<template>
    <div class="faq-item">
        <div class="faq-question">
            <TextInput
                class="faq-question-input"
                :field="props.questionField"
                v-model="state.questionValue"
                @input="updateInput"
            />
            <Icon icon="close" class="remove" @click="removeInput" />
        </div>
        <TextInput
            class="faq-answer-input"
            :field="props.answerField"
            v-model="state.answerValue"
            @input="updateInput"
        />
    </div>
</template>

<script setup lang="ts">
import { ProductFaqModel } from '@/models/product.types'
import TextInput from 'shared/components/form/TextInput.vue'
import Icon from 'shared/components/utils/Icon.vue'
import { TextFieldModel } from 'shared/models/formContent.types'
import { onMounted, reactive } from 'vue'

const emit = defineEmits<{
    (e: 'update', value: ProductFaqModel & { key: number }): void
    (e: 'remove', id: number): void
}>()

interface Props {
    id: number
    questionField: TextFieldModel
    answerField: TextFieldModel
}
const props = defineProps<Props>()

const state = reactive({
    questionValue: '',
    answerValue: ''
})

const updateInput = () => {
    const value = {
        key: props.id,
        question: state.questionValue,
        answer: state.answerValue
    }
    emit('update', value)
}
const removeInput = () => {
    emit('remove', props.id)
}

onMounted(() => {
    state.questionValue = props.questionField.value as string
    state.answerValue = props.answerField.value as string
})
</script>

<style scoped lang="scss">
.faq-item {
    margin-bottom: 1.5rem;
}
.faq-question {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}
.faq-question-input {
    @extend .is-body-2-web;
    flex: 1;
}
.remove {
    margin-top: 1.5rem;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}
.faq-answer-input {
    @extend .is-body-2-web;
}
</style>
