import { t } from '@/i18n'
import { computed } from 'vue'
import { useWindowSize } from '@vueuse/core'

export const getAsset = (relativePath: string) => {
    return new URL(`/src/assets/img/${relativePath}`, import.meta.url).href
}

export const isMobile = computed(() => {
    const { width } = useWindowSize()
    return width.value < 1024
})

export const fadedColor = (color: string) => {
    return `${color}1a`
}

export const enableBodyScroll = () => {
    document.body.classList.remove('is-clipped')
}

export const disableBodyScroll = () => {
    document.body.classList.add('is-clipped')
}
export const getPriceDisplay = (price: number) => {
    return `${price * 0.01} ${t('PLN_ABBR')}`
}
