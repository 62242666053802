import { Timestamp } from 'shared/models/generic.types'

export const tag_scope = {
    blog_parent: 'blog_parent',
    blog_owner: 'blog_owner',
    marketplace: 'marketplace',
    all: 'all'
}
export const tag_type = {
    product_type: 'product_type',
    product_category: 'product_category',
    product_group_age: 'product_group_age',
    other: 'other'
}

export type TagID = string
export type TagType = keyof typeof tag_type
export interface TagModel {
    id: TagID
    created_at: Timestamp

    parent_id?: TagID
    slug: string
    display: string
    description: string
    scope: string
    type: TagType
    icon: string
    color: string
}

export type TagRequestModel = Pick<
    TagModel,
    | 'id'
    | 'parent_id'
    | 'display'
    | 'description'
    | 'scope'
    | 'type'
    | 'icon'
    | 'color'
>
