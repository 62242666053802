<template>
    <div class="editor">
        <div class="back-button">
            <BackIcon class="back-button-arrow" @click="goBack" />
            <div class="back-button-text" v-text="title" />
        </div>
        <div v-if="state.initLoading">LOADING</div>
        <template v-else>
            <FormCreator
                class="support-form-creator"
                :fields="state.form"
                :errors="state.errors"
                @update="onUpdate"
            />
            <div class="save-buttons">
                <button
                    v-for="button in currentCreator.buttons"
                    :key="button.key"
                    :loading="button.loading"
                    class="draft-button"
                    v-text="button.display"
                    @click="onButtonClick(button.fn)"
                />
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import BackIcon from '@/components/icons/BackIcon.vue'
import FormCreator from '@/components/form/FormCreator.vue'
import Errors from 'shared/helpers/errors'
import { reactive, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { support_creators } from '@/helpers/support/supportCreators'
const route = useRoute()
const state = reactive({
    entity: {},
    form: {},
    loading: false,
    initLoading: true,
    errors: new Errors()
})
const currentCreator = computed(() => {
    return support_creators()[route.params.tab as string] || {}
})
const title = computed(() => {
    const titleConfig = currentCreator.value?.title || {}
    return state.entity[titleConfig.key] || titleConfig.default
})
const currentId = computed(() => {
    return route.params.id
})
const goBack = () => {
    currentCreator.value.goBackFn()
}
const onUpdate = update => {
    if (currentCreator.value.onUpdateFn)
        currentCreator.value.onUpdateFn({ form: state.form, update })
}
const onButtonClick = fn => {
    fn({
        id: currentId.value,
        loading: state.loading,
        errors: state.errors,
        form: state.form
    })
}
onMounted(async () => {
    await currentCreator.value.prepare?.()
    state.form = currentCreator.value.form
    state.entity =
        (await currentCreator.value.loadFn({
            id: currentId.value,
            loading: state.initLoading,
            form: state.form
        })) || {}
    state.initLoading = false
})
</script>

<style scoped lang="scss">
.support-form-creator {
    :deep(.TextInput--input) {
        padding: 1.4rem 1rem;
    }
    :deep(.selector-display) {
        padding: 1.4rem 1rem;
        border-radius: 8px;
    }
}
.editor {
    padding: 1rem;
    width: 800px;
    margin: 0 auto;
}
.back-button {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.back-button-arrow {
    cursor: pointer;
    padding-left: 0.5rem;
}
.back-button-text {
    @extend .is-h6-web;
    padding-left: 1rem;
}

.save-buttons {
    display: flex;
    justify-content: end;
    gap: 0.5rem;
}
.save-button {
    @extend .primary-button-admin;
    @extend .is-button-text-1-web;
    padding: 0.7rem 3rem;
}
.draft-button {
    @extend .tertiary-button-admin;
    @extend .is-button-text-1-web;
    padding: 0.7rem 3rem;
}
</style>
