<template>
    <div class="sideview-users">
        <ElTabs v-model="state.activeTab" class="sideview-tabs">
            <ElTabPane
                label="Information"
                name="info"
                class="sideview-users-info"
            >
                <SupportSideviewText
                    v-for="text in texts"
                    :key="text.label"
                    :label="text.label"
                    :value="text.value"
                />
                <SupportSideviewCreatedAt :created_at="props.item.created_at" />
                <hr />
                <SupportSideviewLabel label="Kids" />
                <div
                    v-for="kid in state.kids"
                    :key="kid.id"
                    class="sideview-users-kid"
                >
                    <SupportSideviewText
                        label="Name and surname"
                        :value="kid.fullName"
                    />
                    <SupportSideviewText
                        label="Date of birth"
                        :value="kid.birthdate"
                    />
                    <hr />
                </div>
            </ElTabPane>
            <ElTabPane
                class="sideview-users-info"
                label="Reservations"
                name="reservations"
            >
                <ElTable :data="state.reservationTable" fit>
                    <ElTableColumn
                        v-for="column in tableColumns"
                        :key="column.key"
                        :type="column.type"
                        :prop="column.prop"
                        :label="column.label"
                        :width="column.width"
                    />
                </ElTable>
            </ElTabPane>
        </ElTabs>
    </div>
</template>

<script setup lang="ts">
import SupportSideviewText from '../SupportSideviewText.vue'
import { computed, onMounted, reactive } from 'vue'
import { ElTabs, ElTabPane, ElTable, ElTableColumn } from 'element-plus'
import { ReservationModel } from '@/models/reservation.types'
import 'element-plus/es/components/table/style/css'
import SupportSideviewCreatedAt from '../SupportSideviewCreatedAt.vue'
import SupportSideviewLabel from '../SupportSideviewLabel.vue'
import { ParentModel } from '@/models/parent.types'
import Parents from '@/store/modules/Parents'

interface Props {
    item: ParentModel
}
const props = defineProps<Props>()

const state = reactive({
    activeTab: 'info',
    kids: [],
    reservationTable: []
})

const tableColumns = [
    {
        key: 'index',
        type: 'index',
        width: '30'
    },
    {
        key: 'type',
        prop: 'type',
        label: 'Type',
        width: '100'
    },
    {
        key: 'kid',
        prop: 'kid',
        label: 'Kid'
    },
    {
        key: 'name',
        prop: 'name',
        label: 'Name'
    },
    {
        key: 'date',
        prop: 'date',
        label: 'Date'
    }
]

const texts = computed(() => {
    return [
        {
            label: 'Address e-mail',
            value: props.item.account.email
        },
        {
            label: 'Name and surname',
            value: `${props.item.account.name} ${props.item.account.surname}`
        },
        {
            label: 'Phone number',
            value: props.item.account.phone
        }
    ]
})

onMounted(async () => {
    state.kids = await Parents.getParentKids({
        payload: { id: props.item.id }
    })
    const reservations = await Parents.getParentReservations({
        id: props.item.id
    })
    state.reservationTable = reservations.map(
        (reservation: ReservationModel) => ({
            type: reservation.product.type,
            kid: reservation.product.title,
            name: `${reservation.kid_name} ${reservation.kid_surname}`,
            date: reservation.date
        })
    )
})
</script>

<style scoped lang="scss">
.sideview-users {
    height: 100%;
}
.sideview-users-info {
    padding: 1rem 2rem;
}
.sideview-tabs {
    @include sideview-tabs;
}
</style>
