<template>
    <div class="day-input">
        <div class="remove-container">
            <Icon icon="close" class="remove" @click="removeInput" />
        </div>
        <FormCreator
            :fields="props.form"
            :errors="state.errors"
            @update:fields="updateInput"
        />
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import FormCreator from './FormCreator.vue'
import Errors from 'shared/helpers/errors'
import Icon from 'shared/components/utils/Icon.vue'

const emit = defineEmits<{
    (e: 'update', value: any): void
    (e: 'remove', id: number): void
}>()

interface Props {
    id: number
    form: any
}
const props = defineProps<Props>()

const state = reactive({
    errors: new Errors()
})
const updateInput = fields => {
    emit('update', { form: fields, key: props.id })
}
const removeInput = () => {
    emit('remove', props.id)
}
</script>

<style scoped lang="scss">
.day-input {
    margin-bottom: 0.5rem;
}
.remove-container {
    display: flex;
    justify-content: end;
}
.remove {
    margin-bottom: -1rem;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}
</style>
