import { t } from '@/i18n'
import { TagID, TagModel, tag_scope, tag_type } from '@/models/tag.types'
import Tags from '@/store/modules/Tags'

export const getChildrenTagDisplays = (
    tags: TagModel[],
    id: TagID
): string[] => {
    return tags.filter(tag => tag.parent_id == id).map(tag => tag.display)
}
export const getTagDisplaysPayload = (tags: TagModel[], payload: any) => {
    const displayTags: string[] = getChildrenTagDisplays(tags, payload.id)
    payload.display_tags = displayTags.length > 0 ? displayTags.join(', ') : '-'
    return payload
}
export const tagsByType = (type: TagModel['type']) => {
    return Tags.listTags.filter(t => t.type == type)
}
export const tagsToSelectOptions = (tags: TagModel[]) => {
    return tags.map(({ id, display }) => ({
        value: id,
        display: `${t(display)} (${id})`
    }))
}
export const getTagById = (id: TagID) => {
    return Tags.listTags.find((tag: TagModel) => tag.id == id)
}
export const getTagDisplayById = (id: TagID) => {
    const { display } = getTagById(id)
    return t(display)
}
export const getTagChildren = (parentTag: TagModel) => {
    return Tags.listTags.filter(
        (tag: TagModel) => tag.parent_id == parentTag.id
    )
}
export const getTagParent = (tag: TagModel) => {
    return Tags.listTags.find((t: TagModel) => t.id == tag.parent_id)
}
export const isTagGroupSize = (tag: TagModel) => {
    const parent = getTagParent(tag)
    return parent?.id == 'group_size'
}
export const getGroupSizeTags = () => {
    return Tags.listTags.filter((tag: TagModel) => isTagGroupSize(tag))
}
export const getAgeTags = () => {
    return Tags.listTags.filter(
        (tag: TagModel) => tag.type == tag_type.product_group_age
    )
}
export const getAgeFromTagId = (id: TagID) => {
    return parseInt(id.split('_')[0])
}
export const hasTagAncestor = (tagId: TagID, ancestorId: TagID) => {
    const tag = getTagById(tagId)
    const tagParent = getTagById(tag.parent_id)
    return [tag.parent_id, tagParent.parent_id].includes(ancestorId)
}
