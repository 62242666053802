<template>
    <div class="table-wrapper">
        <transition name="fade" mode="out-in">
            <table
                v-if="tableConfig.data.length != 0"
                class="table is-fullwidth is-bordered"
            >
                <thead>
                    <tr class="headers">
                        <th />
                        <th
                            v-for="column in tableConfig.columns"
                            :key="column"
                            class="table-header px-4"
                        >
                            {{ t(column.header) }}
                        </th>
                    </tr>
                </thead>
                <transition name="fade" mode="out-in">
                    <tbody>
                        <tr
                            v-for="(row, index) in rows"
                            :id="`table-row-${row.key}`"
                            :key="row.key"
                            class="table-row is-clickable"
                            :class="{ 'hover-row': hover }"
                            @click="selectRow(row.payload)"
                        >
                            <th v-text="index + 1" />
                            <th
                                v-for="column in row.columns"
                                :key="column"
                                class="row-column"
                                :class="{
                                    '-action': column.type == 'action',
                                    '-image': column.type == 'image',
                                    '-avatar': column.type == 'avatar',
                                    '-icon': column.type == 'icon',
                                    '-flex-narrow': column.type == 'shrink'
                                }"
                                :style="{
                                    'white-space':
                                        column.break_line == false
                                            ? 'nowrap'
                                            : ''
                                }"
                            >
                                <div
                                    v-if="column.value || column.value == false"
                                    class="table-list-cell p-1"
                                >
                                    <div
                                        v-if="column.type == 'image'"
                                        class="pl flex-center"
                                    >
                                        <img
                                            :src="column.value"
                                            class="image-style"
                                        />
                                    </div>
                                    <div
                                        v-else-if="column.type == 'avatar'"
                                        class="pl flex-center"
                                    >
                                        <Image
                                            class="avatar-style"
                                            :rounded="true"
                                            size="48x48"
                                            :a_src="column.value"
                                        />
                                    </div>
                                    <div
                                        v-else-if="column.type == 'action'"
                                        class="flex-center py-1"
                                    >
                                        <Button
                                            v-if="
                                                column.value.subtype == 'button'
                                            "
                                            class="table-button"
                                            :display="column.value.display"
                                            :color="column.value.color"
                                            @click.stop="
                                                column.value.fn(
                                                    column.value.payload
                                                )
                                            "
                                        />
                                        <Icon
                                            v-else
                                            :icon="column.value.icon"
                                            :color="column.value.color"
                                            class="has-button-hover"
                                            @click.stop="
                                                column.value.fn(
                                                    column.value.payload
                                                )
                                            "
                                        />
                                    </div>
                                    <div
                                        v-else-if="column.type == 'icon'"
                                        class="flex-center py-1"
                                    >
                                        <Icon :icon="column.value" />
                                    </div>
                                    <div
                                        v-else-if="column.type == 'status'"
                                        class="py-1 status"
                                        :style="{ color: column.color }"
                                        v-html="t(column.display)"
                                    />
                                    <input
                                        v-else-if="column.type == 'check'"
                                        type="checkbox"
                                        :checked="column.value"
                                        @click="
                                            toggleCheckbox($event, row.payload)
                                        "
                                    />
                                    <div
                                        v-else
                                        :class="{ '-wrapped': column.wrap }"
                                        class="py-1 text"
                                        :style="{ color: column.color }"
                                        v-html="column.value"
                                    />
                                </div>
                            </th>
                        </tr>
                        <tr
                            v-if="pagination.available"
                            class="table-row is-clickable"
                            @click="pagination.loadMore"
                        >
                            <td colspan="1000">
                                <div
                                    style="
                                        display: flex;
                                        justify-content: center;
                                    "
                                >
                                    {{
                                        pagination.loading
                                            ? 'Loading'
                                            : `LOAD MORE`
                                    }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </transition>
            </table>
            <Loading v-else-if="loading && tableConfig.data.length == 0" />
            <Placeholder v-else class="my-5" style="width: 100%" />
        </transition>
    </div>
</template>
<script setup lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import Placeholder from 'shared/components/utils/Placeholder.vue'
import Loading from 'shared/components/utils/Loading.vue'
import Icon from 'shared/components/utils/Icon.vue'
import Image from 'shared/components/utils/Image.vue'
// import Button from '@/components/utils/Button.vue'
import { t } from '@/i18n'
import { PaginationModel } from '@/helpers/pagination'
import get from 'lodash/get'
interface Props {
    enableCheckbox: boolean
    data: unknown[]
    config: TableConfigModel
    loading: boolean
    hover: boolean
    pagination: PaginationModel
}
const emit = defineEmits<{
    (e: 'select', row: any): void
    (e: 'check', row: any): void
    (e: 'uncheck', row: any): void
}>()
const props: Props = withDefaults(defineProps<Props>(), {
    enableCheckbox: false,
    data: () => [],
    config: () => ({}),
    pagination: () => ({})
})
const tableConfig = computed(() => {
    return {
        columns: props.config.columns,
        headers: props.config.headers,
        data: props.data
    }
})
const rows = computed(() => {
    return props.data.map((row: any) => {
        const columns = props.config.columns.map(({ key, type }: any) => {
            const column = row[key] ?? {}
            const value = column.value ?? get(row, key)
            const { color, break_line, wrap, display } = column
            return { value, type, color, break_line, wrap, display }
        })
        return { columns, payload: row }
    })
})
const selectRow = (row: any) => {
    emit('select', row)
}
const toggleCheckbox = (e, row: any) => {
    e.stopPropagation()
    e.target.checked ? emit('check', row) : emit('uncheck', row)
}
</script>
<style lang="scss" scoped>
.table-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 4px;
    @include mobile {
        overflow-x: auto;
        font-size: 12px;
    }
}
thead {
    position: sticky;
    top: 0;
}
.table-row {
    border-bottom: 1px solid $border-light;
    //* NOTE: for efficiency reasons temporary disabled (only gallery editor)
    &.hover-row:hover {
        cursor: pointer;
        background: rgb(228, 228, 228) !important;
    }
}
.headers {
    background-color: $bg-grey;
}
.table-header {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
}
.table-list-cell {
    color: $black;
    display: flex;
    align-items: center;
}
.row-column {
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}
.-action {
    width: 10px;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}
.-image {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    width: 130px;
}
.-avatar {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    width: 100px;
}
.-icon {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    width: 60px;
}
.-flex-narrow {
    width: 15%;
}
.text {
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
}
.-wrapped {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.flex-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading {
    width: 100% !important;
    background-color: red;
}
.image-style {
    width: 100%;
    height: 70px;
    object-fit: cover;
    border-radius: 8px;
}
.table-button {
    font-size: 12px !important;
}
.status {
    font-size: 12px;
}
</style>
