<template>
    <div class="upload-owner">
        <div class="upload-title" v-text="`Upload owners`" />
        <SupportUploader :uploadFunction="uploadOwners" />
    </div>
</template>

<script setup lang="ts">
import Owners from '@/store/modules/Owners'
import SupportUploader from './components/SupportUploader.vue'

const uploadOwners = async (file: File) => {
    await Owners.uploadOwners({
        payload: { file }
    })
}
</script>

<style scoped lang="scss">
.upload-owner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.upload-title {
    @extend .is-h3-web;
    margin-bottom: 5rem;
}
</style>
