<template>
    <Dropdown
        ref="dropdown"
        :disabled="field.disabled"
        style="height: 100%; max-width: 100%"
    >
        <template v-slot:selector>
            <div class="dropdown" :style="{ height }" style="width: 100%">
                <button
                    :class="{
                        'is-danger-selector': error,
                        'filter-button': isFacilityFilter,
                        '-active': selectedOptionsLength,
                        '-disabled-input': field.disabled
                    }"
                    :disabled="field.disabled"
                    class="button selector-display"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    style="height: 100%; max-width: 100%"
                    @click.prevent
                >
                    <span
                        v-if="selectorDisplay && !isFacilityFilter"
                        class="filter-display"
                        v-text="selectorDisplay"
                    />
                    <div v-else>
                        <div
                            :class="{
                                'is-filter-selector': isFacilityFilter,
                                'is-inline': isFacilityFilter
                            }"
                            class="filter-display has-text-placeholder"
                            v-text="t(placeholder)"
                        />
                        <b
                            v-if="selectedOptionsLength"
                            class="has-text-primary"
                            v-text="` (${selectedOptionsLength})`"
                        ></b>
                    </div>
                    <span
                        v-if="!isFacilityFilter"
                        class="icon"
                        style="margin-left: auto"
                    >
                        <Icon icon="arrow_drop_down" color="grey-darker" />
                    </span>
                </button>
            </div>
        </template>
        <template v-slot:body>
            <div class="is-hidden-desktop">
                <button
                    class="delete"
                    aria-label="close"
                    @click="close"
                ></button>
                <div v-if="filteredOptions.length > 0">
                    <div v-if="!field.scope || field.scope == 'single'">
                        <a
                            v-for="option in filteredOptions"
                            :id="option.value"
                            :key="option.value"
                            class="dropdown-item"
                            @click="selectSingleOption(option)"
                        >
                            <p v-text="t(option.display)" />
                        </a>
                    </div>
                    <div v-else-if="field.scope == 'multi'">
                        <div
                            v-for="option in filteredOptions"
                            :id="option.value"
                            :key="option.value"
                            class="dropdown-item"
                        >
                            <div style="display: flex">
                                <Checkbox
                                    :checked="optionSelected(option)"
                                    :label="option.display"
                                    @input="selectOptionModal(option)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    v-if="field.scope == 'multi'"
                    class="mt-5"
                    display="APPLY"
                    style="width: 100%"
                    @click="close"
                />
            </div>
            <div
                id="dropdown-menu"
                class="dropdown-menu is-hidden-touch"
                style="max-height: 300px; width: 100%"
                role="menu"
            >
                <div>
                    <TextInput
                        v-if="field.search_options"
                        :field="{ key: 'dropdown-search' }"
                        :modelValue="state.text_search"
                        :dropdown_search="true"
                        class="has-background-primary-light"
                        @input="onInput"
                        @blur="onBlur"
                        @click.stop
                    />
                    <div
                        v-if="filteredOptions.length > 0"
                        class="dropdown-content"
                        style="max-height: 200px; overflow-y: auto"
                    >
                        <div v-if="!field.scope || field.scope == 'single'">
                            <a
                                v-for="option in filteredOptions"
                                :id="option.value"
                                :key="option.value"
                                class="dropdown-item"
                                @click="selectSingleOption(option)"
                            >
                                <p v-text="t(option.display)" />
                            </a>
                        </div>
                        <div v-if="field.scope == 'multi'">
                            <div
                                v-for="option in filteredOptions"
                                :id="option.value"
                                :key="option.value"
                                class="dropdown-item"
                            >
                                <div style="display: flex">
                                    <Checkbox
                                        :checked="optionSelected(option)"
                                        :label="option.display"
                                        @input="selectOption(option)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="
                                selectedOptionsLength && field.scope == 'multi'
                            "
                            class="my-2 ml-4"
                        >
                            <TextButton
                                display="CLEAR_FILTERS"
                                size="7"
                                @click="clear"
                            />
                        </div>
                    </div>
                    <div v-else class="dropdown-content p-4">
                        <Placeholder type="small" />
                    </div>
                </div>
            </div>
        </template>
    </Dropdown>
</template>
<script lang="ts">
import { computed, defineComponent, reactive, ref } from 'vue'
import Dropdown from '../utils/Dropdown.vue'
import Checkbox from '../utils/Checkbox.vue'
import Button from '../utils/Button.vue'
import Modal from '../layout/Modal.vue'
import TextInput from '../form/TextInput.vue'
import Placeholder from '../utils/Placeholder.vue'
import TextButton from '../utils/TextButton.vue'
import Icon from '../utils/Icon.vue'
import { FormFieldsModel, FormOptionModel } from '@/helpers/form'
import { t } from '@/i18n'
import { sortAlphabetically } from '../../helpers/utils'
export default defineComponent({
    props: {
        error: {
            type: Boolean,
            default: false
        },
        isFacilityFilter: {
            type: Boolean,
            default: false
        },
        fontSize: {
            type: String,
            default: '16px'
        },
        height: {
            type: String,
            default: '40px'
        },
        field: {
            type: Object,
            default: () => ({})
        },
        modelValue: String
    },
    components: {
        Dropdown,
        Icon,
        Checkbox,
        Placeholder,
        Modal,
        Button,
        TextButton,
        TextInput
    },
    exmits: ['update', 'update:modelValue', 'submit'],
    setup(props, { emit }) {
        const dropdown: any = ref()
        const state = reactive({
            text_search: ''
        })
        const filteredOptions = computed(() => {
            const options: any = props.field.options
            if (!options) return []
            const unsortedoptions = options
                .filter((o: FormOptionModel) => !o.hidden)
                .filter((o: FormOptionModel) => {
                    return t(o.display)
                        .toLowerCase()
                        .includes(state.text_search)
                })
            return props.field.dontSort
                ? unsortedoptions
                : unsortedoptions.sort((a: any, b: any) =>
                      sortAlphabetically(t(a.display), t(b.display))
                  )
        })
        const getMultiDisplay = () => {
            return props.field.value
                .map((v: any) => {
                    const display = props.field.options.find(
                        (o: FormOptionModel) => {
                            return o.value == v
                        }
                    ).display
                    return t(display)
                })
                .join(', ')
        }
        const placeholder = computed(() => {
            return props.field.placeholder ? props.field.placeholder : 'CHOOSE'
        })
        const selectorDisplay = computed((): string => {
            if (props.field.scope == 'multi') {
                return getMultiDisplay()
            } else {
                const option = !props.field.options
                    ? ''
                    : props.field.options.find(
                          (o: FormOptionModel) => o.value == props.modelValue
                      )
                return option && t(option.display)
            }
        })
        const optionSelected = ({ value }: FormFieldsModel) => {
            return props.field.value.includes(value)
        }
        const selectSingleOption = ({ value }: FormOptionModel): void => {
            emitEvents(value)
            dropdown.value.toggleBody()
        }
        const selectOption = ({ value }: FormOptionModel): void => {
            const currentValue = [...props.field.value]
            const index = currentValue.indexOf(value)
            index == -1
                ? currentValue.push(value)
                : currentValue.splice(index, 1)
            const valueToEmit = currentValue?.length > 0 ? currentValue : []
            emitEvents(valueToEmit)
        }
        const selectOptionModal = (option: any) => {
            dropdown.value.state.isActive = true
            selectOption(option)
        }
        const selectedOptionsLength = computed(() => {
            return props.field.value?.length
        })
        const close = () => {
            dropdown.value.state.isActive = false
        }
        const clear = () => {
            emitEvents(props.field.scope == 'multi' ? [] : '')
        }
        const onInput = (e: any) => {
            state.text_search = typeof e === 'string' ? e : e.target.value
        }
        const onBlur = () => {
            dropdown.value.setFocus()
        }
        const fontSize = computed(() => props.fontSize)
        const emitEvents = (value: any) => {
            if (props.field.onUpdate) props.field.onUpdate(value)
            emit('update:modelValue', value)
            emit('update', value)
            emit('submit')
        }
        return {
            selectOption,
            selectSingleOption,
            selectorDisplay,
            filteredOptions,
            dropdown,
            optionSelected,
            placeholder,
            t,
            close,
            selectOptionModal,
            selectedOptionsLength,
            clear,
            state,
            onInput,
            onBlur
        }
    }
})
</script>
<style lang="scss" scoped>
.delete {
    position: fixed;
    top: 1rem;
    right: 1rem;
}
.dropdown-item {
    font-size: 14px;
    @include touch {
        font-size: 1.2rem;
        margin-left: 0;
    }
    -webkit-tap-highlight-color: transparent;
}
.selector-display {
    width: 100%;
    max-width: 100%;
    font-size: v-bind(fontSize);
    text-align: left;
    display: flex;
    justify-content: flex-start;
    border: solid 1px $grey-lighter;
    -webkit-tap-highlight-color: transparent;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.is-danger-selector {
    &,
    &:hover,
    &:focus,
    &:active {
        border-color: $danger;
    }
}
.filter-button {
    padding-top: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 4px !important;
    background-color: $grey-light;
    &.-active {
        border-color: $primary;
    }
}
.is-filter-selector {
    font-size: 12px;
    font-weight: 700;
    color: $text !important;
}
.sub-placeholder {
    font-size: 8px;
    font-weight: 400;
}
.has-text-primary {
    font-size: 12px;
}
.clear-button {
    font-size: 12px !important;
}
.filter-display {
    max-width: 200px;
    overflow-x: hidden !important;
    // @include mobile {
    //     color: $text-grey;
    // }
}
.dropdown-menu {
    @extend .box-shadow-1;
    border-radius: 8px;
    border: 1px solid $gray-60;
}
</style>
