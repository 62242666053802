import { EnvKey } from '../models/env.types'

export type SharedGatewayType =
    | 'fc_web'
    | 'zz_web'
    | 'lk_web'
    | 'lk_web_proxy'
    | 'zz_api'
    | 'zz_sockets'
    | 'fc_api'
    | 'lk_api'
    | 'lk_sockets'
    | 'assets'
    | 'status'
    | 'chat'
    | 'status_socket'
    | 'chat_socket'
    | 'cloud_functions'
export type LkServiceType = 'service-api' | 'service-assets' | 'service-status'
type SharedGatewayObjectType = { [key in SharedGatewayType]: string }

const url = ({
    base,
    prefix = '',
    protocol,
    suffix = ''
}: {
    protocol: 'https' | 'http' | 'wss' | 'ws'
    prefix?: string
    base: string
    suffix?: string
}) => `${protocol}://${prefix ? `${prefix}.` : ''}${base}/${suffix}`

const wss = (base: string, suffix: string) =>
    url({ protocol: 'wss', base, suffix })

const https = (base: string, suffix: string = '') =>
    url({ protocol: 'https', base, suffix })

const localhost = (port: number) =>
    url({ protocol: 'http', base: `localhost:${port}` })

const localhost_ws = (port: number, suffix?: string) =>
    url({ protocol: 'ws', base: `localhost:${port}`, suffix })

const api_livekid_prod = (prefix: string = '', suffix: string = '') =>
    url({ protocol: 'https', prefix, base: 'api-livekid-prod.com', suffix })

const socket_livekid_prod = (prefix: string = '', suffix: string = '') =>
    url({ protocol: 'wss', prefix, base: 'api-livekid-prod.com', suffix })

const api_livekid_dev = (prefix: string = '', suffix: string = '') =>
    url({ protocol: 'https', prefix, base: 'api-livekid-dev.com', suffix })

const socket_livekid_dev = (prefix: string = '', suffix: string = '') =>
    url({ protocol: 'wss', prefix, base: 'api-livekid-dev.com', suffix })

export const localhost_urls: Partial<SharedGatewayObjectType> = {
    fc_api: localhost(4000),
    zz_api: localhost(3003),
    zz_sockets: localhost_ws(3003, 'ws/'),
    lk_api: localhost(3000),
    lk_sockets: localhost_ws(3000, 'v1/socket'),
    chat: localhost(4000),
    chat_socket: localhost_ws(4000, 'v1/socket'),
    assets: api_livekid_dev('service-assets'),
    status: api_livekid_dev('service-status'),
    status_socket: socket_livekid_dev('service-status', 'v1/socket'),
    cloud_functions: localhost(8787)
}
export const rc_urls: Partial<SharedGatewayObjectType> = {
    lk_web: https('rc.app.livekid.com'),
    lk_web_proxy: https('rc-web-proxy-e461f'),
    fc_api: https('stage.marketplace.theboringdomain.com'),
    zz_api: https('zlobkowo.pl'),
    lk_api: api_livekid_dev('service-api-rc'),
    assets: api_livekid_dev('service-assets'),
    status: api_livekid_dev('service-status'),
    chat: https('stage.chat.theboringdomain.com'),
    zz_sockets: wss('zlobkowo.pl', 'ws/'),
    lk_sockets: socket_livekid_dev('service-api-rc', 'v1/socket'),
    status_socket: socket_livekid_dev('service-status', 'v1/socket'),
    chat_socket: wss('stage.chat.theboringdomain.com', 'ws/chat/'),
    cloud_functions: https(
        'service-api-rc.api-livekid-dev.com/region-provider/'
    )
}
export const stage_urls: SharedGatewayObjectType = {
    fc_web: https('stage.funclass.com'),
    zz_web: https('zaufany-zlobek-stage.web.app'),
    lk_web: https('stage.app.livekid.com'),
    lk_web_proxy: https('stage-web-proxy.web.app'),
    fc_api: api_livekid_dev('marketplace.staging'),
    zz_api: https('zlobkowo.pl'),
    lk_api: api_livekid_dev('api.staging'),
    assets: api_livekid_dev('assets.staging'),
    status: api_livekid_dev('service-status'),
    chat: https('stage.chat.theboringdomain.com'),
    zz_sockets: wss('zlobkowo.pl', 'ws/'),
    lk_sockets: socket_livekid_dev('api.staging', 'v1/socket'),
    status_socket: socket_livekid_dev('service-status', 'v1/socket'),
    chat_socket: wss('stage.chat.theboringdomain.com', 'ws/chat/'),
    cloud_functions: https('api.staging.api-livekid-dev.com/region-provider/')
}
export const waw_urls: Partial<SharedGatewayObjectType> = {
    lk_web: https('app.zlobki.waw.pl'),
    lk_web_proxy: https('web-proxy.zlobki.waw.pl'),
    lk_api: api_livekid_prod('waw.service-api'),
    lk_sockets: socket_livekid_prod('waw.service-api', 'v1/socket')
}
export const wekita_urls: Partial<SharedGatewayObjectType> = {
    lk_web: https('app.wekita-welt.de'),
    lk_web_proxy: https('web-proxy.wekita-welt.de'),
    lk_api: api_livekid_prod('wekita.service-api'),
    lk_sockets: socket_livekid_prod('wekita.service-api', 'v1/socket')
}
export const urls: SharedGatewayObjectType = {
    fc_web: https('funclass.com'),
    zz_web: https('przedszkolowo.pl'),
    lk_web: https('app.livekid.com'),
    lk_web_proxy: https('web-proxy.livekid.com'),
    fc_api: https('marketplace.theboringdomain.com'),
    zz_api: https('api-przedszkola.backendowo.com'),
    lk_api: api_livekid_prod('{region}.service-api'),
    assets: api_livekid_prod('service-assets'),
    status: api_livekid_prod('service-status'),
    chat: https('chat.theboringdomain.com'),
    zz_sockets: wss('api-przedszkola.backendowo.com', 'ws/'),
    lk_sockets: socket_livekid_prod('{region}.service-api', 'v1/socket'),
    status_socket: socket_livekid_prod('service-status', 'v1/socket'),
    chat_socket: wss('chat.theboringdomain.com', 'ws/chat/'),
    cloud_functions: https('service-api.api-livekid-prod.com/region-provider/')
}
export const provideBaseUrls = (api_env: EnvKey): SharedGatewayObjectType => {
    return {
        ...urls,
        ...(api_env == 'waw' && waw_urls),
        ...(api_env == 'wekita' && wekita_urls),
        ...(api_env == 'rc' && rc_urls),
        ...(api_env == 'stage' && stage_urls),
        ...(api_env == 'localhost' && localhost_urls)
    }
}
