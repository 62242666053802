<template>
    <input
        ref="inputRef"
        type="time"
        class="TimeInput--input"
        :name="field.name"
        :class="{ 'is-danger': error }"
        :value="displayValue"
        @input.prevent="input"
        @focus="onFocusChange"
        @blur="onFocusChange"
    />
</template>
<script lang="ts">
import { minutes } from '../../helpers/time'
import { computed, defineComponent, ref, reactive } from 'vue'
export default defineComponent({
    props: {
        error: {
            type: Boolean,
            default: false
        },
        field: {
            type: Object,
            default: () => ({})
        },
        modelValue: String
    },
    exmits: ['update:modelValue'],
    setup(props, { emit }) {
        const state = reactive({ changesMade: false })
        const inputRef = ref()
        const displayValue = computed(() => {
            return minutes(props.field.value)
        })
        const input = (event: any): void => {
            const [hours, minutes] = event.target.value.split(':')
            const valueToEmit: any = Number(hours) * 60 + Number(minutes)
            state.changesMade = true
            emit('update', { ...props.field, value: valueToEmit })
            emit('update:field', { ...props.field, value: valueToEmit })
            emit('update:modelValue', valueToEmit)
        }
        const onFocusChange = ({ type }: any) => {
            if (type == 'blur') onSubmit()
            emit('update:isFocused', type == 'focus')
        }
        const onSubmit = () => {
            if (!state.changesMade) return
            state.changesMade = false
            emit('submit')
        }
        const blur = () => {
            inputRef.value.blur()
        }
        return { input, displayValue, inputRef, onFocusChange, blur }
    }
})
</script>
<style lang="scss" scoped>
.TimeInput--input {
    @extend .input;
    cursor: pointer;

    &::-webkit-calendar-picker-indicator {
        display: block;
        top: 0;
        left: 0;
        background: #0000;
        position: absolute;
        transform: scale(12);
    }
}
</style>
