<template>
    <div>
        <PasswordInput
            class="password-current"
            overrideClass="TextInputRoundedSimple"
            :field="state.currentPasswordField"
            v-model="state.currentPasswordField.value"
        />
        <PasswordInput
            class="password-new"
            overrideClass="TextInputRoundedSimple"
            :field="state.newPasswordField"
            v-model="state.newPasswordField.value"
        />
        <div class="password-text" v-text="t('PASSWORD_SHOULD_CONTAIN')" />
        <button
            class="password-button"
            v-text="t('SAVE')"
            @click="changePassword"
        />
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { t } from '@/i18n'
import { client_password } from '@/helpers/formContent'
import PasswordInput from 'shared/components/form/PasswordInput.vue'
import ClientAccounts from '@/store/modules/ClientAccounts'
import toast from '@/helpers/toast'
import { checkClientPasswordRequirements } from '@/helpers/accounts'

const emit = defineEmits<{
    (e: 'passwordChanged'): void
}>()

const state = reactive({
    currentPasswordField: client_password({
        label: t('CURRENT_PASSWORD'),
        placeholder: t('CURRENT_PASSWORD')
    }),
    newPasswordField: client_password({
        label: t('NEW_PASSWORD'),
        placeholder: t('CREATE_NEW_PASSWORD')
    }),
    loading: false
})

const changePassword = async () => {
    try {
        if (
            state.loading ||
            !checkClientPasswordRequirements(state.newPasswordField.value)
        )
            return
        const payload = {
            password_old: state.currentPasswordField.value as string,
            password_new: state.newPasswordField.value as string
        }
        state.loading = true
        await ClientAccounts.changeCurrentPassword({ payload })
        toast.success('YOUR_PASSWORD_HAS_BEEN_UPDATED')
        emit('passwordChanged')
    } catch (error) {
        toast.error(error.message || 'INCORRECT_PASSWORD')
    } finally {
        state.loading = false
    }
}
</script>

<style scoped lang="scss">
.password-current {
    margin-bottom: 2rem;
}
.password-new {
    margin-bottom: 0.5rem;
}
.password-text {
    @extend .is-body-2-web;
    margin-bottom: 2rem;
}
.password-button {
    @extend .wide-button;
}
</style>
