import { default_cords } from '../helpers/openLayers'
import { dateWithFormat, defaultDatePickerFormat } from '../helpers/date'

export const type_text = 'text-input'
export const type_area = 'area-input'
export const type_number = 'number-input'
export const type_switch = 'switch'
export const type_single_select = 'single-select'
export const type_multi_select = 'multi-select'
export const type_time = 'time-input'
export const type_date = 'date-select'
export const type_date_picker = 'date-picker'
export const type_grouped_select = 'grouped-select'
export const type_attachments = 'attachments-input'
export const type_modal = 'modal-input'
export const type_avatar = 'avatar-input'
export const type_group_label = 'group-label'
export const type_color = 'color-input'
export const type_rich_text = 'rich-text-input'

// fc
export const type_map_selector = 'map-selector'

export const characters_low_limit = 1500
export const characters_high_limit = 25000
export const characters_chat_limit = 5000

export type CharLimit =
    | typeof characters_low_limit
    | typeof characters_high_limit

export type FieldTypes =
    | typeof type_text
    | typeof type_number
    | typeof type_switch
    | typeof type_single_select
    | typeof type_multi_select
    | typeof type_time
    | typeof type_date
    | typeof type_grouped_select
    | typeof type_attachments
    | typeof type_modal
    | typeof type_avatar

interface FieldOptionModel {
    value: string | number | boolean
    display: string
    icon?: string
}
interface FieldGroupedConfigModel {
    group_key: string
    title: string
    groupTitle: string
    contentTitle: string
    buttonName: string
    single?: boolean
    defaultAvatars?: boolean
    config_collection: any
}
export interface FieldModel {
    key: string // key in output payload
    title: string // display label
    required?: boolean // should be validated by front
    placeholder?: string // placeholder display
    disabled?: boolean // should be blocked and have different display
    hidden?: boolean // should be visible and omitted in output
    sid?: null | number // school id
    size?: number // size in form
    test_id?: string | number // id for e2e tests
    object?: string // key of nested object
    suffix?: string // suffix display
    prefix?: string // prefix display
    omit?: boolean // should be ommited in output
    no_white_space?: boolean // remove all white space from payload
    message?: string
    iconLeft?: string
    iconRight?: string
    label?: string
    onUpdate?: (value?: any) => void
}
export interface TextFieldModel extends FieldModel {
    type: typeof type_text
    value: string | number
    dataList?: string[]
    scope?: 'string' | 'int' | 'float' | 'password' | 'email' | 'price'
    //  line below optional for lack of compatibility in LK // AK 8.06.22
    autocomplete?: 'on' | 'off'
    overrideClass?: string
    readonly?: boolean
}
export interface AreaFieldModel extends FieldModel {
    type: typeof type_area
    value: string
    limit?: CharLimit
}
export interface NumberFieldModel extends FieldModel {
    type: typeof type_number
    value: number
}
export interface SingleSelectFieldModel extends FieldModel {
    type: typeof type_single_select
    //  line below optional for lack of compatibility in LK // AK 8.06.22
    scope?: string
    value: string | boolean | number
    options: FieldOptionModel[]
    search_options?: boolean
    reseting_value?: string | number | boolean | null | undefined
    dontSort: boolean
}
export interface MultiSelectFieldModel extends FieldModel {
    type: typeof type_multi_select
    //  line below optional for lack of compatibility in LK // AK 8.06.22
    scope?: string
    value: Array<string | number | boolean>
    options: FieldOptionModel[]
    search_options?: boolean
}
export interface SwitchFieldModel extends FieldModel {
    type: typeof type_switch
    value: boolean
    no_options?: boolean
    options?: { false: string; true: string }
}
export interface TimeFieldModel extends FieldModel {
    type: typeof type_time
    interval?: number
    force_interval?: boolean
    value: number
}
export interface DateFieldModel extends FieldModel {
    type: typeof type_date
    value: string | []
    blockFreeDays?: boolean
}
export interface DatePickerFieldModel extends FieldModel {
    type: typeof type_date_picker
    value: string | string[]
    // Datepicker config
    allowedDates?: string[]
    autoApply?: boolean
    cancelText?: string
    enableTimePicker?: boolean
    format?: (date: Date) => string
    previewFormat?: (date: Date) => string
    inline: boolean
    locale?: string
    modeHeight?: number
    multiDates?: boolean
    placeholder?: string
    range?: boolean
    selectText?: string
    startDate?: string
    textInput?: boolean
    timePicker?: boolean
    transitions?: boolean
}
export interface GroupedSelectFieldModel extends FieldModel {
    type: typeof type_grouped_select
    value: any
    config?: FieldGroupedConfigModel
}
export interface ModalFieldModel extends FieldModel {
    type: typeof type_modal
    value: string
}
export interface AvatarFieldModel extends FieldModel {
    type: typeof type_avatar
    value: string
}
export interface AttachmentFieldModel extends FieldModel {
    type: typeof type_attachments
    value: any[] | string
    accept?: string
    directory?: string
    hideStorage?: boolean
    single?: boolean
    loading?: boolean
    forceUpload?: boolean
    fileNameHidden?: boolean
    instant_upload?: boolean
}
export interface GroupLabelFieldModel extends FieldModel {
    type: typeof type_group_label
    text: string
    value?: string
}
export interface ColorFieldModel extends FieldModel {
    type: typeof type_color
    value: string
}
export interface RichTextFieldModel extends FieldModel {
    type: typeof type_rich_text
    value: string
}

// fc
export interface MapSelectorFieldModel extends FieldModel {
    type: typeof type_map_selector
    value?: {
        long: number
        lat: number
    }
    search: boolean
}

export type FieldModels =
    | TextFieldModel
    | NumberFieldModel
    | SingleSelectFieldModel
    | MultiSelectFieldModel
    | SwitchFieldModel
    | TimeFieldModel
    | DateFieldModel
    | GroupedSelectFieldModel
    | GroupLabelFieldModel
    | AreaFieldModel

export const default_field_values: {
    [key in FieldTypes]: any
} = {
    [type_text]: '',
    [type_number]: 0,
    [type_switch]: false,
    [type_single_select]: '',
    [type_multi_select]: [],
    [type_time]: 480,
    [type_date]: dateWithFormat(),
    [type_grouped_select]: [],
    [type_attachments]: [],
    [type_modal]: '',
    [type_avatar]: ''
}

export const text_input = (
    custom: Partial<TextFieldModel> = {}
): TextFieldModel => ({
    title: '',
    value: default_field_values[type_text],
    type: type_text,
    scope: 'string',
    key: '',
    message: '',
    size: 2,
    hidden: false,
    autocomplete: 'off',
    readonly: false,
    required: false,
    ...custom
})
export const single_select_input = (
    custom: Partial<SingleSelectFieldModel> = {}
): SingleSelectFieldModel => {
    const options = custom.hasOwnProperty('reseting_value')
        ? [
              { value: custom.reseting_value, display: 'LABEL_EMPTY' },
              ...custom.options
          ]
        : custom.options
    return {
        title: '',
        value: default_field_values[type_single_select],
        scope: 'single',
        key: '',
        type: type_single_select,
        hidden: false,
        size: 2,
        dontSort: false,
        ...custom,
        options
    }
}
export const multi_select_input = (
    custom: Partial<MultiSelectFieldModel> = {}
): MultiSelectFieldModel => ({
    title: '',
    value: default_field_values[type_multi_select],
    scope: 'multi',
    key: '',
    type: type_multi_select,
    options: [],
    hidden: false,
    size: 2,
    ...custom
})
export const group_label = (
    custom: Partial<GroupLabelFieldModel> = {}
): GroupLabelFieldModel => ({
    key: '',
    title: '',
    type: type_group_label,
    text: '',
    ...custom
})
export const area_input = (
    custom: Partial<AreaFieldModel> = {}
): AreaFieldModel => ({
    key: '',
    title: '',
    type: type_area,
    value: '',
    ...custom
})
export const date_input = (
    custom: Partial<DateFieldModel> = {}
): DateFieldModel => ({
    key: '',
    title: '',
    type: type_date,
    value: '',
    ...custom
})
export const date_picker_input = (
    custom: Partial<DatePickerFieldModel> = {}
): DatePickerFieldModel => ({
    key: '',
    title: '',
    type: type_date_picker,
    value: '',
    // Datepicker config
    autoApply: true,
    enableTimePicker: false,
    format: defaultDatePickerFormat,
    previewFormat: defaultDatePickerFormat,
    inline: false,
    locale: 'pl',
    multiDates: false,
    placeholder: '',
    range: false,
    textInput: false,
    timePicker: false,
    transitions: false,
    ...custom
})

export const time_input = (
    custom: Partial<TimeFieldModel> = {}
): TimeFieldModel => ({
    key: '',
    title: '',
    type: type_time,
    value: 0,
    ...custom
})
export const switch_input = (
    custom: Partial<SwitchFieldModel> = {}
): SwitchFieldModel => ({
    key: '',
    title: '',
    type: type_switch,
    value: false,
    ...custom
})

export const attachment_input = (
    custom: Partial<AttachmentFieldModel> = {}
): AttachmentFieldModel => ({
    key: '',
    title: '',
    type: type_attachments,
    value: [],
    fileNameHidden: false,
    single: false,
    instant_upload: false,
    ...custom
})
export const color_input = (
    custom: Partial<ColorFieldModel> = {}
): ColorFieldModel => ({
    key: '',
    title: '',
    type: type_color,
    value: '',
    ...custom
})
export const rich_text_input = (
    custom: Partial<RichTextFieldModel> = {}
): RichTextFieldModel => ({
    key: '',
    title: '',
    type: type_rich_text,
    value: '',
    ...custom
})

// fc
export const map_selector_input = (
    custom: Partial<MapSelectorFieldModel> = {}
): MapSelectorFieldModel => ({
    key: '',
    title: '',
    type: type_map_selector,
    value: default_cords,
    search: false,
    ...custom
})
