import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: 'AIzaSyAQ2hYZkBdrobn2_DbneFVMbXcmf9wuEyA',
    authDomain: 'funclass-prod.firebaseapp.com',
    projectId: 'funclass-prod',
    storageBucket: 'funclass-prod.appspot.com',
    messagingSenderId: '728014249794',
    appId: '1:728014249794:web:7aa26949f0abe70cd3f2db',
    measurementId: 'G-YNX1TK2ZG9'
}
export default firebase.initializeApp(firebaseConfig)
firebase.analytics()
