export interface ActionEventModel {
    props?: {}
    fn?: Function
    key: string
}

export interface DropdownItemModel {
    display: string
    event: ActionEventModel
}
export interface ActionModel {
    display?: string
    color?: string
    class?: string
    event: ActionEventModel
    hidden?: boolean
    loading?: boolean
    counter?: number
    type?: string
    test_id?: string
    active?: boolean
    disabled?: boolean
    current?: boolean
    dropdownItems?: DropdownItemModel[]
}

export const actionEmitted = ({ event, disabled }: ActionModel): void => {
    if (!event.fn || disabled) return
    event.fn(event.props)
}
