<template>
    <div
        ref="mapRef"
        :style="{ cursor: isStatic ? 'default' : 'grab' }"
        @mouseup="onMouseUp"
    >
        <slot />
    </div>
</template>
<script lang="ts">
import { getMapBounds } from 'shared/helpers/openLayers'
import { Map, View } from 'ol'
import { Control, defaults as defaultControls } from 'ol/control'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import XYZ from 'ol/source/XYZ'
import * as olInteraction from 'ol/interaction'
import * as olProj from 'ol/proj'
import Collection from 'ol/Collection'
import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    provide,
    ref,
    PropType
} from 'vue'
export default defineComponent({
    props: {
        controls: {
            type: Array as PropType<Control[]>,
            default: () =>
                defaultControls({
                    rotate: false
                })
        },
        isMapSelector: {
            type: Boolean,
            default: false
        },
        mapColor: {
            type: Boolean,
            default: false
        },
        // name isStatic is forced by style tag, normally would be static
        isStatic: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const map_config = {
            zoom: 13,
            minZoom: props.isMapSelector ? 6 : 10,
            maxZoom: 18,
            fitDuration: 400,
            defaultCenter: fromLonLat([21.0122, 52.2297]),
            mapColor: props.mapColor ? 'rastertiles/voyager' : 'light_all'
        }
        const mainLayer = new TileLayer({
            source: new XYZ({
                url: `https://cartodb-basemaps-b.global.ssl.fastly.net/${map_config.mapColor}/{z}/{x}/{y}.png`
            })
        })
        const vectorSource = computed(() => new VectorSource())
        const vectorLayer = computed(
            () => new VectorLayer({ source: vectorSource.value })
        )
        const mapRef = ref(undefined)
        const map = new Map({
            controls: props.controls,
            view: new View({
                enableRotation: false,
                center: map_config.defaultCenter,
                zoom: map_config.zoom,
                minZoom: map_config.minZoom,
                maxZoom: map_config.maxZoom
            }),
            layers: [mainLayer, vectorLayer.value]
        })

        provide('vectorSource', vectorSource)
        provide('vectorLayer', vectorLayer)
        provide('map', map)

        const onMouseUp = () => {
            if (props.isStatic) return
            map.getViewport().style.cursor = 'grab'
        }
        const setPosition = (extent: number[], zoom: any = undefined) => {
            map.getView().fit(extent, {
                size: map.getSize(),
                duration: map_config.fitDuration,
                maxZoom: zoom
            })
        }
        const centerMap = (lon: number, lat: number) => {
            map.getView().setCenter(fromLonLat([lon, lat]))
        }
        map.on('pointerdrag', () =>
            props.isStatic
                ? null
                : (map.getViewport().style.cursor = 'grabbing')
        )
        map.on('moveend', event => {
            const view = event.map.getView()
            const bounds = getMapBounds(event.map)
            const zoom = parseInt(view.getZoom()?.toString() ?? '0')
            emit('move', { zoom, bounds })
        })
        map.on('singleclick', event => {
            emit('click')
            const feature = event.map
                .getFeaturesAtPixel(event.pixel)
                .filter(f => f.getId())[0]
            if (feature) emit('select', feature.getProperties().payload)
        })
        map.on('pointermove', event => {
            if (props.isStatic) return
            const feature = event.map
                .getFeaturesAtPixel(event.pixel)
                .filter(f => f.getId())[0]
            const cursor = feature ? 'pointer' : 'grab'
            map.getViewport().style.cursor = cursor
            emit(
                'hover',
                (feature && feature.getProperties().payload) || undefined
            )
        })
        map.on('pointermove', event => {
            if (event.dragging || !props.isMapSelector) return
            const feature: any = event.map
                .getFeaturesAtPixel(event.pixel)
                .filter(f => f.getId())[0]
            const cursor = feature ? 'pointer' : ''
            map.getViewport().style.cursor = cursor
            if (!feature) return
            const HandleMapFeatureDrag = () => {
                let point = feature.getGeometry().getCoordinates()
                point = olProj.transform(point, 'EPSG:3857', 'EPSG:4326')
                emit('dragDrop', point)
            }
            const translate = new olInteraction.Translate({
                features: new Collection([feature])
            })
            map.addInteraction(translate)
            translate.on('translateend', HandleMapFeatureDrag)
        })

        onMounted(() => {
            map.setTarget(mapRef.value)
            if (props.isStatic)
                map.getInteractions().forEach(interaction =>
                    interaction.setActive(false)
                )
        })
        onUnmounted(() => {
            map.setTarget(undefined)
        })
        return { mapRef, onMouseUp, setPosition, centerMap }
    }
})
</script>
<style lang="scss">
.map {
    cursor: grab;
}
.ol-zoom {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border: 2px solid $grey-dark;
    border-radius: 4px;

    .ol-zoom-in,
    .ol-zoom-out {
        border: none;
        display: block;
        text-align: center;
        width: 30px !important;
        height: 30px !important;
        background-color: $white;
        font-size: 24px;
        padding: 0;
        color: $black;
        font-weight: 500;
        padding-bottom: 0.35rem;
        cursor: pointer;
        &:hover {
            background-color: $bg-grey;
        }
        @include mobile {
            padding-bottom: 30px;
        }
    }
    .ol-zoom-in {
        border-bottom: 1px solid $grey-dark;
    }
}
.z-index {
    z-index: 1 !important;
}
</style>
