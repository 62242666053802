import router from '@/router'
import Accounts from '@/store/modules/Accounts'
import ClientAccounts from '@/store/modules/ClientAccounts'
import { containsDigits, containsSpecialCharacters } from 'shared/helpers/utils'
import toast from './toast'

export const provideCurrentJWT = (): string => {
    return (
        ClientAccounts.currentClientAccount?.jwt ||
        Accounts.currentRole?.jwt ||
        ''
    )
}

export const checkClientPasswordRequirements = password => {
    if (!containsSpecialCharacters(password))
        toast.error('PASSWORD_MUST_CONTAIN_AT_LEAST_ONE_SYMBOL')
    else if (!containsDigits(password))
        toast.error('PASSWORD_MUST_CONTAIN_AT_LEAST_ONE_DIGIT')
    else if (password.length < 8) toast.error('PASSWORD_IS_TOO_SHORT')
    else return true

    return false
}

export const goToAccountReservations = () => {
    router.push({ name: 'account_reservations' })
}
