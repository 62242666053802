<template>
    <metainfo>
        <title>{{ metadata.title }}</title>
        <meta name="description" :content="metadata.description" />
        <meta property="og:image" :content="metadata.ogImage" />
    </metainfo>
    <router-view />
    <CookieBanner />
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { currentMeta } from '@/helpers/meta'
import { addFacebookPixelToHead } from './helpers/facebookPixel'
import { isDEV } from './helpers/env'
import { appOgImage } from './helpers/app'
import CookieBanner from '@/components/utils/CookieBanner.vue'
import { addHotjarToHead } from './helpers/Hotjar'
import { addGoogleTagManagerToHead } from './helpers/GoogleTagManager'

const metadata = computed(() => {
    return {
        title: currentMeta.value.title,
        description: currentMeta.value.description,
        ogImage: currentMeta.value.ogImage
            ? currentMeta.value.ogImage
            : appOgImage
    }
})
onMounted(() => {
    if (!isDEV()) {
        addFacebookPixelToHead()
        addHotjarToHead()
        addGoogleTagManagerToHead()
    }
})
</script>
<style lang="scss">
html {
    height: 100%;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
}
body {
    height: 100%;
}
#app {
    @extend .is-body-1-web;
    font-family: $tisa-sans-pro;
    color: $navy-blue-40;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-tap-highlight-color: transparent;
}
</style>
