<template>
    <div v-if="!cookiesAccepted" class="cookie-banner-container">
        <div class="cookie-banner">
            <Image r_src="cookie.svg" class="cookie-image" />
            <div class="cookie-content">
                <div class="cookie-text">
                    <span v-text="t('COOKIES_BANNER_CONTENT')" />
                    <b
                        v-text="` ${t('IN_PRIVACY_POLICY')}.`"
                        @click="goToPrivacyPolicy"
                    />
                </div>
                <button
                    class="cookie-button"
                    v-text="t('I_UNDERSTAND')"
                    @click="accept"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Image from '@/components/utils/Image.vue'
import { t } from '@/i18n'
import { goToPrivacyPolicy } from '@/helpers/regulations'
import { useLocalStorage } from '@vueuse/core'

const cookiesAccepted = useLocalStorage('cookies_accepted', false)

const accept = () => {
    cookiesAccepted.value = true
}
</script>

<style scoped lang="scss">
.cookie-banner-container {
    position: fixed;
    z-index: 1000;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    width: 100%;
}
.cookie-banner {
    @extend .box-shadow-2;
    background-color: $gray-20;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 80%;
    padding: 1rem;
}
.cookie-image {
    width: 50px;
    height: 50px;
    margin: 0 0.5rem;
    padding: 0.3rem;
    background-color: $gray-60;
    border-radius: 50%;
}
.cookie-text {
    margin-bottom: 0.5rem;
    margin-right: 3rem;
    b {
        cursor: pointer;
    }
}
.cookie-button {
    @extend .primary-button;
    @extend .is-button-text-1-web;
    text-transform: uppercase;
    padding: 0.5rem 4rem;
}

@include touch {
    .cookie-banner {
        flex-direction: column;
        text-align: center;
        width: 95%;
    }
    .cookie-text {
        @include is-body-3-mobile;
        margin-right: 0;
    }
}
</style>
