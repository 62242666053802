<template>
    <div class="staff-item">
        <div class="remove-container">
            <div class="index" v-text="index" />
            <Icon class="remove-item" icon="close" @click="remove" />
        </div>
        <FormCreator
            :fields="props.form"
            :errors="state.errors"
            @update="updateItem"
        />
    </div>
</template>

<script setup lang="ts">
import FormCreator from '@/components/form/FormCreator.vue'
import Icon from 'shared/components/utils/Icon.vue'
import { computed, reactive } from 'vue'
import Errors from 'shared/helpers/errors'

const emit = defineEmits<{
    (e: 'remove', id: number): void
    (e: 'update'): void
}>()
const props = defineProps({
    id: {
        type: Number,
        default: 0
    },
    form: {
        type: Object
    }
})
const state = reactive({
    errors: new Errors()
})

const remove = () => {
    emit('remove', props.id)
}
const updateItem = () => {
    emit('update')
}
const index = computed(() => {
    return `${props.id + 1}.`
})
</script>

<style scoped lang="scss">
.staff-item {
    margin: 2rem 0;
}
.index {
    @extend .is-subtitle-web;
}
.remove-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.remove-item {
    cursor: pointer;
}
.input-container {
    margin: 1rem 0;
}
</style>
