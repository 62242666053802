import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators'
import store, { shouldPreserve } from '@/store'
import { RequestModel } from '@/models/api.types'
import api from '@/api'
import { KidID, KidModel, KidRequestModel } from '@/models/kids.types'
import { addEntity, removeEntity, updateEntity } from '@/helpers/store'
import ClientAccounts from './ClientAccounts'
const base_url = 'v1/kids'

const name = 'kids'
const keys = ['kids']
@Module({
    name,
    dynamic: true,
    preserveState: shouldPreserve(name, keys),
    store
})
class Kids extends VuexModule {
    kids: KidModel[] = []

    @Mutation
    public CLEAR_STATE() {
        this.kids = []
    }
    @Mutation
    SET_KIDS(kids: KidModel[]) {
        this.kids = kids
    }
    @Mutation
    ADD_KID(kid: KidModel) {
        addEntity(kid, this.kids)
    }
    @Mutation
    EDIT_KID(kid: KidModel) {
        updateEntity(kid, this.kids)
    }
    @Mutation
    REMOVE_KID(kid_id: KidModel['id']) {
        removeEntity(kid_id, this.kids)
    }

    @Action
    public async getKids({ config = {} }: RequestModel = {}): Promise<
        KidModel[]
    > {
        const kids = await api.get(base_url, config)
        this.SET_KIDS(kids)
        return kids
    }
    @Action
    public async getKidById({
        config = {},
        payload
    }: RequestModel<{ id: KidID }> = {}): Promise<KidModel> {
        return api.get(`${base_url}/${payload.id}`, config)
    }
    @Action
    public async createKid({
        config = {},
        payload
    }: RequestModel<KidRequestModel> = {}): Promise<KidModel> {
        const kid = await api.post(base_url, payload, config)
        this.ADD_KID(kid)
        ClientAccounts.refreshJWT()
        return kid
    }
    @Action
    public async editKid({
        config = {},
        payload,
        id
    }: RequestModel<KidRequestModel> = {}): Promise<KidModel> {
        const kid = await api.patch(`${base_url}/${id}`, payload, config)
        this.EDIT_KID(kid)
        ClientAccounts.refreshJWT()
        return kid
    }
    @Action
    public async deleteKid({
        config = {},
        payload
    }: RequestModel<{ id: string }> = {}): Promise<KidModel> {
        const kid = await api.delete(`${base_url}/${payload.id}`, config)
        this.REMOVE_KID(kid.id)
        ClientAccounts.refreshJWT()
        return kid
    }

    get listKids() {
        return this.kids || []
    }
}

export default getModule(Kids)
