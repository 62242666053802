<template>
    <button
        :name="`button-${name}`"
        class="button"
        :class="[
            `is-${color}`,
            `is-${size}`,
            { 'is-loading': loading, '-disabled': disabled, special }
        ]"
    >
        <slot />
        <div class="has-text-weight-semibold flex-row">
            <Icon
                v-if="icon"
                :icon="icon"
                :color="icon_color"
                :size="icon_size"
                class="mr-1"
            />
            {{ t(display) }}
            <span v-if="filtersLength" class="has-text-primary">
                ({{ filtersLength }})</span
            >
        </div>
    </button>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { t } from '@/i18n'
import Icon from 'shared/components/utils/Icon.vue'
export default defineComponent({
    components: {
        Icon
    },
    props: {
        name: String,
        display: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: Boolean,
        size: {
            type: String,
            default: 'normal'
        },
        color: {
            type: String,
            default: 'primary'
        },
        filtersLength: {
            type: Number,
            default: 0
        },
        icon: {
            type: Object
        },
        icon_color: {
            type: String,
            default: 'primary'
        },
        icon_size: {
            type: String,
            default: ''
        }
    },
    setup() {
        return { t }
    }
})
</script>

<style lang="scss" scoped>
.button {
    border-radius: 4px;
    transition: opacity 0.3s ease;
    .flex-row {
        align-items: center !important;
        justify-content: center !important;
        justify-items: center !important;
    }
    &.-disabled {
        background-color: $grey;
        opacity: 0.8;
        pointer-events: none;
    }
    &.special {
        padding: 11px 52px;
        font-size: 16px;
        border-radius: 8px;
        &:hover {
            transform: none;
        }
    }
}
</style>
