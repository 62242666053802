import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { RequestModel } from '@/models/api.types'
import api from '@/api'
import { BlogID, BlogModel, BlogRequestModel } from '@/models/blog.types'
import { addEntity, removeEntity, updateEntity } from '@/helpers/store'
const base_url = 'v1/blog'
const name = 'blogs'
@Module({
    name,
    dynamic: true,
    store
})
class Blogs extends VuexModule {
    blogs: BlogModel[] = []
    article: BlogModel = null

    @Mutation
    public CLEAR_STATE() {
        this.blogs = []
    }
    @Mutation
    SET_BLOGS(blogs: BlogModel[]) {
        this.blogs = blogs
    }
    @Mutation
    ADD_BLOG(blog: BlogModel) {
        addEntity(blog, this.blogs)
    }
    @Mutation
    EDIT_BLOG(blog: BlogModel) {
        updateEntity(blog, this.blogs)
    }
    @Mutation
    REMOVE_BLOG(blog_id: BlogModel['id']) {
        removeEntity(blog_id, this.blogs)
    }
    @Mutation
    SET_ARTICLE(article: BlogModel) {
        this.article = article
    }

    @Action
    public async getBlogs({ config = {} }: RequestModel = {}): Promise<
        BlogModel[]
    > {
        const blogs = await api.get(`${base_url}/list`, config)
        this.SET_BLOGS(blogs)
        return blogs
    }
    @Action
    public async getBlogById({
        config = {},
        payload
    }: RequestModel<{ id: BlogID }> = {}): Promise<BlogModel> {
        const { id } = payload
        const article = await api.get(`${base_url}/list/${id}`, config)
        this.SET_ARTICLE(article)
        return article
    }
    @Action
    public async createBlog({
        config = {},
        payload
    }: RequestModel<BlogRequestModel> = {}): Promise<BlogModel[]> {
        const blog = await api.post(`${base_url}/list`, payload, config)
        this.ADD_BLOG(blog)
        return blog
    }
    @Action
    public async editBlog({
        config = {},
        payload
    }: RequestModel<BlogRequestModel> = {}): Promise<BlogModel[]> {
        const blog = await api.patch(
            `${base_url}/list/${payload.id}`,
            payload,
            config
        )
        this.EDIT_BLOG(blog)
        return blog
    }
    @Action
    public async deleteBlog({
        config = {},
        payload
    }: RequestModel<{ id: BlogID }> = {}): Promise<{ id: BlogID }> {
        const { id } = await api.delete(
            `${base_url}/list/${payload.id}`,
            config
        )
        this.REMOVE_BLOG(id)
        return id
    }
    @Action
    public async searchBlogs({
        config = {},
        payload
    }: RequestModel<{ text: string }> = {}): Promise<BlogModel[]> {
        const blogs = await api.post(`${base_url}/search`, payload, config)
        return blogs
    }

    get listBlogs() {
        return this.blogs
    }
    get currentArticle() {
        return this.article
    }
}

export default getModule(Blogs)
