const valueREG = /(=|>|<|\*)/g
const keyREG = /(\[]|\*)/g
const getOperatorValue = (value: any) => {
    value = value.toString()
    let operator = ''
    switch (true) {
        case value.includes('>'):
            operator = '>'
            break
        case value.includes('<'):
            operator = '<'
            break
        case value.includes('!'):
            operator = '!='
            value = value.replace('!', '')
            break
        default:
            operator = '='
            break
    }
    Array.isArray(value)
        ? (value = value.map(v => v.replace(valueREG, '')))
        : (value = value.replace(valueREG, ''))
    return `${operator}"${value}"`
}
const getFilterType = (key: string): string => {
    switch (true) {
        case key.includes('[]'):
            return 'ARRAY'
        case key.includes('*'):
            return 'MATCH'
        default:
            return ''
    }
}
const getANDFilter = ({ key, value, type }: any) => {
    switch (type) {
        case 'ARRAY':
            // TODO: BRING PARENTHESIS BACK WHEN BE FIXED
            return `${key}<-${value}`
        case 'MATCH':
            return `(${key}=i="*${value}*")`
        default:
            return `(${key}${getOperatorValue(value)})`
    }
}
const getORFilter = ({ key, value, type }: any) => {
    switch (type) {
        case 'ARRAY':
            return `(${value.map((v: any) => `(${key}<-${v})`).join(' OR ')})`
        case 'MATCH':
            return `(${value
                .map((v: any) => `(${key}=i="*${v}*")`)
                .join(' OR ')})`
        default:
            return `(${value
                .map((v: any) => `(${key}${getOperatorValue(v)})`)
                .join(' OR ')})`
    }
}
/**
 * Creates filter query for request params
 *
 * @param filter Array of objects ```{ key: value }``` to filter by
 * @returns  Object containing filter query ```{ query: string }``
 */
export default (filter: any) => {
    if (!filter) return {}
    const filters = filter
        .filter((f: any) => f)
        .map((ORFilter: any) => {
            const querys = Object.entries(ORFilter)
                .map(([key, value]) => {
                    if (value == undefined) return
                    const type = getFilterType(key)
                    key = key.replace(keyREG, '')
                    if (key == 'custom' && !`${value}`.trim()) return
                    if (key == 'custom') return `(${value})`
                    if (Array.isArray(value))
                        return getORFilter({ key, value, type })
                    return getANDFilter({ key, value, type })
                })
                .filter(q => q)
            return querys.length > 1 ? `(${querys.join(' OR ')})` : `${querys}`
        })
        .filter((f: any) => f)
    const query =
        filters.length > 1 ? `(${filters.join(' AND ')})` : `${filters}`
    // NOTE: FOR DEBUGGING
    //    if (filters.length) console.log({ query, filters })
    return { query }
}
