import axios from 'axios'
import qs from 'qs'
import { GatewayRequestModel } from './gateway.types'

/**
 * Universal gateway for all APIs.
 * Sends request and gets response from server.
 * Catches errors and passes them to specified callback function 'errorFN'.
 * @returns Server response
 */
export default async ({
    method,
    url = '',
    payload = {},
    config = {}, // Config is defined by gateway client
    params = {},
    headers = {},
    base_url = '',
    responseType,
    errorFN
}: GatewayRequestModel) => {
    return axios({
        baseURL: base_url,
        method,
        url,
        data: payload || {},
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        responseType,
        paramsSerializer: params =>
            qs.stringify(params, { arrayFormat: 'repeat' }),
        params
    })
        .then((response: any) => response)
        .catch(error =>
            errorFN(error, config, configFromError => axios(configFromError))
        )
}
