<template>
    <div>
        <div class="pt-2">
            <transition-group>
                <div v-if="state.currentFiles.length" class="grid">
                    <div
                        v-for="(file, index) in state.currentFiles"
                        :key="index"
                    >
                        <div class="attachment-container">
                            <div class="attachment-buttons">
                                <div
                                    class="remove-attachment"
                                    @click="removeAttachment(file)"
                                >
                                    <CustomIcon icon="bin_outlined" />
                                </div>
                            </div>
                            <img
                                class="image-attachment"
                                :src="fileSrc(file)"
                            />
                        </div>
                        <div
                            v-if="!props.field.fileNameHidden"
                            class="name-attachment"
                            v-text="file.name"
                        />
                    </div>
                </div>
            </transition-group>
            <button
                v-if="addButtonVisible"
                class="add-button"
                v-text="t(placeholder)"
                @click="toggleInput"
            />
        </div>
        <input
            ref="fileInput"
            style="display: none"
            :multiple="!props.field.single"
            type="file"
            @input.prevent="onInput"
        />
    </div>
</template>
<script lang="ts" setup>
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import Button from 'shared/components/utils/Button.vue'
import 'cropperjs/dist/cropper.css'
import { t } from '@/i18n'
import storage from '@/firebase/storage'
import { getAsset } from '@/helpers/utils'
import { AttachmentFieldModel } from 'shared/models/formContent.types'
import CustomIcon from '../icons/CustomIcon.vue'

const emit = defineEmits<{
    (e: 'input', files: any): void
    (e: 'update:modelValue', files: any): void
}>()

interface Props {
    field: AttachmentFieldModel
    modelValue: string
}
const props = defineProps<Props>()

const fileInput = ref()
const state: any = reactive({
    loading: false,
    currentFiles: []
})
const addButtonVisible = computed(
    () =>
        (props.field.single && props.field.value.length != 1) ||
        !props.field.single
)
const placeholder = computed(() => props.field.placeholder || 'ADD_ATTACHMENT')

const handleInstantUpload = async event => {
    const urls = await storage.uploadFilesFromEvent(event)
    return storage.getFiles(urls)
}
const onInput = async (event: any) => {
    const files = props.field.instant_upload
        ? await handleInstantUpload(event)
        : await storage.addFilesFromEvent(event)
    state.currentFiles.push(
        ...files.map(({ file, name, url, type }) => ({
            name: name,
            url: url || window.URL.createObjectURL(file),
            type,
            file
        }))
    )
    emitFiles(state.currentFiles)
}
const emitFiles = (files: any[]) => {
    emit('update:modelValue', files)
    emit('input', files)
}
const getFiles = async () => {
    if (!props.field.value.length) return []
    const attachmentsArray = Array.isArray(props.field.value)
        ? props.field.value
        : [props.field.value]
    const promises = attachmentsArray.map(async attachment =>
        attachment.url ? attachment : await storage.getFile(attachment)
    )

    return Promise.all(promises)
}
const fileSrc = ({
    file,
    url,
    type
}: {
    file: any
    url: string
    type: string
}) => {
    return file?.type?.includes('image') || type?.includes('image')
        ? url
        : getAsset('file.svg')
}

const removeAttachment = ({ url, path }: { url: string; path: string }) => {
    const index = state.currentFiles.findIndex(f => f.url == url)
    if (path) storage.removeFile(path)
    state.currentFiles.splice(index, 1)
    emitFiles(state.currentFiles)
}
const toggleInput = () => {
    if (props.field.disabled) return
    fileInput.value.click()
}
onMounted(() => {
    nextTick(async () => {
        state.currentFiles = await getFiles()
    })
})
</script>
<style lang="scss" scoped>
.attachment-container {
    position: relative;
    width: fit-content;
}
.attachment-buttons {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    display: flex;
    gap: 0.5rem;
}
.remove-attachment {
    background-color: $white;
    border-radius: 50%;
    padding: 0.3rem;
    cursor: pointer;
}
.image-attachment {
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
}
.name-attachment {
    font-size: 10px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 1rem;
}
.add-button {
    @extend .secondary-button-admin;
    @extend .is-button-text-1-web;
    color: $navy-blue-60;
}
</style>
