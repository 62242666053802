<template>
    <router-link
        :to="
            !props.reservationPreview
                ? getProductProfileRouterOptions(props.product)
                : ''
        "
        :style="{ cursor: props.reservationPreview ? 'default' : 'pointer' }"
    >
        <div class="product">
            <DayCampBadge v-if="badge" class="product-badge" :badge="badge" />
            <Image
                v-if="props.product.attachments.length == 0"
                class="slider-placeholder"
            />
            <Image
                v-else-if="props.reservationPreview"
                :a_src="props.product.attachments[0]"
                class="product-img"
            />
            <Splide
                v-else
                class="slider"
                :options="state.splideOptions"
                :has-track="false"
            >
                <SplideTrack>
                    <SplideSlide
                        v-for="(img, index) in props.product.attachments"
                        :key="index"
                    >
                        <Image
                            :a_src="img"
                            :alt="alt"
                            class="product-img"
                            :width="previewImageWidth"
                        />
                    </SplideSlide>
                </SplideTrack>
                <div class="splide__arrows" @click.prevent />
            </Splide>
            <div class="product-text">
                <div class="product-name-container">
                    <h2 class="product-name" v-text="props.product.title" />
                    <Icon
                        v-if="props.verified || props.product.owner.verified"
                        icon="check_circle"
                        :color="COLORS.green"
                        size="6"
                    />
                </div>
                <div class="product-owner" v-text="props.product.owner_name" />
                <div class="product-description">
                    <div class="product-info">
                        <div class="product-info-item">
                            <LocationIcon
                                :color="COLORS.gray80"
                                class="location"
                            />
                            <div
                                v-text="getProductFullAddress(props.product)"
                            />
                        </div>
                        <div
                            v-if="props.product.type == ProductType.DayCamp"
                            class="product-info-item"
                        >
                            <CalendarIcon :color="COLORS.gray80" />
                            <div v-text="getProductDateRange(props.product)" />
                        </div>
                        <div
                            v-if="props.product.type == ProductType.Class"
                            class="product-info-item"
                        >
                            <CalendarIcon :color="COLORS.gray80" />
                            <div
                                v-if="
                                    isZeroDate(
                                        props.product.schedule?.start_date
                                    ) ||
                                    props.product.recrutation_status ==
                                        'recrutation_to_be_started'
                                "
                                v-text="t('RECRUITMENT_FOR_NEW_SCHOOL_YEAR')"
                            />
                            <div v-else v-text="classDateRangeDisplay" />
                        </div>

                        <div v-if="!unspecifiedAge" class="product-info-item">
                            <KidIcon :color="COLORS.gray80" />
                            <div
                                v-text="
                                    getProductAgeRangeDisplay(props.product)
                                "
                            />
                        </div>
                        <!-- TODO: enable when ratings are ready 31.05.22
                        <div class="product-info-item">
                            <Icon icon="star" color="#FFE16D" size="6" />
                            <div v-text="props.product.rating" />
                        </div> -->
                    </div>
                    <div
                        v-if="
                            !props.reservationPreview &&
                            props.product.type == ProductType.DayCamp
                        "
                        class="product-price"
                    >
                        <div
                            class="product-price-bundle"
                            v-text="getProductPriceBundleDisplay(props.product)"
                        />
                        <div
                            v-if="props.product.price_single_day"
                            class="product-price-single"
                            v-text="
                                getProductPriceSingleDayDisplay(props.product)
                            "
                        />
                    </div>
                    <div
                        v-else-if="
                            !props.reservationPreview &&
                            props.product.type == ProductType.Class &&
                            getClassProductAdvancePriceDisplay(props.product) !=
                                null
                        "
                        class="product-price"
                    >
                        <div
                            v-if="
                                props.product.recrutation_status ==
                                    'recrutation_to_be_started' && !isMobile
                            "
                            class="recruitment-button"
                            v-text="t('NOTIFY_ME_ABOUT_RECRUITMENT')"
                            @click.prevent="
                                state.recruitmentModalVisible = true
                            "
                        />
                        <div
                            class="product-price-single"
                            v-text="
                                getClassProductAdvancePriceDisplay(
                                    props.product
                                )
                            "
                        />
                    </div>
                </div>
                <div
                    v-if="
                        props.product.recrutation_status ==
                            'recrutation_to_be_started' && isMobile
                    "
                    class="recruitment-button"
                    v-text="t('NOTIFY_ME_ABOUT_RECRUITMENT')"
                    @click.prevent="state.recruitmentModalVisible = true"
                />
            </div>
        </div>
    </router-link>
    <transition>
        <component
            v-if="state.recruitmentModalVisible"
            :is="recruitmentModal"
            :basic="true"
            @close="state.recruitmentModalVisible = false"
        >
            <Loading
                v-if="state.loading.recruitmentForm"
                class="recruitment-loading"
            />
            <div v-else-if="!state.formSend">
                <div
                    class="recruitment-title"
                    v-text="t('DO_YOU_WANT_TO_RESERVE_THESE_CLASSES')"
                />
                <div
                    class="recruitment-description"
                    v-text="t('RECRUITMENT_HAS_NOT_STARTED_YET_GIVE_EMAIL')"
                />
                <ProfileSignUpFormEmail
                    inModal
                    @sendReservationMessage="sendReservationMessage"
                />
            </div>
            <div v-else class="recruitment-thanks">
                <div
                    class="recruitment-thanks-text"
                    v-text="
                        t(
                            'THANKS_WE_WILL_SEND_INVITATION_WHEN_RECRUITMENT_STARTS'
                        )
                    "
                />
                <button
                    class="recruitment-thanks-button"
                    v-text="t('RETURN')"
                    @click="() => (state.recruitmentModalVisible = false)"
                />
            </div>
        </component>
    </transition>
</template>

<script setup lang="ts">
import { reactive, computed, ref, onMounted } from 'vue'
import Image from '@/components/utils/Image.vue'
import Icon from 'shared/components/utils/Icon.vue'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
import {
    getProductFullAddress,
    getProductDateRange,
    getProductAgeRangeDisplay,
    getProductPriceBundleDisplay,
    getProductPriceSingleDayDisplay,
    getProductBadge,
    getClassProductAdvancePriceDisplay,
    isZeroDate,
    getProductProfileRouterOptions
} from '@/helpers/product'
import DayCampBadge from './DayCampBadge.vue'
import { COLORS } from '@/helpers/colors'
import { ProductModel, ProductType } from '@/models/product.types'
import { t } from '@/i18n'
import LocationIcon from '@/components/icons/LocationIcon.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import KidIcon from '@/components/icons/KidIcon.vue'
import { getClassDateRange } from '@/helpers/classProfile'
import { ClassModel } from '@/models/class.types'
import ProfileSignUpFormEmail from './ProfileSignUpFormEmail.vue'
import Modal from '@/components/utils/Modal.vue'
import Loading from '@/components/utils/Loading.vue'
import Slack from '@/store/modules/Slack'
import toast from '@/helpers/toast'
import { useRouter } from 'vue-router'
import Owners from '@/store/modules/Owners'
import { getCustomerPipedrivePayload } from '@/helpers/owners'
import { getSlackCustomerPayload } from '@/helpers/slack'
import { isMobile } from '@/helpers/utils'
import MobileModal from '@/components/utils/MobileModal.vue'

const recruitmentModal = computed(() => (isMobile.value ? MobileModal : Modal))

interface Props {
    product: ProductModel | ClassModel
    reservationPreview: boolean
    verified?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    reservationPreview: false
})

const state = reactive({
    splideOptions: {
        arrows: true,
        pagination: true,
        perPage: 1
    },
    loading: {
        recruitmentForm: false
    },
    recruitmentModalVisible: false,
    formSend: false,
    verified: false,
    initialScreenWidth: 1000
})
const badge = computed(() => {
    return getProductBadge(props.product)
})
const alt = computed(() => {
    return `${props.product.title} - ${props.product.owner_name} - ${t(
        'DAYCAMP'
    )} ${props.product.city}`
})
const classDateRangeDisplay = computed(() => {
    return (props.product as ClassModel).schedule?.start_date
        ? getClassDateRange(props.product as ClassModel)
        : t('RECRUITMENT_FOR_NEW_SCHOOL_YEAR')
})
const unspecifiedAge = computed(() => {
    return props.product.age_tags.length == 0
})
const previewImageWidth = computed(() => {
    return isMobile.value ? state.initialScreenWidth : 260
})

const sendReservationMessage = async (email: string) => {
    try {
        state.loading.recruitmentForm = true
        const payload = getCustomerPipedrivePayload(email, props.product)
        await Owners.addOwnerContactInfo({
            payload
        })
        await Slack.sendDayCampReservation({
            payload: getSlackCustomerPayload(email, props.product)
        })
        state.formSend = true
    } catch (error) {
        toast.error(t('SOMETHING_WENT_WRONG'))
    } finally {
        state.loading.recruitmentForm = false
    }
}

onMounted(() => {
    state.initialScreenWidth = document.body.clientWidth
})
</script>

<style scoped lang="scss">
.product {
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    background-color: white;
    position: relative;
}
.product-badge {
    position: absolute;
    top: 1.5rem;
    left: 0.5rem;
    z-index: 5;
}
.slider {
    width: 260px;
}
.slider-placeholder {
    border-radius: 10px;
    object-fit: cover;
    width: 260px;
    height: 180px;
}
.product-img {
    border-radius: 10px;
    object-fit: cover;
    width: 260px;
    height: 180px;
    display: block;
}
.product-text {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}
.product-name-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.product-name {
    @extend .is-subtitle-bold-web;
    color: $navy-blue-40;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.product-owner {
    @extend .is-body-2-web;
    color: $gray-80;
    padding: 0.3rem 0;
}
.product-description {
    display: flex;
    flex: 1;
}
.product-info {
    flex: 1;
}
.product-info-item {
    @extend .is-body-2-web;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.2rem 0;
    color: $gray-80;

    svg {
        width: 20px;
        height: 20px;
    }
    .location {
        width: 16px;
        height: 20px;
        margin: 0 2px;
    }
}
.product-price {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    color: $navy-blue-40;
}
.product-price-bundle {
    @extend .is-body-2-web;
}
.product-price-single {
    @extend .is-body-2-web;
}
.recruitment-button {
    @extend .tertiary-button;
    @extend .is-body-3-web;
    padding: 0.3rem 0.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
}
.recruitment-modal {
    :deep(.modal-card) {
        width: 460px;
    }
}
.recruitment-title {
    @extend .is-h6-web;
    margin-bottom: 1.5rem;
}
.recruitment-description {
    @extend .is-body-1-web;
    margin-bottom: 1.5rem;
}
.recruitment-thanks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.recruitment-thanks-text {
    @extend .is-h6-web;
    text-align: center;
    margin-bottom: 1.5rem;
}
.recruitment-thanks-button {
    @extend .primary-button;
    @extend .is-button-text-1-web;
    text-transform: uppercase;
    padding: 0.5rem 4rem;
}

@include touch {
    .product {
        flex-direction: column;
    }
    .slider {
        width: 100%;
    }
    .product-img {
        width: 100%;
        height: 30vh;
    }
    .slider-placeholder {
        width: 100%;
        height: 30vh;
    }
    .recruitment-button {
        margin-top: 0.5rem;
        width: 50%;
    }
}
</style>
