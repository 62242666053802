import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators'
import store, { shouldPreserve } from '@/store'
import { RequestModel } from '@/models/api.types'
import api from '@/api'
import { addEntity, removeEntity, updateEntity } from '@/helpers/store'
import { TagID, TagModel, TagRequestModel } from '@/models/tag.types'
const base_url = 'v1/tags'
const name = 'tags'
const keys = ['tags']
@Module({
    name,
    dynamic: true,
    preserveState: shouldPreserve(name, keys),
    store
})
class Tags extends VuexModule {
    tags: TagModel[] = []

    @Mutation
    public CLEAR_STATE() {
        this.tags = []
    }
    @Mutation
    SET_TAGS(tags: TagModel[]) {
        this.tags = tags
    }
    @Mutation
    ADD_TAG(tag: TagModel) {
        addEntity(tag, this.tags)
    }
    @Mutation
    EDIT_TAG(tag: TagModel) {
        updateEntity(tag, this.tags)
    }
    @Mutation
    REMOVE_TAG(tag_id: TagModel['id']) {
        removeEntity(tag_id, this.tags)
    }
    @Action
    public async getTags({ config = {} }: RequestModel = {}): Promise<
        TagModel[]
    > {
        const tags = await api.get(base_url, config)
        this.SET_TAGS(tags)
        return tags
    }
    @Action
    public async getTagById({
        config,
        payload
    }: RequestModel<{ id: TagID }> = {}): Promise<TagModel> {
        const { id } = payload
        const tag = await api.get(`${base_url}/${id}`, config)
        this.EDIT_TAG(tag)
        return tag
    }
    @Action
    public async createTag({
        config = {},
        payload
    }: RequestModel<TagRequestModel> = {}): Promise<TagModel[]> {
        const tags = await api.post(base_url, payload, config)
        this.ADD_TAG(tags)
        return tags
    }
    @Action
    public async editTag({
        config = {},
        payload
    }: RequestModel<TagRequestModel> = {}): Promise<TagModel[]> {
        const tag = await api.patch(
            `${base_url}/${payload.id}`,
            payload,
            config
        )
        this.EDIT_TAG(tag)
        return tag
    }
    @Action
    public async deleteTag({
        config = {},
        payload
    }: RequestModel<{ id: TagID }> = {}): Promise<{ id: TagID }> {
        const { id } = await api.delete(`${base_url}/${payload.id}`, config)
        this.REMOVE_TAG(id)
        return id
    }

    get listTags() {
        return this.tags || []
    }
}

export default getModule(Tags)
