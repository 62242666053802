import {
    DayCampPriceType,
    ProductID,
    ProductModel
} from '@/models/product.types'
import { Timestamp, NumberID } from 'shared/models/generic.types'
import { ClassModel, ClassPriceType } from './class.types'
import { KidModel } from './kids.types'
import { OwnerModel } from './owner.types'

// TODO: 'payment_status' replace it with enum 1.09.22
export const payment_status = {
    created: 'created',
    pending: 'pending',
    paid: 'paid',
    cancelled: 'cancelled'
}

export type ReservationPriceType = ClassPriceType | DayCampPriceType
export enum ReservationTicketType {
    Range = 'range',
    SingleDay = 'single_day',
    Trial = 'trial',
    Advance = 'advance',
    Semester = 'semester'
}

export type ReservationID = string
export type ReservationGatewayID = string

export interface ReservationGateway {
    bankName: string
    gatewayID: ReservationGatewayID
    gatewayName: string
    gatewayType: string
    iconURL: string
    statusDate: string
}
export interface ReservationModel {
    id: ReservationID
    created_at: Timestamp

    product_id: ProductID
    product: ProductModel
    account_id?: NumberID
    kid_id?: string
    amount: number
    kid_name: string
    kid_surname: string
    kid_age: number
    kid_birthdate: string
    title: string
    scope: ReservationTicketType
    date?: string
    payment_status: keyof typeof payment_status
    is_money_transferred: boolean
    owner: OwnerModel
    commission: number
}

export interface ReservationWithGateways {
    reservation: ReservationModel
    gateways: ReservationGateway[]
}

export interface ReservationInProgress {
    product: ProductModel | ClassModel
    ticket: {
        kids: KidModel[]
        type: ReservationPriceType
        dates: string[]
    }
    reservation: ReservationWithGateways
}

export type ReservationRequestModel = Pick<
    ReservationModel,
    | 'product_id'
    | 'account_id'
    | 'kid_id'
    | 'kid_name'
    | 'kid_surname'
    | 'kid_age'
    | 'kid_birthdate'
    | 'title'
    | 'scope'
    | 'date'
>
