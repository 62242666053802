import {
    ProductModel,
    ProductRecrutationStatus,
    ProductStaff,
    ProductType,
    reported_to_board_of_education
} from '@/models/product.types'
import { minutes } from 'shared/helpers/time'
import router from '@/router'
import { t } from '@/i18n'
import { dateWithFormat } from 'shared/helpers/date'
import { toSentenceCase } from 'shared/helpers/utils'
import Products from '@/store/modules/Products'
import { ClassModel } from '@/models/class.types'
import Owners from '@/store/modules/Owners'
import { getOwnerAgeRangeObject } from './owners'
import { OwnerModel } from '@/models/owner.types'

export interface CityWithProductTypes {
    city: string
    types: {
        key: string
        display: string
    }[]
}

export const getProductFullAddress = (product: ProductModel) => {
    const { street, building_number, apartment_number, city } = product
    return apartment_number
        ? `${street} ${building_number}/${apartment_number}, ${city}`
        : `${street} ${building_number}, ${city}`
}

export const getProductDateRange = (product: ProductModel) => {
    const { dates } = product
    const start = dateWithFormat({ date: dates[0], format: 'D.MM.YY' })
    const end = dateWithFormat({
        date: dates[dates.length - 1],
        format: 'D.MM.YY'
    })
    return `${start}-${end}`
}
export const getProductDateRangeObject = (product: ProductModel) => {
    const { dates } = product
    const start = dateWithFormat({ date: dates[0], format: 'YYYY-MM-DD' })
    const end = dateWithFormat({
        date: dates[dates.length - 1],
        format: 'YYYY-MM-DD'
    })
    return { start, end }
}

export const getProductStartDate = (product: ProductModel) => {
    return dateWithFormat({
        date: getProductDateRangeObject(product).start,
        format: 'D.MM.YYYY'
    })
}

export const getProductEndDate = (product: ProductModel) => {
    return dateWithFormat({
        date: getProductDateRangeObject(product).end,
        format: 'D.MM.YYYY'
    })
}

export const getProductRecruitmentStart = (product: ProductModel) => {
    return dateWithFormat({
        date: product.recrutation_start_date,
        format: 'D.MM.YYYY'
    })
}

export const getProductRecruitmentEnd = (product: ProductModel) => {
    return dateWithFormat({
        date: product.recrutation_end_date,
        format: 'D.MM.YYYY'
    })
}

export const getProductHoursRange = (product: ProductModel) => {
    const { time_start, time_end } = product
    return `${minutes(time_start)}-${minutes(time_end)}`
}

export const getProductAgeRangeObject = (product: ProductModel) => {
    const { age_tags } = product
    const ages = age_tags.map(tag => parseInt(tag.split('_')[0]))
    ages.sort((a, b) => a - b)
    return {
        min: ages[0],
        max: ages[ages.length - 1]
    }
}

export const getProductAgeRange = (product: ProductModel | OwnerModel) => {
    const range = product.owner_id
        ? getProductAgeRangeObject(product as ProductModel)
        : getOwnerAgeRangeObject(product as OwnerModel)
    return `${range.min}-${range.max}`
}

export const getProductAgeRangeDisplay = (product: ProductModel) => {
    return `${getProductAgeRange(product)} ${t('OF_YEARS')}`
}

export const getCitiesWithTypes = (
    products: ProductModel[]
): CityWithProductTypes[] => {
    const cities = new Set(products.map(product => product.city))
    return Array.from(cities).map(city => ({
        city,
        types: Array.from(
            new Set(
                products
                    .filter(product => product.city == city)
                    .map(product => getProductTypeDisplay(product))
            )
        )
    }))
}
export const getDayCampCities = (products: ProductModel[]) => {
    const cities = new Set(
        products
            .filter(product => product.type == 'day_camp')
            .map(product => product.city)
    )
    return Array.from(cities)
}
export const getProductTypeDisplay = (product: ProductModel) => {
    const displays = {
        day_camp: toSentenceCase(t('DAYCAMPS'))
    }
    return displays[product.type]
}
export const getStaffFullName = (staff: ProductStaff) => {
    return `${staff.name} ${staff.surname}`
}
export const getProductPriceDisplay = (productPrice: number) => {
    return `${productPrice * 0.01} ${t('PLN_ABBR')}`
}
export const getProductPriceBundleDisplay = (product: ProductModel) => {
    return `${product.price_bundle * 0.01} ${t('PLN_ABBR')}`
}
export const getProductPriceSingleDayDisplay = (product: ProductModel) => {
    return `${product.price_single_day * 0.01} ${t('PLN_ABBR')}/${t('DAY')}`
}
export const getClassProductAdvancePriceDisplay = (product: ClassModel) => {
    // TODO: decide which type of price we want to display
    // currently advance if not semester if not trial if not it returns null
    if (!product || !product?.prices) return null
    const price =
        product.prices.find(({ type }) => type == 'advance')?.value ||
        product.prices.find(({ type }) => type == 'semester')?.value ||
        product.prices.find(({ type }) => type == 'trial')?.value
    return price
        ? `${t('FROM').toLowerCase()} ${price * 0.01} ${t('PLN_ABBR')}`
        : null
}
export const getProductProfileRouterOptions = (product: ProductModel) => {
    const names = {
        day_camp: 'daycamp_profile',
        class_profile: 'class_profile'
    }
    return {
        name: names[product.type],
        params: {
            product_slug: product.slug,
            owner_slug: product.owner.slug,
            city: product.city
        }
    }
}
export const goToProductProfile = (product: ProductModel) => {
    router.push(getProductProfileRouterOptions(product))
}
export const isProductReportedToBoardOfEducation = (product: ProductModel) => {
    return product.other_tags.includes(reported_to_board_of_education)
}
export const getProductBadge = (product: ProductModel) => {
    const badges = {
        [ProductRecrutationStatus.Finished]: {
            text: t('RECRUITMENT_ENDED'),
            colorClass: 'purple'
        },
        [ProductRecrutationStatus.ToBeStarted]: {
            text: t('RECRUITMENT_STARTING_SOON'),
            colorClass: 'pink'
        }
    }
    return product.recrutation_status == ProductRecrutationStatus.Started
        ? hasEmptySpots(product)
            ? false
            : {
                  text: t('NO_EMPTY_SPOTS'),
                  colorClass: 'sea'
              }
        : badges[product.recrutation_status]
}
export const getPublishedDayCamps = async () => {
    return Products.getProducts({
        config: {
            filter: [
                {
                    type: ProductType.DayCamp
                },
                {
                    recrutation_status: `!=${ProductRecrutationStatus.Draft}`
                }
            ]
        }
    })
}
export const getProductUrl = (product: ProductModel | ClassModel) => {
    const route = router.resolve(getProductProfileRouterOptions(product))
    return `${window.location.origin}${route.href}`
}
export const isZeroDate = (date: string) => {
    const zeros = ['0000-00-00', '1900-01-01']
    return zeros.includes(date)
}
export const isStringEmptyOrFake = (str: string) => {
    const toCompare = ['FAKE_STRING', '']
    return toCompare.includes(str)
}
export const hasEmptySpots = (product: ProductModel | ClassModel) => {
    return product.days.some(({ free_slots }) => free_slots > 0)
}
