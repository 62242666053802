import storage from '@/firebase/storage'
import { goToSupportTab } from '@/helpers/support'
import {
    product_form,
    owner_form,
    categoriesAddTagForm,
    article_form,
    class_form,
    faqPresetItemsDayCamp
} from '@/helpers/support/supportForms'
import {
    createArticlePayload,
    createClassPayload,
    createOwnerPayload,
    createProductPayload,
    createTagPayload
} from '@/helpers/support/supportPayloads'
import { ClassPriceType } from '@/models/class.types'
import { SupportCreators, SupportButtonFnConfig } from '@/models/support.types'
import { TagID, TagModel } from '@/models/tag.types'
import Blogs from '@/store/modules/Blogs'
import Classes from '@/store/modules/Classes'
import Owners from '@/store/modules/Owners'
import Products from '@/store/modules/Products'
import Tags from '@/store/modules/Tags'
import { isEmptyDate } from 'shared/helpers/date'
import { FormModel, setForm } from 'shared/helpers/form'
import { getPriceValueFromBackend } from 'shared/helpers/utils'
import { getPriceByType } from '../classProfile'
import { getTagById, isTagGroupSize } from '../tags'

const prepareDate = (date: string) => {
    return isEmptyDate(date) ? '' : date
}

const submitOwner = async ({ id, loading, errors, form }) => {
    try {
        if (loading) return
        loading = true
        const payload = await createOwnerPayload({
            form: form as FormModel
        })
        id
            ? await Owners.editOwner({
                  config: { errors },
                  payload: { ...payload },
                  id
              })
            : await Owners.createOwner({
                  config: { errors },
                  payload
              })
        goToSupportTab('activity', 'owners')
    } finally {
        loading = false
    }
}
const submitProduct = async ({
    id,
    loading,
    errors,
    form
}: SupportButtonFnConfig) => {
    try {
        if (loading) return
        loading = true
        const payload = await createProductPayload({
            form: form as FormModel,
            id
        })
        id
            ? await Products.editProduct({
                  config: { errors },
                  payload: { ...payload, id }
              })
            : await Products.createProduct({
                  config: { errors },
                  payload
              })
        goToSupportTab('activity', 'products')
    } finally {
        loading = false
    }
}
const submitClass = async ({
    id,
    loading,
    errors,
    form
}: SupportButtonFnConfig) => {
    try {
        if (loading) return
        loading = true
        const payload = await createClassPayload({
            form: form as FormModel,
            id
        })
        id
            ? await Classes.editClass({
                  config: { errors },
                  payload: { ...payload, id }
              })
            : await Classes.createClass({
                  config: { errors },
                  payload
              })
        goToSupportTab('activity', 'products')
    } finally {
        loading = false
    }
}
const submitTag = async ({
    id,
    loading,
    errors,
    form
}: SupportButtonFnConfig) => {
    try {
        if (loading) return
        loading = true
        const payload = await createTagPayload({ form: form as FormModel })
        id
            ? await Tags.editTag({
                  config: { errors },
                  payload: { ...payload, id }
              })
            : await Tags.createTag({
                  config: { errors },
                  payload
              })
        goToSupportTab('tags', 'tags')
    } finally {
        loading = false
    }
}
const submitArticle = async ({
    id,
    loading,
    errors,
    form,
    customFields
}: SupportButtonFnConfig) => {
    try {
        if (loading) return
        loading = true
        const payload = await createArticlePayload({ form: form as FormModel })
        id
            ? await Blogs.editBlog({
                  config: { errors },
                  payload: { ...payload, id, ...customFields }
              })
            : await Blogs.createBlog({
                  config: { errors },
                  payload: { ...payload, ...customFields }
              })
        goToSupportTab('articles', 'articles')
    } finally {
        loading = false
    }
}

export const support_creators = (): SupportCreators => ({
    products: {
        title: { key: 'title', default: 'Create Product' },
        form: product_form(),
        buttons: [
            {
                key: 'submit',
                display: 'Submit',
                fn: async ({ id, loading, errors, form }) =>
                    submitProduct({ id, loading, errors, form })
            }
        ],
        prepare: async () => {
            if (Owners.listOwners.length == 0) await Owners.getOwners()
        },
        loadFn: async ({ id, loading, form }) => {
            try {
                if (!id) {
                    return
                }
                loading = true
                const product = await Products.getProductById({
                    payload: { id }
                })
                const {
                    localization_coordinates,
                    faq,
                    price_bundle,
                    price_single_day
                } = product
                const dates = product.days.map(({ date }) => date)
                const attachments = await storage.getFiles(product.attachments)
                const slots_per_day = product.days[0]?.free_slots || 0
                setForm(
                    {
                        ...product,
                        localization_coordinates_long:
                            localization_coordinates.long,
                        localization_coordinates_lat:
                            localization_coordinates.lat,
                        date_camp_from: dates[0],
                        date_camp_to: dates[dates.length - 1],
                        attachments,
                        slots_per_day,
                        faq: faq.length > 0 ? faq : faqPresetItemsDayCamp,
                        price_bundle: getPriceValueFromBackend(price_bundle),
                        price_single_day:
                            getPriceValueFromBackend(price_single_day)
                    },
                    form
                )
                return product
            } finally {
                loading = false
            }
        },
        goBackFn: () => goToSupportTab('activity', 'products')
    },
    classes: {
        title: { key: 'title', default: 'Create Class' },
        form: class_form(),
        buttons: [
            {
                key: 'submit',
                display: 'Submit',
                fn: async ({ id, loading, errors, form }) =>
                    submitClass({ id, loading, errors, form })
            }
        ],
        prepare: async () => {
            if (Owners.listOwners.length == 0) await Owners.getOwners()
        },
        loadFn: async ({ id, loading, form }) => {
            try {
                if (!id) {
                    return
                }
                loading = true
                const product = await Classes.getClassById({
                    payload: { id }
                })
                const attachments = await storage.getFiles(product.attachments)
                const trialPrice = getPriceByType(product, ClassPriceType.Trial)
                const semesterPrice = getPriceByType(
                    product,
                    ClassPriceType.Semester
                )
                const advancePrice = getPriceByType(
                    product,
                    ClassPriceType.Advance
                )

                setForm(
                    {
                        ...product,
                        schedule: {
                            ...product.schedule,
                            start_date: prepareDate(
                                product.schedule.start_date
                            ),
                            end_date: prepareDate(product.schedule.end_date)
                        },
                        recrutation_start_date: prepareDate(
                            product.recrutation_start_date
                        ),
                        recrutation_end_date: prepareDate(
                            product.recrutation_end_date
                        ),
                        group_size: product.other_tags.find((tagId: TagID) =>
                            isTagGroupSize(getTagById(tagId))
                        ),
                        attachments,
                        trial_price: getPriceValueFromBackend(trialPrice.value),
                        trial_price_description: trialPrice.data.description,
                        trial_price_caption: trialPrice.data.caption,
                        semester_price: getPriceValueFromBackend(
                            semesterPrice.value
                        ),
                        semester_price_description:
                            semesterPrice.data.description,
                        advance_price: getPriceValueFromBackend(
                            advancePrice.value
                        ),
                        advance_price_description: advancePrice.data.description
                    },
                    form
                )
                return product
            } finally {
                loading = false
            }
        },
        goBackFn: () => goToSupportTab('activity', 'products')
    },
    owners: {
        title: { key: 'company_name', default: 'Create Owner' },
        form: owner_form(),
        buttons: [
            {
                key: 'submit',
                display: 'Submit',
                fn: async ({ id, loading, errors, form }) =>
                    submitOwner({ id, loading, errors, form })
            }
        ],
        loadFn: async ({ id, loading, form }) => {
            try {
                if (!id) {
                    return
                }
                loading = true
                const payload = await Owners.getOwnerById({ payload: { id } })
                setForm(payload, form)
            } finally {
                loading = false
            }
        },
        goBackFn: () => goToSupportTab('activity', 'owners')
    },
    tags: {
        title: { key: 'title', default: 'Create tag' },
        form: categoriesAddTagForm(),
        buttons: [
            {
                key: 'submit',
                display: 'Submit',
                fn: async ({ id, loading, errors, form }) =>
                    submitTag({ id, loading, errors, form })
            }
        ],
        loadFn: async ({ id, loading, form }) => {
            try {
                if (!id) {
                    return
                }
                loading = true
                const tag = await Tags.getTagById({ payload: { id } })
                const icon = await storage.getFiles([tag.icon])
                setForm({ ...tag, icon }, form)
            } finally {
                loading = false
            }
        },
        goBackFn: () => goToSupportTab('tags', 'tags')
    },
    articles: {
        title: { key: 'title', default: 'Create article' },
        form: article_form(),
        buttons: [
            {
                key: 'draft',
                display: 'Save as draft',
                fn: async ({ id, loading, errors, form }) =>
                    submitArticle({
                        id,
                        loading,
                        errors,
                        form,
                        customFields: { is_published: false }
                    })
            },
            {
                key: 'submit',
                display: 'Submit',
                fn: async ({ id, loading, errors, form }) =>
                    submitArticle({
                        id,
                        loading,
                        errors,
                        form,
                        customFields: { is_published: true }
                    })
            }
        ],
        loadFn: async ({ id, loading, form }) => {
            try {
                if (!id) {
                    return
                }
                loading = true
                const payload = await Blogs.getBlogById({ payload: { id } })
                setForm(payload, form)
            } finally {
                loading = false
            }
        },
        goBackFn: () => goToSupportTab('articles', 'articles')
    }
})
