<template>
    <div class="sideview-articles">
        <SupportSideviewBoolean
            label="Published"
            :value="props.item.is_published"
        />
        <SupportSideviewText
            label="Description"
            :value="props.item.description"
        />
        <div class="sideview-articles-images">
            <SupportSideviewImage
                label="Background image"
                :value="props.item.background_image"
            />
            <SupportSideviewImage
                label="Thumbnail image"
                :value="props.item.thumbnail_image"
            />
        </div>
        <SupportSideviewText label="Category tags" :value="categoryTags" />
        <SupportSideviewText label="Display tags" :value="displayTags" />
        <SupportSideviewText label="Slug" :value="props.item.slug" />
        <SupportSideviewText label="ID" :value="props.item.id" />
        <SupportSideviewCreatedAt :created_at="props.item.created_at" />
        <hr />
        <div v-html="props.item.body" />
    </div>
</template>

<script setup lang="ts">
import { BlogModel } from '@/models/blog.types'
import { TagID } from '@/models/tag.types'
import { computed } from 'vue'
import SupportSideviewText from '../SupportSideviewText.vue'
import SupportSideviewCreatedAt from '../SupportSideviewCreatedAt.vue'
import SupportSideviewBoolean from '../SupportSideviewBoolean.vue'
import SupportSideviewImage from '../SupportSideviewImage.vue'

interface Props {
    item: BlogModel
}
const props = defineProps<Props>()

const categoryTags = computed(() => {
    return props.item.category_tags.map((tagId: TagID) => tagId).join(', ')
})
const displayTags = computed(() => {
    return props.item.other_tags.map((tagId: TagID) => tagId).join(', ')
})
</script>

<style scoped lang="scss">
.sideview-articles {
    padding: 1rem 2rem;
}
.sideview-articles-images {
    display: flex;
    gap: 1rem;
}
</style>
