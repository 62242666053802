<template>
    <div class="account-kids-profiles">
        <div v-if="state.kids.length > 0" class="kids-profiles">
            <div v-if="!isMobile" class="kids-title-container">
                <div class="kids-title" v-text="t('KIDS_PROFILES')" />
                <div
                    v-if="currentParentRoleID"
                    class="kids-add-button"
                    @click="addKid"
                >
                    <Icon icon="add" size="5" />
                    <div v-text="t('ADD_KID_PROFILE')" />
                </div>
            </div>
            <Icon
                v-else
                class="kids-add-button"
                icon="add"
                size="3"
                @click="addKid"
            />
            <AccountKidsProfilesTabs
                :kids="state.kids"
                :selected="state.selectedKid"
                @changeTab="changeSelectedKid"
            />
            <div v-if="state.selectedKid" class="kids-info">
                <div v-text="kidFullName" />
                <div v-text="kidDateOfBirth" />
            </div>
            <button
                class="kids-search-button"
                v-text="t('SEARCH_ACTIVITIES')"
                @click="goToListing(ProductType.Class)"
            />
            <div class="kids-actions">
                <div class="kids-action-button">
                    <CustomIcon icon="bin" />
                    <div
                        v-text="t('REMOVE_PROFILE')"
                        @click="removeProfileModal.show"
                    />
                </div>
                <div class="kids-action-button">
                    <CustomIcon icon="edit" />
                    <div v-text="t('EDIT')" @click="editKid" />
                </div>
            </div>
        </div>
        <div v-else class="kids-empty">
            <div
                class="kids-empty-text"
                v-text="t('YOU_DO_NOT_HAVE_ANY_KID_PROFILE')"
            />
            <div
                class="kids-empty-button"
                v-text="t('ADD_PROFILE')"
                @click="addKid"
            />
        </div>
    </div>
    <Modal
        v-if="!isMobile"
        ref="addProfileModal"
        class="add-profile"
        :title="t(addProfileModalTitle)"
        :buttons="addProfileModalButtons"
        :withAlertOnClose="state.unsavedChanges && state.editing"
        @exitWithoutSaving="() => preventModal.show()"
    >
        <FormCreator
            class="form"
            :fields="state.fields"
            :errors="state.errors"
            @update="() => (state.unsavedChanges = true)"
        />
    </Modal>
    <MobileModal v-else ref="addProfileModal" :title="t(addProfileModalTitle)">
        <FormCreator
            class="form"
            :fields="state.fields"
            :errors="state.errors"
            @update="() => (state.unsavedChanges = true)"
        />
        <div class="mobile-modal-buttons">
            <button
                v-for="button in addProfileModalButtons"
                :key="button.key"
                :class="button.class"
                v-text="button.display"
                @click="button.action"
            />
        </div>
    </MobileModal>
    <AccountPreventLeaveModal
        ref="preventModal"
        @discard="discardChanges"
        @save="saveChanges"
    />
    <Modal ref="removeProfileModal" class="remove-profile" :hideHeader="true">
        <div
            class="remove-text"
            v-text="t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_KID_PROFILE')"
        />
        <div class="remove-buttons">
            <button
                class="remove-cancel"
                v-text="t('CANCEL')"
                @click="closeRemoveModal"
            />
            <button
                class="remove-yes"
                v-text="t('YES_REMOVE')"
                @click="removeKidProfile"
            />
        </div>
    </Modal>
</template>

<script setup lang="ts">
import { computed, reactive, onMounted, ref } from 'vue'
import Modal from '@/components/utils/Modal.vue'
import FormCreator from '@/components/form/FormCreator.vue'
import { client_kid_profile } from '@/helpers/formContent'
import { t } from '@/i18n'
import {
    dateWithFormat,
    createDateFromScraps,
    months
} from 'shared/helpers/date'
import { getFullName } from 'shared/helpers/utils'
import Errors from 'shared/helpers/errors'
import { Button } from 'shared/models/generic.types'
import toast from '@/helpers/toast'
import Icon from 'shared/components/utils/Icon.vue'
import CustomIcon from '@/components/icons/CustomIcon.vue'
import { goToListing } from '@/helpers/dayCampListing'
import { ProductType } from '@/models/product.types'
import AccountKidsProfilesTabs from './components/AccountKidsProfilesTabs.vue'
import { isMobile } from '@/helpers/utils'
import MobileModal from '@/components/utils/MobileModal.vue'
import Kids from '@/store/modules/Kids'
import { createPayload, setForm } from 'shared/helpers/form'
import Parents from '@/store/modules/Parents'
import AccountPreventLeaveModal from './components/AccountPreventLeaveModal.vue'
import ClientAccounts from '@/store/modules/ClientAccounts'
import { KidModel } from '@/models/kids.types'

const addProfileModal = ref()
const removeProfileModal = ref()
const preventModal = ref<InstanceType<typeof AccountPreventLeaveModal> | null>(
    null
)
const addProfileModalButtons: Button[] = [
    {
        key: 'cancel',
        display: t('CANCEL'),
        class: 'tertiary-button',
        action: () => {
            discardChanges()
        }
    },
    {
        key: 'save',
        display: t('SAVE'),
        class: 'primary-button',
        action: () => {
            addKidProfile()
        }
    }
]

const currentParentRoleID = computed(() => {
    return Parents.currentParent.id
})

const state = reactive({
    kids: [],
    selectedKid: null,
    fields: client_kid_profile(),
    errors: new Errors(),
    loading: false,
    editing: false,
    unsavedChanges: false
})

const kidFullName = computed(() => {
    const fullName = getFullName(
        state.selectedKid.name,
        state.selectedKid.surname
    )
    return `${t('NAME_AND_SURNAME')}: ${fullName}`
})
const kidDateOfBirth = computed(() => {
    const date = dateWithFormat({
        date: state.selectedKid.birthdate,
        format: 'D.MM.YYYY'
    })
    return `${t('BIRTH_DATE')}: ${date}`
})
const changeSelectedKid = (kid: KidModel) => {
    state.selectedKid = kid
}
const closeRemoveModal = () => {
    removeProfileModal.value.close()
}
const addProfileModalTitle = computed(() =>
    state.editing ? 'EDIT_KID_PROFILE' : 'ADD_KID_PROFILE'
)
const saveChanges = () => {
    state.unsavedChanges = false
    preventModal.value.close()
    addKidProfile()
}
const discardChanges = () => {
    preventModal.value.close()
    state.unsavedChanges = false
    addProfileModal.value.close()
}
const addKid = () => {
    state.editing = false
    state.fields = client_kid_profile()
    addProfileModal.value.show()
}
const editKid = () => {
    state.editing = true
    const birthdate = new Date(state.selectedKid.birthdate)
    setForm(
        {
            day: birthdate.getDate(),
            month: months[birthdate.getMonth()],
            year: birthdate.getFullYear(),
            ...state.selectedKid
        },
        state.fields
    )
    addProfileModal.value.show()
}
const addKidProfile = async () => {
    try {
        if (state.loading) return
        const { name, surname, birthdate } = createPayload({
            form: state.fields,
            errors: state.errors,
            checkRequired: true
        })
        if (state.errors.errors.length > 0) {
            return
        }
        const payload = {
            parent_id: currentParentRoleID.value,
            name,
            surname,
            birthdate
        }
        state.loading = true
        state.editing
            ? await Kids.editKid({ payload, id: state.selectedKid.id })
            : await Kids.createKid({ payload })
        toast.success('CHANGES_HAVE_BEEN_SAVED')
        state.selectedKid = state.kids?.[0]
        addProfileModal.value.close()
    } catch (error) {
        toast.error('SOMETHING_WENT_WRONG')
    } finally {
        state.loading = false
    }
}
const removeKidProfile = async () => {
    try {
        if (state.loading) return
        state.loading = true
        await Kids.deleteKid({ payload: { id: state.selectedKid.id } })
        toast.success('CHANGES_HAVE_BEEN_SAVED')
        state.selectedKid = state.kids?.[0]
        removeProfileModal.value.close()
    } catch (error) {
        toast.error('SOMETHING_WENT_WRONG')
    } finally {
        state.loading = false
    }
}
const setKids = async () => {
    try {
        if (state.loading) return
        state.loading = true
        const parent = await Parents.getParentByAccountId({
            payload: { id: ClientAccounts.clientAccount.id }
        })
        state.kids =
            (await Parents.getParentKids({
                payload: { id: parent.id }
            })) || []
        if (state.kids.length > 0) state.selectedKid = state.kids[0]
    } catch (error) {
        toast.error('SOMETHING_WENT_WRONG')
    } finally {
        state.loading = false
    }
}
onMounted(async () => {
    await setKids()
})
</script>

<style scoped lang="scss">
.account-kids-profiles {
    width: 750px;
}

.kids-title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.kids-title {
    @extend .is-subtitle-bold-web;
}
.kids-add-button {
    @extend .is-body-2-bold-web;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
}

.kids-info {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.kids-search-button {
    @extend .primary-button;
    @extend .is-button-text-2-web;
    text-transform: uppercase;
    padding: 0.5rem 2rem;
    margin-bottom: 2rem;
}
.kids-actions {
    display: flex;
    gap: 2rem;
}
.kids-action-button {
    @extend .is-body-2-bold-web;
    display: flex;
    gap: 0.3rem;
    cursor: pointer;
}

.kids-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-top: 5rem;
}
.kids-empty-text {
    @extend .is-subtitle-bold-web;
}
.kids-empty-button {
    @extend .primary-button;
    @extend .is-button-text-1-web;
    text-transform: uppercase;
    padding: 0.6rem 4rem;
}

.add-profile {
    @include client-modal-buttons;
}

.form {
    @include client-select;
}

.remove-profile {
    :deep(.modal-card) {
        width: 450px;
    }
}
.remove-text {
    @extend .is-h6-web;
    text-align: center;
    margin-bottom: 2rem;
}
.remove-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.remove-cancel {
    @extend .tertiary-button;
    @extend .is-button-text-2-web;
    text-transform: uppercase;
}
.remove-yes {
    @extend .primary-button;
    @extend .is-button-text-2-web;
    text-transform: uppercase;
}
.form {
    :deep(.dp__pointer) {
        height: 50px;
        border-radius: 100px;
    }
}
.mobile-modal-buttons {
    display: flex;
    justify-content: center;
    padding: 1rem 0;

    button {
        @extend .is-button-text-1-web;
        text-transform: uppercase;
    }
}

@include touch {
    .account-kids-profiles {
        width: 100%;
    }
    .kids-add-button {
        position: absolute;
        top: 0;
        right: 0.5rem;
    }
    .kids-empty {
        padding-top: 10rem;
    }
    .kids-empty-text {
        text-align: center;
        width: 80vw;
    }

    .remove-profile {
        :deep(.modal-card) {
            width: 90vw;
        }
    }
    .remove-text {
        @include is-h3-mobile;
    }
    .remove-cancel {
        padding: 0.5rem 2rem;
    }
    .remove-yes {
        padding: 0.5rem 2rem;
    }
}
</style>
