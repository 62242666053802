import {
    goToSupportArticleCreator,
    goToSupportClassCreator,
    goToSupportOwnerCreator,
    goToSupportProductCreator,
    goToSupportTagCreator
} from '@/helpers/support'
import { SupportTabs } from '@/models/support.types'
import Blogs from '@/store/modules/Blogs'
import Owners from '@/store/modules/Owners'
import Tags from '@/store/modules/Tags'
import Products from '@/store/modules/Products'
import { ProductType } from '@/models/product.types'

export const supportSideviewButtons = {
    [SupportTabs.Owners]: [
        {
            key: 'remove_owner',
            display: 'Remove',
            class: 'sideview-secondary-button',
            fn: ({ id }) => {
                Owners.deleteOwner({ payload: { id } })
            }
        },
        {
            key: 'edit_owner',
            display: 'Edit',
            class: 'sideview-primary-button',
            fn: async ({ id }) => goToSupportOwnerCreator(id)
        }
    ],
    [SupportTabs.Tags]: [
        {
            key: 'remove_tag',
            display: 'Remove',
            class: 'sideview-secondary-button',
            fn: async ({ id }) => {
                Tags.deleteTag({ payload: { id } })
            }
        },
        {
            key: 'edit_tag',
            display: 'Edit',
            class: 'sideview-primary-button',
            fn: async ({ id }) => {
                goToSupportTagCreator(id)
            }
        }
    ],
    [SupportTabs.Articles]: [
        {
            key: 'remove_article',
            display: 'Remove',
            class: 'sideview-secondary-button',
            fn: async ({ id }) => {
                await Blogs.deleteBlog({ payload: { id } })
            }
        },
        {
            key: 'edit_article',
            display: 'Edit',
            class: 'sideview-primary-button',
            fn: async ({ id }) => {
                goToSupportArticleCreator(id)
            }
        }
    ],
    [SupportTabs.Products]: [
        {
            key: 'remove_product',
            display: 'Remove',
            class: 'sideview-secondary-button',
            fn: async ({ id }) => {
                await Products.deleteProduct({ payload: { id } })
            }
        },
        {
            key: 'duplicate_product',
            display: 'Duplicate',
            class: 'sideview-secondary-button'
        },
        {
            key: 'edit_product',
            display: 'Edit',
            class: 'sideview-primary-button',
            fn: async ({ id }) => {
                const type = Products.listProducts.find(
                    product => product.id == id
                ).type

                type == ProductType.Class
                    ? goToSupportClassCreator(id)
                    : goToSupportProductCreator(id)
            }
        }
    ]
}
