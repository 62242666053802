import Owners from '@/store/modules/Owners'
import Products from '@/store/modules/Products'
import Reservations from '@/store/modules/Reservations'
import Blogs from '@/store/modules/Blogs'
import Tags from '@/store/modules/Tags'
import { getPayloadDate } from 'shared/helpers/time'
import { getTagDisplaysPayload } from '@/helpers/tags'
import { tag_scope, tag_type } from '@/models/tag.types'
import { getFullName, getPriceValueFromBackend } from 'shared/helpers/utils'
import { computed } from 'vue'
import { getProductDateRange } from '../product'
import { ProductModel, ProductType } from '@/models/product.types'
import Classes from '@/store/modules/Classes'
import Parents from '@/store/modules/Parents'
import { ParentModel } from '@/models/parent.types'

const bigCities = [
    'Wrocław',
    'Wałbrzych',
    'Legnica',
    'Lubin',
    'Głogów',
    'Bydgoszcz',
    'Toruń',
    'Włocławek',
    'Inowrocław',
    'Grudziąc',
    'Lublin',
    'Zamość',
    'Biała Podlaska',
    'Puławy',
    'Chełm',
    'Zielona Góra',
    'Gorzów Wielkopolski',
    'Żary',
    'Nowa Sól',
    'Łódź',
    'Zgierz',
    'Pabianice',
    'Piotrków Trybunalski',
    'Bełchatów',
    'Kraków',
    'Wieliczka',
    'Tarnów',
    'Nowy Sącz',
    'Wadowice',
    'Warszawa',
    'Piaseczno',
    'Pruszków',
    'Ożarów Mazowiecki',
    'Wołomin',
    'Opole',
    'Nysa',
    'Brzeg',
    'Kędzierzyn-Koźle',
    'Krapkowice',
    'Rzeszów',
    'Krosno',
    'Dębica',
    'Mielec',
    'Łańcut',
    'Białystok',
    'Łomża',
    'Suwałki',
    'Augustów',
    'Grajewo',
    'Gdańsk',
    'Gdynia',
    'Wejherowo',
    'Pruszcz Gdański',
    'Kartuzy',
    'Katowice',
    'Bielsko-Biała',
    'Częstochowa',
    'Gliwice',
    'Rybnik',
    'Kielce',
    'Ostrowiec Świętokrzyski',
    'Jędrzejów',
    'Bogoria',
    'Chęciny',
    'Olsztyn',
    'Elbląg',
    'Ostróda',
    'Ełk',
    'Iława',
    'Poznań',
    'Konin',
    'Kalisz',
    'Ostrów Wielkopolski',
    'Gniezno',
    'Szczecin',
    'Koszalin',
    'Stargard Szczeciński',
    'Kołobrzeg',
    'Police'
]
const cityFilter = {
    key: 'address_city',
    placeholder: 'City',
    value: [],
    scope: 'multi',
    search_options: true,
    options: bigCities.map(city => {
        return { key: city, value: city, display: city }
    })
}

export const usersTable = {
    config: {
        columns: [
            { key: 'id', header: 'Parent ID' },
            { key: 'account.id', header: 'Account ID' },
            { key: 'account.email', header: 'E-mail' },
            { key: 'fullName', header: 'Name and surname' },
            { key: 'account.phone', header: 'Phone number' },
            { key: 'created_at_display', header: 'Registration date' }
        ]
    },
    loadData: async () => {
        return Parents.getParents()
    },
    data: computed(() => {
        return Parents.listParents
            .map((parent: ParentModel) => getPayloadDate(parent))
            .map((parent: ParentModel) => ({
                ...parent,
                fullName: getFullName(
                    parent.account.name,
                    parent.account.surname
                )
            }))
    })
}

export const ownersTable = {
    filters: [cityFilter],
    config: {
        columns: [
            { key: 'company_name', header: 'Company name' },
            { key: 'address_city', header: 'City' },
            { key: 'email', header: 'E-mail' },
            { key: 'phone', header: 'Phone number' },
            { key: 'business_owner', header: 'Business owner' },
            { key: 'products', header: 'Activities' },
            { key: 'id', header: 'ID' },
            { key: 'created_at_display', header: 'Created' }
        ]
    },
    loadData: () => {
        return Owners.getOwners()
    },
    data: computed(() => {
        return Owners.listOwners
            .map(owner => getPayloadDate(owner))
            .map(owner => {
                return {
                    ...owner,
                    products: owner.products.toString(),
                    business_owner: `${owner.name} ${owner.surname}`
                }
            })
    })
}

export const productsTable = {
    filters: [
        { ...cityFilter, key: 'city' },
        {
            key: 'category_tags_display',
            placeholder: 'Category',
            value: [],
            scope: 'multi',
            search_options: true,
            options: Tags.listTags
        },
        {
            key: 'recrutation_status',
            placeholder: 'Status',
            value: [],
            scope: 'multi',
            options: [
                { key: 'draft', value: 'draft', display: 'draft' },
                {
                    key: 'recrutation_to_be_started',
                    value: 'recrutation_to_be_started',
                    display: 'recrutation_to_be_started'
                },
                {
                    key: 'recrutation_started',
                    value: 'recrutation_started',
                    display: 'recrutation_started'
                },
                {
                    key: 'recrutation_finished',
                    value: 'recrutation_finished',
                    display: 'recrutation_finished'
                }
            ]
        }
    ],
    config: {
        columns: [
            { key: 'type', header: 'Type' },
            { key: 'title', header: 'Day camp name' },
            { key: 'owner.company_name', header: 'Company name' },
            { key: 'city', header: 'City' },
            { key: 'category_tags_display', header: 'Category' },
            { key: 'dates_display', header: 'Camp date' },
            { key: 'recrutation_status', header: 'Status' },
            { key: 'reservations', header: 'Reservations' }
        ]
    },
    loadData: async () => {
        const daycamps = (await Products.getProducts()).filter(
            (product: ProductModel) => product.type == ProductType.DayCamp
        )
        const classes = await Classes.getClasses()
        return [...daycamps, ...classes]
    },
    data: computed(() => {
        const daycamps = Products.listProducts.filter(
            (product: ProductModel) => product.type == ProductType.DayCamp
        )
        return [...daycamps, ...Classes.listClasses].map(product => {
            return {
                ...product,
                reservations: product.reservations?.toString() || 'error', // TODO: remove when BE is fixed 9.08.22
                category_tags_display: product.category_tags.join(', '),
                dates_display: getProductDateRange(product)
            }
        })
    })
}

export const reservationsTable = {
    filters: [
        {
            key: 'payment_status',
            placeholder: 'Payment status',
            value: [],
            scope: 'multi',
            options: [
                { key: 1, value: 'created', display: 'created' },
                { key: 2, value: 'paid', display: 'paid' },
                { key: 3, value: 'cancelled', display: 'cancelled' }
            ]
        },
        {
            key: 'transfer_status',
            placeholder: 'Transfer status',
            value: [],
            scope: 'multi',
            options: [
                { key: 1, value: 'Completed', display: 'Completed' },
                { key: 2, value: 'Pending', display: 'Pending' }
            ]
        }
    ],
    config: {
        columns: [
            { key: 'id', header: 'ID' },
            { key: 'display_kid_name', header: 'Name and surname' },
            { key: 'product_name', header: 'Day camp name' },
            { key: 'xxxxx', header: 'Tickets' },
            { key: 'price', header: 'Price' },
            { key: 'commission_display', header: 'Commission' },
            { key: 'xxxxx', header: 'Amount to be send' },
            { key: 'payment_status', header: 'Payment status' },
            { key: 'date', header: 'Reservation date' }
            // { key: 'transfer_status', header: 'Transfer status' } // hidden for now (26.05.22)
        ]
    },
    loadData: () => {
        return Reservations.getReservations()
    },
    data: computed(() => {
        return Reservations.listReservations.map(reservation => {
            return {
                ...reservation,
                transfer_status: reservation.is_money_transferred
                    ? 'Completed'
                    : 'Pending',
                price: `${getPriceValueFromBackend(reservation.amount)} zł`,
                product_name: reservation.product.title,
                commission_display: reservation.owner
                    ? `${reservation.owner.commission} %`
                    : '-',
                display_kid_name: `${reservation.kid_name} ${reservation.kid_surname}`
            }
        })
    })
}

export const tagsTable = {
    filters: [
        {
            placeholder: 'User',
            key: 'scope',
            value: [],
            scope: 'multi',
            options: [
                {
                    display: 'Parent',
                    value: tag_scope.blog_parent
                },
                {
                    display: 'Business owner',
                    value: tag_scope.blog_owner
                },
                {
                    display: 'Marketplace',
                    value: tag_scope.marketplace
                },
                {
                    display: 'All',
                    value: tag_scope.all
                }
            ]
        },
        {
            placeholder: 'Type of tag',
            key: 'type',
            value: [],
            scope: 'multi',
            options: [
                {
                    display: 'Category',
                    value: tag_type.product_category
                },
                {
                    display: 'Product type',
                    value: tag_type.product_type
                },
                {
                    display: 'Product group age',
                    value: tag_type.product_group_age
                },
                {
                    display: 'Other',
                    value: tag_type.other
                }
            ]
        }
    ],
    config: {
        columns: [
            { key: 'id', header: 'ID' },
            { key: 'scope', header: 'Type of blog' },
            { key: 'type', header: 'Type' },
            { key: 'slug', header: 'Key (slug)' },
            { key: 'display', header: 'Display' },
            { key: 'display_tags', header: 'Display tags' },
            { key: 'created_at_display', header: 'Created' }
        ]
    },
    loadData: () => {
        return Tags.getTags()
    },
    data: computed(() => {
        return Tags.listTags
            .map(tag => getPayloadDate(tag))
            .map(tag => getTagDisplaysPayload(Tags.listTags, tag))
    })
}

export const articlesTable = {
    config: {
        columns: [
            { key: 'title', header: 'Title' },
            { key: 'author', header: 'Author' },
            {
                key: 'category_tags_display',
                header: 'Category',
                type: 'shrink'
            },
            { key: 'display_tags', header: 'Display tags' },
            { key: 'status', header: 'Status' },
            { key: 'created_at_display', header: 'Created' }
        ]
    },
    loadData: () => {
        return Blogs.getBlogs()
    },
    data: computed(() => {
        return Blogs.listBlogs
            .map(blog => getPayloadDate(blog))
            .map(blog => getTagDisplaysPayload(Tags.listTags, blog))
            .map(blog => {
                return {
                    ...blog,
                    category_tags_display: blog.category_tags.join(', '),
                    display_tags: blog.other_tags.join(', '),
                    status: blog.is_published ? 'Published' : 'Draft'
                }
            })
    })
}
