export enum PipedriveContactType {
    Customer = 'Customer',
    Owner = 'Owner'
}

export enum PipedriveCustomFieldKeys {
    PRODUCT_TITLE = '8c80c3282f82baf9342baf917155ee89cde61c3d',
    PRODUCT_LINK = '7905900c11be2b38f5c759868463d033ae351aa9',
    PRODUCT_ID = '06b399c21851363d99dba9c2206d90c20bb2b6f5',
    CONTACT_TYPE = '5bbcdb78cdeb31b424cf350bbc57c25f7a7fb873'
}
