<template>
    <div class="account-settings">
        <div class="settings-title" v-text="t('MY_DATA')" />
        <FormCreator
            class="settings-form"
            :fields="state.fields"
            :errors="state.errors"
            @update="formUpdate"
        />
        <div class="settings-password">
            <div class="settings-password-display" v-text="passwordDisplay" />
            <div
                class="settings-password-change"
                v-text="t('CHANGE_PASSWORD')"
                @click="passwordModal.show"
            />
        </div>
        <button
            class="settings-button"
            v-text="t('SAVE_CHANGES')"
            @click="saveChanges"
        />
        <hr class="settings-divider" />
        <div class="settings-remove-button" @click="removeModal.show">
            <CustomIcon icon="bin" />
            <div v-text="t('REMOVE_MY_ACCOUNT_IN_APP', { appTitle })" />
        </div>
        <Modal
            v-if="!isMobile"
            ref="passwordModal"
            class="password-modal"
            :title="t('CHANGE_PASSWORD')"
        >
            <AccountSettingsChangePassword
                @passwordChanged="closePasswordModal"
            />
        </Modal>
        <MobileModal
            v-else
            ref="passwordModal"
            class="password-modal"
            :title="t('CHANGE_PASSWORD')"
        >
            <AccountSettingsChangePassword
                @passwordChanged="closePasswordModal"
            />
        </MobileModal>
        <Modal
            v-if="!isMobile"
            ref="removeModal"
            class="remove-modal"
            :title="t('REMOVE_ACCOUNT')"
        >
            <AccountSettingsRemoveAccount />
        </Modal>
        <MobileModal
            v-else
            ref="removeModal"
            class="remove-modal"
            :title="t('REMOVE_ACCOUNT')"
        >
            <AccountSettingsRemoveAccount />
        </MobileModal>

        <AccountPreventLeaveModal
            ref="preventModal"
            @discard="discardChanges"
            @save="saveChanges"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import {
    client_name,
    client_surname,
    client_phone,
    client_avatar,
    email
} from '@/helpers/formContent'
import CustomIcon from '@/components/icons/CustomIcon.vue'
import Errors from 'shared/helpers/errors'
import { t } from '@/i18n'
import { appTitle } from '@/helpers/app'
import FormCreator from '@/components/form/FormCreator.vue'
import Modal from '@/components/utils/Modal.vue'
import toast from '@/helpers/toast'
import { onBeforeRouteLeave } from 'vue-router'
import router from '@/router'
import AccountSettingsChangePassword from './components/AccountSettingsChangePassword.vue'
import AccountSettingsRemoveAccount from './components/AccountSettingsRemoveAccount.vue'
import MobileModal from '@/components/utils/MobileModal.vue'
import { isMobile } from '@/helpers/utils'
import AccountPreventLeaveModal from './components/AccountPreventLeaveModal.vue'
import { createPayload, setForm } from 'shared/helpers/form'
import ClientAccounts from '@/store/modules/ClientAccounts'
import storage from '@/firebase/storage'

const passwordModal = ref()
const removeModal = ref()
const preventModal = ref()

const fieldDefault = {
    overrideClass: 'TextInputRoundedSimple',
    size: 3
}

const state = reactive({
    fields: {
        avatar: client_avatar(), // TODO: improve avatar input
        email: email({ ...fieldDefault, disabled: true }),
        name: client_name(fieldDefault),
        surname: client_surname(fieldDefault),
        phone: client_phone(fieldDefault)
    },
    errors: new Errors(),
    unsavedChanges: false,
    loading: false,
    nextRoute: null
})

const passwordDisplay = computed(() => {
    return `${t('PASSWORD')}: *********`
})

const formUpdate = () => {
    state.unsavedChanges = true
}

const closePasswordModal = () => {
    passwordModal.value.close()
}
const saveChanges = async () => {
    try {
        if (state.loading) return
        const payload = createPayload({ form: state.fields })

        const files = await storage.uploadLocalFiles(payload.avatar)
        state.loading = true
        await ClientAccounts.editAccountData({
            payload: {
                ...payload,
                avatar: files[0] || null
            }
        })
        toast.success('CHANGES_HAVE_BEEN_SAVED')
        state.unsavedChanges = false
        preventModal.value.close()
    } finally {
        state.loading = false
    }
}
const discardChanges = () => {
    state.unsavedChanges = false
    router.push(state.nextRoute)
}
const setState = () => {
    const payload = ClientAccounts.currentClientAccount
    const avatar = payload.avatar ? [payload.avatar] : []
    setForm({ ...payload, avatar }, state.fields)
}
onBeforeRouteLeave((to, _from, next) => {
    if (!state.unsavedChanges) next()
    else {
        next(false)
        state.nextRoute = to
        preventModal.value.show()
    }
})
onMounted(() => setState())
</script>

<style scoped lang="scss">
.account-settings {
    width: 750px;
}
.settings-title {
    @extend .is-h6-web;
    margin-bottom: 2rem;
}
.settings-form {
    :deep(.field) {
        margin-bottom: 2rem;
    }
    :deep(.grid) {
        grid-template-columns: none;
    }
    :deep(.image-attachment) {
        display: block;
        width: 250px;
        height: 250px;
    }
    :deep(.add-button) {
        width: 250px;
        height: 250px;
        border: 2px dashed $gray-60;
        color: $gray-80;

        &:hover {
            background-color: transparent;
            color: $gray-80;
        }
    }
}
.settings-password {
    @extend .is-body-1-web;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
}
.settings-password-change {
    border-bottom: 1px solid $navy-blue-40;
    cursor: pointer;
}
.settings-button {
    @extend .primary-button;
    @extend .is-button-text-1-web;
    padding: 0.6rem 4rem;
    text-transform: uppercase;
}
.settings-divider {
    @extend .account-divider;
}
.settings-remove-button {
    @extend .is-body-2-bold-web;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.remove-modal,
.password-modal {
    :deep(.modal-card) {
        width: 460px;
    }
}

@include touch {
    .account-settings {
        width: 100%;
    }
    .settings-form {
        :deep(.field) {
            margin-bottom: 1rem;
        }
        :deep(.image-attachment) {
            width: 160px;
            height: 160px;
        }
        :deep(.add-button) {
            @include is-body-3-mobile;
            padding: 1rem;
            width: 160px;
            height: 160px;
        }
    }
    .settings-password {
        @include is-body-2-mobile;
    }
    .settings-button {
        width: 100%;
    }
    .settings-remove-button {
        margin-bottom: 2rem;
    }

    .remove-modal,
    .password-modal {
        :deep(.mobile-modal-body) {
            height: 80vh;
        }
    }
}
</style>
