import VuexPersist from 'vuex-persist'
import Vuex from 'vuex'
import { config } from 'vuex-module-decorators'
config.rawError = true
const env = ''
const key = `fc-web-${env}`
const vuexPersist = new VuexPersist({
    key,
    storage: window.localStorage,
    modules: ['account', 'clientAccounts']
})
export const shouldPreserve = (module?: string, keys?: string[]) => {
    const storage: string | null = localStorage.getItem(key)
    if (!storage) return false
    const json: { [key: string]: any } = JSON.parse(storage)

    if (module && keys) {
        return keys.every(key => {
            return json[module] && Object.keys(json[module]).includes(key)
        })
    }
}
export const getStateKeys = (state: { [key: string]: any }) => {
    return state ? Object.keys(state) : []
}
export default new Vuex.Store({ plugins: [vuexPersist.plugin] })
