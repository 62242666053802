import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators'
import store, { shouldPreserve } from '@/store'
import { RequestModel } from '@/models/api.types'
import api from '@/api'
import { addEntity, removeEntity, updateEntity } from '@/helpers/store'
import {
    ClientAccountModel,
    ClientAccountRequestModel
} from '@/models/clientAccount.types'
import router from '@/router'
import Accounts from './Accounts'
const base_url = 'v1/account'
const keys = ['clientAccount']
const name = 'clientAccounts'
@Module({
    name,
    dynamic: true,
    store,
    preserveState: shouldPreserve(name, keys)
})
class ClientAccounts extends VuexModule {
    email = ''
    clientAccount: ClientAccountModel = null

    @Mutation
    public SET_CURRENT_EMAIL(email: string) {
        this.email = email
    }
    @Mutation
    public SET_CLIENT_ACCOUNT(account: ClientAccountModel) {
        this.clientAccount = account
    }
    @Mutation
    public RESET_CLIENT_ACCOUNT_STATE() {
        this.clientAccount = null
    }
    @Mutation
    public REFRESH_CLIENT_JWT_TOKEN(jwt: string) {
        this.clientAccount.jwt = jwt
    }

    @Action
    public async refreshJWT({ config }: RequestModel = {}): Promise<string> {
        const jwt = await api.post(`${base_url}/refresh-jwt`, config)
        this.REFRESH_CLIENT_JWT_TOKEN(jwt)
        return jwt
    }
    @Action
    public async checkIfEmailExists({
        config,
        payload
    }: RequestModel<{ email: string }> = {}): Promise<{
        in_marketplace: boolean
        in_common_accounts: boolean
    }> {
        this.SET_CURRENT_EMAIL(payload.email)
        return api.post(`${base_url}/exists`, payload, config)
    }
    @Action
    public async connectExistingAccount({
        config,
        payload
    }: RequestModel<{ email: string }> = {}): Promise<ClientAccountModel> {
        return api.post(`${base_url}/connect-existing-account`, payload, config)
    }

    @Action
    public async loginClient({
        config,
        payload
    }: RequestModel<{
        email: string
        password: string
    }> = {}): Promise<ClientAccountModel> {
        const account = await api.post(
            `${base_url}/login/email`,
            payload,
            config
        )
        this.SET_CLIENT_ACCOUNT(account)
        this.refreshJWT()
        Accounts.logout()
        return account
    }

    @Action
    public async signIn({
        config,
        payload
    }: RequestModel<ClientAccountRequestModel> & {
        password: string
    }): Promise<ClientAccountModel> {
        const account = await api.post(
            `${base_url}/create-new-account`,
            payload,
            config
        )
        this.SET_CLIENT_ACCOUNT(account)
        return account
    }

    @Action
    public async editAccountData({
        config,
        payload
    }: RequestModel<ClientAccountRequestModel> = {}): Promise<ClientAccountModel> {
        const account = await api.patch(`${base_url}/current`, payload, config)
        this.SET_CLIENT_ACCOUNT(account)
        return account
    }

    @Action
    public async changeCurrentPassword({
        config,
        payload
    }: RequestModel<{
        password_new: string
        password_old: string
    }> = {}): Promise<{
        ack: boolean
    }> {
        return api.post(`${base_url}/current/change-password`, payload, config)
    }

    @Action
    public async resetPasswordEmail({
        config,
        payload
    }: RequestModel<{ email: string }> = {}): Promise<{
        ack: boolean
    }> {
        return api.post(`${base_url}/reset-password/email`, payload, config)
    }

    @Action
    public async resetPasswordConfirmEmail({
        config,
        payload
    }: RequestModel<{ password: string; token: string }> = {}): Promise<{
        ack: boolean
    }> {
        return api.post(
            `${base_url}/reset-password/confirm/email`,
            payload,
            config
        )
    }
    @Action
    // Truly we do not delete account but this explanation is more "user-friendly"
    public async deleteAccount({
        config,
        payload
    }: RequestModel<{ password: string }> = {}): Promise<{
        ack: boolean
    }> {
        return api.post(`${base_url}/current/delete`, payload, config)
    }
    @Action
    async logOut() {
        this.RESET_CLIENT_ACCOUNT_STATE()
        router.push({ name: 'parent_landing' })
    }

    get currentClientEmail() {
        return this.email
    }
    get currentClientAccount() {
        return this.clientAccount
    }
    get isLoggedIn() {
        return Boolean(this.clientAccount)
    }
}

export default getModule(ClientAccounts)
