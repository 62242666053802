<template>
   <div class="placeholder-body" style="min-width: 250px">
      <div
         :class="{ 'placeholder-small': type == 'small' }"
         class="flex-row has-background-bg-grey px-4 py-2 has-radius"
         style="max-width: 600px"
      >
         <Image
            class="media-left"
            style="
               width: 128px;
               height: 110px;
               border-radius: 8px;
               overflow: hidden;
            "
            r_src="not_found.svg"
         />
         <div class="has-text-text mr-5">
            <h2 v-if="title" class="title is-size-5 pb-1" v-text="t(title)" />
            <p
               v-if="subtitle"
               class="subtitle is-size-6 mb-2"
               v-text="t(subtitle)"
            />
            <div v-if="action">
               <TextButton
                  color="primary"
                  :display="action.display"
                  @click="action.event.fn"
               />
            </div>
         </div>
      </div>
   </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Image from 'shared/components/utils/Image.vue'
import { t } from '@/i18n'
import { ActionModel } from '@/helpers/actions'
import TextButton from './TextButton.vue'
export default defineComponent({
   props: {
      type: {
         type: String,
         default: ''
      },
      title: {
         type: String,
         default: 'NO_ELEMENTS'
      },
      subtitle: {
         type: String,
         default: 'ADD_ELEMENTS_TO_DISPLAY'
      },
      action: {
         type: Object as PropType<ActionModel>
      }
   },
   components: { Image, TextButton },
   setup() {
      return { t }
   }
})
</script>
<style lang="scss" scoped>
.placeholder-body {
   width: 100%;
   height: 10%;
   display: flex;
   align-items: center;
   justify-content: center;
}
.placeholder-small {
   display: flex;
   flex-direction: column;
   text-align: center;

   div {
      margin: 0 !important;
   }
}
</style>
