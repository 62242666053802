<template>
    <div class="sideview-reservations">
        <SupportSideviewLabel label="Booking details" />
        <SupportSideviewText label="Type" :value="props.item.product.type" />
        <SupportSideviewText label="Name" :value="props.item.product.title" />
        <SupportSideviewText
            label="Name and surname booking person"
            :value="getFullName(state.parent?.name, state.parent?.surname)"
        />
        <SupportSideviewText
            label="E-mail booking person"
            :value="state.parent?.email"
        />
        <SupportSideviewText
            label="Phone number booking person"
            :value="state.parent?.phone"
        />
        <SupportSideviewText label="Ticket" :value="getTicketDisplay" />
        <SupportSideviewText
            label="Price"
            :value="getPriceDisplay(props.item.amount)"
        />
        <SupportSideviewText label="Product date" :value="props.item.date" />
        <SupportSideviewText
            label="Status"
            :value="props.item.payment_status"
        />
        <hr />
        <SupportSideviewLabel label="Company" />
        <SupportSideviewText
            label="Company name"
            :value="props.item.owner.company_name"
        />
        <SupportSideviewText
            label="Business owner"
            :value="`${props.item.owner.name} ${props.item.owner.surname}`"
        />
        <SupportSideviewText label="City" :value="props.item.owner.city" />
        <SupportSideviewText
            label="Post code"
            :value="props.item.owner.address_post_code"
        />
        <SupportSideviewText
            label="Street"
            :value="props.item.owner.address_street"
        />
        <SupportSideviewText
            label="Building number"
            :value="props.item.owner.address_street_number"
        />
        <SupportSideviewText label="NIP" :value="props.item.owner.nip" />
        <SupportSideviewText
            label="Bank account number"
            :value="props.item.owner.nrb"
        />
        <SupportSideviewText label="ID" :value="props.item.owner.id" />
        <hr />
        <SupportSideviewText
            label="Commission"
            :value="`${props.item.owner.commission} %`"
        />
    </div>
</template>

<script setup lang="ts">
import SupportSideviewText from '../SupportSideviewText.vue'
import SupportSideviewLabel from '../SupportSideviewLabel.vue'
import { ReservationModel } from '@/models/reservation.types'
import { getPriceDisplay } from '@/helpers/utils'
import { computed, onMounted, reactive } from 'vue'
import Parents from '@/store/modules/Parents'
import { getFullName } from 'shared/helpers/utils'
import { ProductType } from '@/models/product.types'
import { t } from '@/i18n'

interface Props {
    item: ReservationModel
}
const props = defineProps<Props>()

const state = reactive({
    parent: null
})

const getTicketDisplay = computed(() => {
    const types = {
        [ProductType.Class]: 'CLASSES',
        [ProductType.DayCamp]: 'DAYCAMP'
    }
    const days =
        props.item.product.type == ProductType.DayCamp
            ? `${props.item.product.dates.length} ${t('DAYS').toLowerCase()}`
            : ''
    const kid = `${props.item.kid_name} ${props.item.kid_surname} ${
        props.item.kid_age
    } ${t('OF_YEARS')}`
    return `${t(types[props.item.product.type])} ${days} (${kid})`
})

onMounted(async () => {
    try {
        state.parent = await Parents.getParentByAccountId({
            payload: { id: props.item.account_id }
        })
    } catch (error) {
        console.log('Account is not a parent')
    }
})
</script>

<style scoped lang="scss">
.sideview-reservations {
    padding: 1rem 2rem;
}
</style>
