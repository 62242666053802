import {
    class_category_tags,
    daycamp_category_tags,
    group_size,
    password,
    verified
} from '../formContent'
import { tag_scope, tag_type } from '@/models/tag.types'
import {
    area_input,
    group_label,
    text_input,
    time_input,
    attachment_input,
    color_input,
    single_select_input,
    multi_select_input,
    rich_text_input,
    map_selector_input,
    date_picker_input,
    switch_input
} from 'shared/models/formContent.types'
import {
    class_schedule_input,
    faq_input,
    owner_schedule_input,
    staff_input
} from '@/models/formContent.types'
import Tags from '@/store/modules/Tags'
import {
    name,
    surname,
    country,
    address_city,
    address_post_code,
    address_street,
    address_street_number,
    building_number,
    apartment_number,
    nip,
    nrb,
    commission,
    phone,
    email,
    company_name,
    district,
    voivodeship,
    title,
    age_tags,
    owner_id,
    description,
    is_published
} from '@/helpers/formContent'
import { t } from '@/i18n'

export const datePickerPlaceholder = 'DD.MM.YYYY'
export const faqPresetItemsDayCamp = [
    'DO_CHILDREN_HAVE_INSURRANCE',
    'HOW_TO_PREPARE_CHILD_FOR_DAY_CAMP',
    'HOW_NUMEROUS_ARE_GROUPS',
    'IS_THERE_POSSIBILITY_OF_SIGNING_UP_FOR_ONE_DAY',
    'CAN_I_RESIGN_WHEN_CHILD_CAN_PARTICIPATE',
    'DOES_CHILDREN_WITH_DISABILITIES_CAN_PARTICIPATE_IN_DAY_CAMP'
].map((question, index) => ({
    question: `${index + 1}. “${t(question)}”`,
    answer: ''
}))
export const faqPresetItemsClass = [
    'HOW_BIG_ARE_GROUPS',
    'HOW_MANY_STAFF_MEMBERS_THERE_ARE',
    'HOW_SAFETY_IS_PROVIDED_DURING_CLASSES',
    'ON_WHICH_LEVEL_HAS_TO_BE_MY_CHILD_TO_PARTICIPATE',
    'IS_IT_POSIBLE_TO_MAKE_UP_CLASSES',
    'DOES_MY_CHILD_GET_CERTIFICATE_AFTER_CLASES',
    'DOES_MY_CHILD_CAN_USE_PHONE_DURING_CLASSES',
    'CAN_CHILDREN_WITH_DISABILITIES_PARTICIPATE'
].map((question, index) => ({
    question: `${index + 1}. “${t(question)}”`,
    answer: ''
}))

export const owner_form = () => ({
    verified: verified(),
    company_name: company_name({ required: true, size: 3 }),
    email: email({ required: true }),
    phone: phone({ required: true }),
    name: name({ title: 'Business owner name', required: true }),
    surname: surname({ title: 'Business owner surname', required: true }),
    country: country(),
    address_city: address_city({ required: true }),
    address_post_code: address_post_code({ required: true }),
    address_street: address_street({ required: true }),
    address_street_number: address_street_number({ required: true, size: 3 }),
    localization_coordinates: map_selector_input({
        title: 'Localization coordinates',
        key: 'localization_coordinates',
        search: true
    }),
    nip: nip({ required: true, size: 3 }),
    nrb: nrb({ required: true, size: 3 }),
    commission: commission({ required: true, size: 3 }),
    description: description({
        title: 'Description (visible to admin)',
        placeholder: 'Description (visible to admin)',
        size: 3
    }),
    days_schedule_label: group_label({
        text: 'Opening hours'
    }),
    days_schedule: owner_schedule_input(),
    localization_description_label: group_label({
        text: 'Description'
    }),
    localization_description: area_input({
        title: 'Location description',
        placeholder: ' ',
        size: 3
    }),
    staff_label: group_label({
        text: 'Organizer and team'
    }),
    staff_description: area_input({
        title: 'Owner and team description',
        key: 'staff_description',
        placeholder: ' '
    }),
    staff: staff_input({
        title: 'Staff members',
        key: 'staff'
    }),
    gallery_label: group_label({
        text: 'Gallery',
        key: 'gallery_label'
    }),
    gallery: attachment_input({
        key: 'gallery'
    })
})

export const categoriesAddTagForm = () => {
    return {
        scope: single_select_input({
            title: 'User',
            key: 'scope',
            value: tag_scope.blog_parent,
            options: [
                {
                    display: 'Parent',
                    value: tag_scope.blog_parent
                },
                {
                    display: 'Business owner',
                    value: tag_scope.blog_owner
                },
                {
                    display: 'Marketplace',
                    value: tag_scope.marketplace
                },
                {
                    display: 'All',
                    value: tag_scope.all
                }
            ]
        }),
        type: single_select_input({
            title: 'Type of tag',
            key: 'type',
            value: tag_type.product_category,
            options: [
                {
                    display: 'Category',
                    value: tag_type.product_category
                },
                {
                    display: 'Product type',
                    value: tag_type.product_type
                },
                {
                    display: 'Product group age',
                    value: tag_type.product_group_age
                },
                {
                    display: 'Other',
                    value: tag_type.other
                }
            ]
        }),
        id: text_input({
            title: 'Key',
            key: 'id',
            placeholder: 'Key',
            value: ''
        }),
        display: text_input({
            title: 'Display',
            key: 'display',
            placeholder: 'Display',
            value: ''
        }),
        parent_id: {
            title: 'Parent',
            key: 'type',
            type: 'single-select',
            scope: 'single',
            value: '',
            options: Tags.listTags.map(({ display, id }) => {
                return {
                    display,
                    value: id
                }
            })
        },
        description: area_input({
            title: 'Text SEO',
            key: 'description',
            placeholder: 'Text SEO'
        }),
        icon: attachment_input({
            title: 'Icon',
            key: 'icon'
        }),
        color: color_input({
            title: 'Color',
            key: 'color'
        })
    }
}
export const product_form = () => {
    return {
        is_published: is_published(),
        title: title({ placeholder: 'Camp name', size: 3 }),
        category_tags: daycamp_category_tags({
            placeholder: 'Choose category',
            size: 3
        }),

        company_label: group_label({
            text: 'Company',
            key: 'company_label'
        }),
        owner_id: owner_id({
            required: false,
            placeholder: 'Choose owner'
        }),
        owner_name: text_input({
            title: 'Owner display name',
            key: 'owner_name',
            placeholder: 'Owner display name'
        }),
        email: email({ title: 'Address e-mail' }),
        phone: phone(),

        localization_label: group_label({
            text: 'Localization description',
            key: 'localization_label'
        }),
        country: country(),
        voivodeship: voivodeship(),
        district: district({
            placeholder: 'District'
        }),
        city: address_city({ key: 'city' }),
        post_code: address_post_code({ key: 'post_code' }),
        street: address_street({ key: 'street' }),
        building_number: building_number(),
        apartment_number: apartment_number(),
        localization_coordinates: map_selector_input({
            title: 'Localization coordinates',
            key: 'localization_coordinates',
            search: true
        }),
        date_camp_label: group_label({
            text: 'Date camp',
            key: 'date_camp_label'
        }),
        date_camp_from: date_picker_input({
            title: 'From',
            key: 'date_camp_from',
            placeholder: datePickerPlaceholder,
            size: 2
        }),
        date_camp_to: date_picker_input({
            title: 'To',
            key: 'date_camp_to',
            placeholder: datePickerPlaceholder,
            size: 2
        }),

        time_camp_label: group_label({
            text: 'Time camp',
            key: 'time_camp_label'
        }),
        time_start: time_input({
            title: 'Time Start',
            key: 'time_start',
            size: 2
        }),
        time_end: time_input({
            title: 'Time End',
            key: 'time_end',
            size: 2
        }),

        recrutation_label: group_label({
            text: 'Recruitment',
            key: 'recruitment_label'
        }),
        recrutation_start_date: date_picker_input({
            title: 'Start',
            key: 'recrutation_start_date',
            placeholder: datePickerPlaceholder,
            size: 2
        }),
        recrutation_end_date: date_picker_input({
            title: 'End',
            key: 'recrutation_end_date',
            placeholder: datePickerPlaceholder,
            size: 2
        }),

        children_age_label: group_label({
            text: 'Children age',
            key: 'children_age_label'
        }),
        age_tags: age_tags({ placeholder: 'Choose age' }),

        informations_label: group_label({
            text: 'Informations',
            key: 'informations_label'
        }),
        slots_per_day: text_input({
            title: 'Slots per day',
            key: 'slots_per_day',
            value: 0,
            scope: 'int'
        }),
        reported_to_board: switch_input({
            label: 'Badge “Zgłoszono do Kuratorium Oświaty”'
        }),

        camp_description_label: group_label({
            text: 'Camp description',
            key: 'camp_description_label'
        }),
        description: area_input({
            title: 'Description',
            key: 'description',
            placeholder: ' '
        }),
        day_plan: area_input({
            title: 'Day Plan',
            key: 'day_plan',
            placeholder: ' '
        }),
        food_description: area_input({
            title: 'Food description',
            key: 'food_description',
            placeholder: ' '
        }),
        localization_description: area_input({
            title: 'Localization description',
            key: 'localization_description',
            placeholder: ' '
        }),

        faq_label: group_label({
            text: 'FAQ',
            key: 'faq_label'
        }),
        faq: faq_input({
            key: 'faq',
            presetItems: faqPresetItemsDayCamp,
            value: [...faqPresetItemsDayCamp]
        }),

        staff_label: group_label({
            text: 'Organizer and team',
            key: 'staff_label'
        }),
        staff_description: area_input({
            title: 'Staff description',
            key: 'staff_description',
            placeholder: 'Staff description'
        }),
        staff: staff_input({
            title: 'Staff members',
            key: 'staff'
        }),

        gallery_label: group_label({
            text: 'Gallery',
            key: 'gallery_label'
        }),
        attachments: attachment_input({
            title: 'Attachments',
            key: 'attachments'
        }),

        tickets_label: group_label({
            text: 'Tickets',
            key: 'tickets_label'
        }),
        price_bundle: text_input({
            title: 'Camp price',
            key: 'camp_rpice',
            placeholder: 'Camp price (zł)',
            value: 0,
            scope: 'price',
            size: 3
        }),
        price_single_day: text_input({
            title: 'Price single day',
            key: 'price_single_day',
            placeholder: 'Price single day (zł)',
            value: 0,
            scope: 'price',
            size: 3
        })
    }
}
export const class_form = () => {
    return {
        is_published: is_published(),
        title: title({ placeholder: 'Title', size: 3 }),
        category_tags: class_category_tags({ size: 3 }),

        company_label: group_label({
            text: 'Company',
            key: 'company_label'
        }),
        owner_id: owner_id({
            required: false,
            placeholder: 'Choose owner'
        }),
        owner_name: text_input({
            title: 'Display owner name',
            key: 'owner_name',
            placeholder: 'Display owner name'
        }),
        email: email({ title: 'Address email (for these classes)' }),
        phone: phone({
            title: 'Phone number (for these classes)'
        }),

        localization_label: group_label({
            text: 'Localization description',
            key: 'localization_label'
        }),
        country: country(),
        voivodeship: voivodeship({
            placeholder: 'Choose voivodeship'
        }),
        district: district({
            placeholder: 'District'
        }),
        city: address_city({ key: 'city' }),
        post_code: address_post_code({ key: 'post_code' }),
        street: address_street({ key: 'street' }),
        building_number: building_number(),
        apartment_number: apartment_number(),

        localization_coordinates: map_selector_input({
            title: 'Localization coordinates',
            key: 'localization_coordinates',
            search: true
        }),

        date_camp_label: group_label({
            text: 'Date and time',
            key: 'date_class_label'
        }),
        schedule: class_schedule_input(),

        recrutation_label: group_label({
            text: 'Recruitment',
            key: 'recruitment_label'
        }),
        recrutation_start_date: date_picker_input({
            title: 'Start',
            key: 'recrutation_start_date',
            placeholder: datePickerPlaceholder,
            size: 2
        }),
        recrutation_end_date: date_picker_input({
            title: 'End',
            key: 'recrutation_end_date',
            placeholder: datePickerPlaceholder,
            size: 2
        }),

        children_age_label: group_label({
            text: 'Children age',
            key: 'children_age_label'
        }),
        age_tags: age_tags({
            placeholder: 'Choose'
        }),

        group_size_label: group_label({
            text: 'Group size',
            key: 'group_size_label'
        }),
        group_size: group_size(),

        base_free_slots_label: group_label({
            text: 'Slots',
            key: 'base_free_slots_label'
        }),
        base_free_slots: text_input({
            title: 'Free slots',
            key: 'base_free_slots',
            placeholder: '0',
            scope: 'int',
            size: 2
        }),

        camp_description_label: group_label({
            text: 'Classes description',
            key: 'camp_description_label'
        }),
        description: area_input({
            title: 'Description',
            key: 'description',
            placeholder: ' '
        }),
        localization_description: area_input({
            title: 'Localization description',
            key: 'localization_description',
            placeholder: ' '
        }),

        faq_label: group_label({
            text: 'FAQ',
            key: 'faq_label'
        }),
        faq: faq_input({
            key: 'faq',
            presetItems: faqPresetItemsClass,
            value: [...faqPresetItemsClass]
        }),

        staff_label: group_label({
            text: 'Organizer and team',
            key: 'staff_label'
        }),

        // TODO: new staff input when BE is ready 19.07
        staff_description: area_input({
            title: 'Staff description',
            key: 'staff_description',
            placeholder: 'Staff description'
        }),
        staff: staff_input({
            title: 'Staff members',
            key: 'staff'
        }),

        gallery_label: group_label({
            text: 'Gallery',
            key: 'gallery_label'
        }),
        attachments: attachment_input({
            title: 'Attachments',
            key: 'attachments'
        }),

        tickets_label: group_label({
            text: 'Tickets',
            key: 'tickets_label'
        }),
        trial_price: text_input({
            title: 'Trial',
            key: 'trial_price',
            placeholder: 'Price (zł)',
            value: 0,
            scope: 'price'
        }),
        trial_price_description: area_input({
            title: 'Description',
            key: 'trial_price_description',
            placeholder: ' '
        }),
        trial_price_caption: area_input({
            title: 'Description to voucher (*)',
            key: 'trial_price_caption',
            placeholder: ' '
        }),
        semester_price: text_input({
            title: 'Semester',
            key: 'semester_price',
            placeholder: 'Price (zł)',
            value: 0,
            scope: 'price'
        }),
        semester_price_description: area_input({
            title: 'Description',
            key: 'semester_price_description',
            placeholder: ' '
        }),
        advance_price: text_input({
            title: 'Advance',
            key: 'advance_price',
            placeholder: 'Price (zł)',
            value: 0,
            scope: 'price'
        }),
        advance_price_description: area_input({
            title: 'Description',
            key: 'advance_price_description',
            placeholder: ' '
        })
    }
}
export const article_form = () => {
    return {
        category_tags: multi_select_input({
            title: 'Category',
            key: 'category_tags',
            value: [],
            placeholder: 'Choose category',
            search_options: true,
            options: Tags.listTags
                .filter(
                    tag =>
                        (tag.scope == tag_scope.blog_owner ||
                            tag.scope == tag_scope.blog_parent) &&
                        tag.type == tag_type.product_category
                )
                .map(tag => {
                    return {
                        display: tag.display,
                        value: tag.id
                    }
                })
        }),
        title: text_input({
            title: 'Title',
            key: 'title',
            value: '',
            placeholder: 'Title',
            size: 3
        }),
        description: area_input({
            title: 'Description',
            key: 'description',
            value: '',
            placeholder: 'Short article description'
        }),
        thumbnail_image: attachment_input({
            title: 'Thumbnail image',
            key: 'thumbnail_image'
        }),
        background_image: attachment_input({
            title: 'Background image',
            key: 'background_image'
        }),
        body: rich_text_input({
            title: 'Article editor',
            key: 'body',
            value: '',
            placeholder: 'Write here...',
            size: 3
        }),
        other_tags: multi_select_input({
            title: 'Tags',
            key: 'other_tags',
            value: [],
            placeholder: 'Choose tags',
            search_options: true,
            options: Tags.listTags.map(tag => {
                return {
                    display: tag.display,
                    value: tag.id
                }
            })
        })
    }
}
export const support_login_form = () => {
    return {
        mail: email(),
        password: password()
    }
}
