<template>
    <div class="schedule-input">
        <SupportClassScheduleDayInput
            v-for="day in state.daysFields"
            :key="day.key"
            :id="day.key"
            :form="day.form"
            @remove="removeDay"
            @update="updateDay"
        />
        <div class="schedule-input-button" @click="addDay">
            <Icon icon="add" />
            <span v-text="`Add day`" />
        </div>
        <hr class="divider" />
        <FormCreator
            :fields="state.dateRangeForm"
            :errors="state.errors"
            @update:fields="updateDateRange"
        />
        <hr class="divider" />
        <div class="schedule-input-summary">
            <div class="summary-item">
                <span v-text="`Summary: `" />
                <b v-text="getSummary" />
            </div>
            <div class="summary-item">
                <span v-text="`Exceptions: `" />
                <b v-text="getExceptions" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, watch } from 'vue'
import FormCreator from './FormCreator.vue'
import Errors from 'shared/helpers/errors'
import { dateWithFormat } from 'shared/helpers/date'
import SupportClassScheduleDayInput from './SupportClassScheduleDayInput.vue'
import Icon from 'shared/components/utils/Icon.vue'
import { ClassWeekDay } from '@/models/class.types'
import { getScheduleObject } from '@/helpers/classProfile'
import { v4 as uuidv4 } from 'uuid'
import { class_schedule, class_schedule_range } from '@/helpers/formContent'

const emit = defineEmits<{
    (e: 'update', value: any): void
    (e: 'update:modelValue', value: any): void
}>()

interface Props {
    field: any
    modelValue: string
}
const props = defineProps<Props>()

const state = reactive({
    daysFields: [],
    dateRangeForm: class_schedule_range(),
    errors: new Errors()
})

const emitUpdate = value => {
    emit('update', value)
    emit('update:modelValue', value)
}
const addDay = () => {
    const value = { ...props.field.value }
    value.week_days.push({
        key: uuidv4(),
        week_day: 0,
        start_time: '',
        end_time: ''
    })
    emitUpdate(value)
}
const removeDay = (id: number) => {
    const value = { ...props.field.value }
    const index = state.daysFields.findIndex(day => day.key === id)
    if (index != -1) {
        value.week_days.splice(index, 1)
        emitUpdate(value)
    }
}
const updateDay = fields => {
    const days = state.daysFields.map(({ key, form }) => {
        return {
            week_day: form.week_day.value,
            start_time:
                fields.key == key
                    ? fields.form.start_time.value
                    : form.start_time.value,
            end_time:
                fields.key == key
                    ? fields.form.end_time.value
                    : form.end_time.value
        }
    })
    emitUpdate({ ...props.field.value, week_days: days })
}
const setDays = () => {
    state.daysFields = props.field.value.week_days.map((day: ClassWeekDay) => {
        return {
            key: uuidv4(),
            form: class_schedule(day)
        }
    })
}

const updateDateRange = update => {
    const { start_date, end_date, exceptions } = update
    const value = {
        week_days: props.field.value.week_days,
        start_date: start_date.value,
        end_date: end_date.value,
        exceptions: exceptions.value
            ? Array.isArray(exceptions.value)
                ? exceptions.value.map((exception: string) => ({
                      date: exception,
                      start_time: 0,
                      end_time: 0,
                      week_day: 0
                  }))
                : [
                      {
                          date: exceptions.value,
                          start_time: 0,
                          end_time: 0,
                          week_day: 0
                      }
                  ]
            : []
    }
    emitUpdate(value)
}

const setDateRangeForm = () => {
    const { start_date, end_date, exceptions } = state.dateRangeForm
    start_date.value = props.field.value.start_date
    end_date.value = props.field.value.end_date
    exceptions.value = props.field.value.exceptions.map(({ date }) => date)
}

const getExceptions = computed(() => {
    const value = state.dateRangeForm.exceptions.value
    return !value
        ? ''
        : Array.isArray(value)
        ? value
              .map(exception =>
                  dateWithFormat({ date: exception, format: 'D.MM.YYYY' })
              )
              .join('; ')
        : dateWithFormat({ date: value, format: 'D.MM.YYYY' })
})

const getSummary = computed(() => {
    const classCount = Object.values(getScheduleObject(props.field.value))
        .map((value: { dates: string[] }) => value.dates)
        .flat().length
    return `${classCount} lessons`
})

watch(
    () => props.field.value.week_days.length,
    () => {
        setDays()
    }
)
onMounted(() => {
    setDays()
    setDateRangeForm()
})
</script>

<style scoped lang="scss">
.divider {
    margin: 0.5rem 0;
}
.schedule-input-button {
    @extend .is-body-2-bold-web;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    cursor: pointer;
}
</style>
