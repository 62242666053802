<template>
    <div class="password">
        <TextInput
            :field="props.field"
            v-model="value"
            :overrideClass="props.overrideClass"
            :passwordVisible="state.passwordVisible"
        />
        <Icon
            :icon="icon"
            class="password-icon"
            @click="state.passwordVisible = !state.passwordVisible"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, reactive, onMounted, watch } from 'vue'
import { TextFieldModel } from '../../models/formContent.types'
import Icon from '../../components/utils/Icon.vue'
import TextInput from './TextInput.vue'

const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
}>()

interface Props {
    field: TextFieldModel
    overrideClass: string
    modelValue: string
}
const props = defineProps<Props>()

const state = reactive({
    value: '',
    passwordVisible: false
})

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})
const icon = computed(() => {
    return state.passwordVisible ? 'visibility' : 'visibility_off'
})
</script>

<style scoped lang="scss">
.password {
    position: relative;
}
.password-icon {
    position: absolute;
    top: 2.2rem;
    right: 1rem;
    opacity: 0.5;
}
</style>
