<template>
    <div v-if="editor" class="tiptap-buttons">
        <Selector
            v-model="headingSelector.value"
            :field="headingSelector"
            class="heading-selector"
        />
        <img
            :src="getAsset('icons/format_bold.svg')"
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }"
        />
        <img
            :src="getAsset('icons/format_underlined.svg')"
            @click="editor.chain().focus().toggleUnderline().run()"
            :class="{ 'is-active': editor.isActive('underline') }"
        />
        <img
            :src="getAsset('icons/format_italic.svg')"
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
        />
        <img
            :src="getAsset('icons/format_list_bulleted.svg')"
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
        />
        <img
            :src="getAsset('icons/format_list_numbered.svg')"
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{ 'is-active': editor.isActive('orderedList') }"
        />
        <img
            :src="getAsset('icons/format_align_left.svg')"
            @click="editor.chain().focus().setTextAlign('left').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
        />
        <img
            :src="getAsset('icons/format_align_center.svg')"
            @click="editor.chain().focus().setTextAlign('center').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
        />
        <img
            :src="getAsset('icons/format_align_right.svg')"
            @click="editor.chain().focus().setTextAlign('right').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
        />
        <img
            :src="getAsset('icons/format_align_justify.svg')"
            @click="editor.chain().focus().setTextAlign('justify').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
        />
        <img
            :src="getAsset('icons/format_image.svg')"
            @click="imageUploadModal.show"
        />
    </div>
    <editor-content :editor="editor" class="editor-content" />
    <Modal
        ref="imageUploadModal"
        :title="state.imageUploadModal.title"
        :buttons="state.imageUploadModal.buttons"
    >
        <Loading v-if="state.loading.imageUpload" />
        <FormCreator
            v-else
            :fields="state.imageUploadModal.form"
            :errors="state.errors"
        />
    </Modal>
</template>

<script setup lang="ts">
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'
import Link from '@tiptap/extension-link'
import Dropdown from 'shared/components/utils/Dropdown.vue'
import Modal from '@/components/utils/Modal.vue'
import { reactive, watch, onMounted, ref } from 'vue'
import { getAsset } from 'shared/helpers/utils'
import Selector from 'shared/components/form/Selector.vue'
import FormCreator from '@/components/form/FormCreator.vue'
import Errors from 'shared/helpers/errors'
import { attachment_input } from 'shared/models/formContent.types'
import { createPayload } from 'shared/helpers/form'
import storage from '@/firebase/storage'
import Loading from '@/components/utils/Loading.vue'

const imageUploadModal = ref<InstanceType<typeof Modal> | null>(null)

const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    }
})
const emit = defineEmits(['update', 'update:modelValue'])
const editor = new Editor({
    extensions: [
        StarterKit,
        TextAlign.configure({
            types: ['heading', 'paragraph']
        }),
        Underline,
        Image,
        Dropcursor,
        Link
    ],
    content: props.modelValue,
    onUpdate: () => {
        emit('update', editor.getHTML())
    }
})
const state = reactive({
    imageUploadModal: {
        visible: false,
        title: 'Upload image',
        form: {
            image: attachment_input({
                title: 'Image',
                key: 'image'
            })
        },
        buttons: [
            {
                key: 'submit',
                display: 'Submit',
                class: 'primary-button-admin',
                action: () => {
                    submitImage()
                }
            }
        ]
    },
    loading: {
        imageUpload: false
    },
    errors: new Errors()
})
const headingSelector = reactive({
    key: 'scope',
    type: 'single-select',
    value: 'paragraph',
    options: [
        {
            value: 'paragraph',
            display: 'Paragraph',
            fn: () => {
                editor.chain().focus().setParagraph().run()
            }
        },
        {
            value: 'h1',
            display: 'Heading 1',
            fn: () => {
                toggleHeading(1)
            }
        },
        {
            value: 'h2',
            display: 'Heading 2',
            fn: () => {
                toggleHeading(2)
            }
        },
        {
            value: 'h3',
            display: 'Heading 3',
            fn: () => {
                toggleHeading(3)
            }
        }
    ]
})
const toggleHeading = (level: number) => {
    editor.chain().focus().toggleHeading({ level }).run()
}
const submitImage = async () => {
    const formPayload = createPayload({ form: state.imageUploadModal.form })
    state.loading.imageUpload = true
    const imageUrls = await storage.uploadLocalFiles(formPayload.image)
    state.loading.imageUpload = false

    imageUrls.forEach(url => {
        editor.chain().focus().setImage({ src: url }).run()
    })
    state.imageUploadModal.form.image.value = []
    imageUploadModal.value.close()
}
watch(
    () => headingSelector.value,
    () => {
        headingSelector.options
            .find(heading => heading.value == headingSelector.value)
            .fn()
    }
)
watch(
    () => props.modelValue,
    () => {
        editor.commands.setContent(props.modelValue, false)
    }
)
</script>

<style lang="scss">
.tiptap-buttons {
    background-color: $gray-00;
    padding: 1rem;
    border: 1px solid $gray-60;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .heading-selector {
        width: 150px;
        button {
            border-radius: 8px;
        }
    }

    img {
        border: none;
        background-color: transparent;
        padding: 0.3rem;
        width: 32px;
        height: 32px;
        cursor: pointer;

        &:hover {
            background-color: $gray-40;
        }

        &.is-active {
            background-color: $gray-60;
        }
    }
}
.editor-content {
    flex: 1;
}
.ProseMirror {
    border: 1px solid $gray-60;
    border-top: none;
    border-radius: 0 0 10px 10px;
    padding: 0.5rem;
    height: 350px;
    overflow: auto;

    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 2rem;
    }

    ul {
        list-style-type: disc;
    }

    p {
        @extend .is-body-1-web;
    }
    h1 {
        @extend .is-h4-web;
    }
    h2 {
        @extend .is-h5-web;
    }
    h3 {
        @extend .is-h6-web;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0d0d0d, 0.1);
        margin: 2rem 0;
    }
}
</style>
