<template>
    <div v-if="currentLayout?.tabs?.length > 0" class="support-tabs">
        <div
            v-for="tab in currentLayout.tabs"
            :key="tab.key"
            :class="[
                'support-tab',
                isSupportSubpageActive(tab.key) ? 'active' : ''
            ]"
            v-text="tab.display"
            @click="goToTab(tab.key)"
        />
    </div>
    <div v-if="!state.initLoading" class="actions">
        <div class="actions-filters">
            <!-- disabled until decided how search should work 15.07.22 -->
            <!-- <TextInput
                class="actions-search"
                :field="state.searchField"
                size="normal"
            /> -->
            <div
                v-for="filter in current.dataTable.filters"
                :key="filter.key"
                class="action-filters-selector"
            >
                <Selector
                    class="filter"
                    v-model="filter.value"
                    :field="filter"
                    height="35px"
                    @update="updateFilters()"
                />
            </div>
        </div>
        <div v-if="current.buttons" class="actions-buttons">
            <button
                v-for="button in current.buttons"
                :key="button.key"
                class="action-button"
                v-text="button.display"
                @click="
                    button.fn({
                        publishAllModal,
                        draftAllModal,
                        productTypeModal
                    })
                "
            />
        </div>
    </div>
    <ListLayout :table="table" class="support-table" @select="showSideview" />

    <Modal ref="publishAllModal" :buttons="publishAllModalButtons">
        <div v-if="state.loading.publishModal" class="loading">
            <div class="loading-text" v-text="`This may take a while`" />
            <Loading />
        </div>
        <div v-else class="remove-modal-text">
            Are you sure you want to publish all products? After you click 'Yes'
            there's no coming back
        </div>
    </Modal>
    <Modal ref="draftAllModal" :buttons="draftAllModalButtons">
        <div v-if="state.loading.draftModal" class="loading">
            <div class="loading-text" v-text="`This may take a while`" />
            <Loading />
        </div>
        <div v-else class="remove-modal-text">
            Are you sure you want to save all products as drafts? After you
            click 'Yes' there's no coming back
        </div>
    </Modal>
    <Modal ref="productTypeModal" title="Add product">
        <div class="product-type-modal-buttons">
            <div
                class="product-type-modal-button"
                @click="setProductType(ProductType.DayCamp)"
            >
                DAY CAMP
            </div>
            <div
                class="product-type-modal-button"
                @click="setProductType(ProductType.Class)"
            >
                CLASSES
            </div>
        </div>
    </Modal>

    <SupportSideview
        :selectedItem="state.selectedItem"
        :type="current.sideview"
        @close="closeSideview"
        @duplicate="handleDuplication"
        @remove="handleRemove"
    />
</template>
<script setup lang="ts">
import { reactive, computed, onMounted, watch, ref } from 'vue'
import {
    goToSupportClassCreator,
    goToSupportProductCreator,
    isSupportSubpageActive
} from '@/helpers/support'
import { supportLayouts } from '@/helpers/support/supportLayouts'
import ListLayout from 'shared/components/layout/ListLayout.vue'
import Modal from '@/components/utils/Modal.vue'
import Selector from 'shared/components/form/Selector.vue'
import { useRouter, useRoute } from 'vue-router'
import Errors from 'shared/helpers/errors'
import Products from '@/store/modules/Products'
import { ProductModel, ProductType } from '@/models/product.types'
import SupportSideview from './components/SupportSideview.vue'
import Loading from '@/components/utils/Loading.vue'
import Tags from '@/store/modules/Tags'

const publishAllModal = ref()
const draftAllModal = ref()
const productTypeModal = ref()

const router = useRouter()
const route = useRoute()
const state = reactive({
    actionConfig: null,
    initLoading: true,
    filters: [],
    selectedItem: null,
    errors: new Errors(),
    loading: {
        publishModal: false,
        draftModal: false
    }
})

const checkFilters = item => {
    if (state.filters.length == 0) return true
    return state.filters.every(({ key, values }) => {
        return values.length > 0
            ? values.some(value => value == item[key])
            : true
    })
}

const updateFilters = () => {
    state.filters = current.value.dataTable.filters
        ? current.value.dataTable.filters.map(({ key, value }) => {
              return {
                  key,
                  values: value
              }
          })
        : []
}
const filteredData = computed(() => {
    return current.value.dataTable.data.value.filter(item => checkFilters(item))
})

const table = computed(() => ({
    config: current.value.dataTable?.config,
    data: filteredData.value,
    loading: state.initLoading
}))
const currentLayoutKey = computed(() => {
    return route.params.layout as string
})
const currentLayout = computed(() => {
    return supportLayouts[currentLayoutKey.value]
})
const currentTab = computed(() => {
    const { tab } = route.params
    return currentLayout.value?.tabs.find(({ key }) => key == tab)
})
const current = computed(() => {
    // NOTE: it has to be like this - I was forced to do it - sorry
    const preppedCurrentTab = currentTab.value?.key == 'products' && {
        ...currentTab.value
    }
    if (preppedCurrentTab)
        preppedCurrentTab.dataTable.filters.find(
            ({ key }) => key == 'category_tags_display'
        ).options = Tags.listTags
    return currentTab.value || currentLayout.value
})

const publishAllModalButtons = computed(() => {
    return state.loading.publishModal
        ? []
        : [
              {
                  display: 'Cancel',
                  key: 'cancel',
                  class: 'modal-secondary-button',
                  action: () => {
                      publishAllModal.value.close()
                  }
              },
              {
                  display: 'Yes',
                  key: 'yes',
                  class: 'modal-primary-button',
                  action: async () => {
                      const draftIds = Products.listProducts
                          .filter(
                              (product: ProductModel) => !product.is_published
                          )
                          .map(({ id }) => id)
                      state.loading.publishModal = true
                      await Products.changeProductsPublishedStatus({
                          payload: {
                              ids: draftIds,
                              is_published: true
                          }
                      })
                      state.loading.publishModal = false
                      publishAllModal.value.close()
                      loadData()
                  }
              }
          ]
})
const draftAllModalButtons = computed(() => {
    return state.loading.draftModal
        ? []
        : [
              {
                  display: 'Cancel',
                  key: 'cancel',
                  class: 'modal-secondary-button',
                  action: () => {
                      draftAllModal.value.close()
                  }
              },
              {
                  display: 'Yes',
                  key: 'yes',
                  class: 'modal-primary-button',
                  action: async () => {
                      const publishedIds = Products.listProducts
                          .filter(
                              (product: ProductModel) => product.is_published
                          )
                          .map(({ id }) => id)
                      state.loading.draftModal = true
                      await Products.changeProductsPublishedStatus({
                          payload: {
                              ids: publishedIds,
                              is_published: false
                          }
                      })
                      state.loading.draftModal = false
                      draftAllModal.value.close()
                      loadData()
                  }
              }
          ]
})
const goToTab = tab => {
    router.push({ name: 'support_tab', params: { tab } })
}
const showSideview = row => {
    state.selectedItem = row
}
const closeSideview = () => {
    state.selectedItem = null
}
const setProductType = (type: ProductType) => {
    switch (type) {
        case ProductType.DayCamp:
            goToSupportProductCreator()
            break
        case ProductType.Class:
            goToSupportClassCreator()
            break
    }
}
const handleDuplication = () => {
    state.selectedItem = null
    loadData()
}
const handleRemove = () => {
    state.selectedItem = null
}
watch(
    () => router.currentRoute.value.path,
    async () => {
        if (router.options.history.location.includes('editor')) return
        state.initLoading = true
        await loadData()
        updateFilters()
        state.initLoading = false
    }
)
const loadData = async () => {
    await current.value.dataTable.loadData()
}
onMounted(async () => {
    await loadData()
    state.initLoading = false
})
</script>

<style scoped lang="scss">
.support-tabs {
    display: flex;
    border: 1px solid $gray-20;
    padding: 0.5rem 1.5rem;
}
.support-tab {
    padding: 0 1rem;
    color: $navy-blue-20;
    cursor: pointer;
    &.active {
        font-weight: bold;
        color: $navy-blue-60;
    }
}
.actions {
    display: flex;
    justify-content: space-between;
    margin: 1rem 2rem;
    height: 40px;
}
.actions-filters {
    display: flex;
    gap: 0.5rem;
}
.filter {
    .selector-display {
        background-color: red;
    }
}
.actions-search {
    width: 250px;
}
.actions-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}
.action-button {
    @extend .primary-button-admin;
    @extend .is-button-text-2-web;
    padding: 0.5rem 2.5rem;

    &:nth-child(2) {
        @extend .secondary-button-admin;
    }
}
.support-table {
    margin: 0 1rem;
    margin-bottom: 1rem;
    max-height: 100%;

    :deep(.table-wrapper) {
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
    }
}

.remove-modal-text {
    @extend .is-h6-web;
    text-align: center;
    padding: 3rem;
}
.product-type-modal-buttons {
    display: flex;
    gap: 1rem;
}
.product-type-modal-button {
    @extend .is-body-1-bold-web;
    border-radius: 8px;
    border: 2px solid $gray-40;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1.5/1;
    cursor: pointer;

    &:hover {
        background-color: $gray-00;
    }
}

.loading-text {
    @extend .is-h5-web;
    text-align: center;
    margin-bottom: 1rem;
}
</style>
