import firebase from 'firebase/app'
import Accounts from '@/store/modules/Accounts'
import 'firebase/storage'

import {
    addFilesFromEvent,
    uploadFiles,
    uploadFilesFromEvent,
    getFile,
    uploadLocalFiles
} from 'shared/firebase/storageProvider'
import toast from '@/helpers/toast'
import { t } from '@/i18n'
import ClientAccounts from '@/store/modules/ClientAccounts'

const alertFn = () => {
    toast.error(t('FILE_IS_TOO_BIG'))
}
const storage = firebase.storage

const user_id = () =>
    Accounts.currentAccount?.id || ClientAccounts.currentClientAccount.id

const getFiles = async (
    urls: string[]
): Promise<{ url: string; name: string }[]> => {
    const promises =
        urls.map(async (url: string) => {
            const file = await getFile({ storage, url })
            if (!file) return undefined
            const { name, type } = file
            return { url, name, type }
        }) || []
    const attachments = await Promise.all(promises)
    return attachments.filter(Boolean)
}

export default {
    addFilesFromEvent: event => addFilesFromEvent({ event, alertFn }),
    uploadFiles: files => uploadFiles({ storage, files, user_id: user_id() }),
    uploadFilesFromEvent: event =>
        uploadFilesFromEvent({ storage, event, user_id: user_id() }),
    uploadLocalFiles: files =>
        uploadLocalFiles({ storage, files, user_id: user_id() }),
    getFile: (url: string) => getFile({ storage, url }),
    getFiles,
    removeFile: (url: string) => {}
}
