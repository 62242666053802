import { RouteRecordRaw } from 'vue-router'
import SupportView from '@/views/Support/SupportView.vue'
import SupportCreator from '@/views/Support/SupportCreator.vue'
import SupportUploadOwner from '@/views/Support/SupportUploadOwner.vue'
import SupportUploadProducts from '@/views/Support/SupportUploadProducts.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: {
            name: 'support_tab',
            params: { layout: 'activity', tab: 'owners' }
        }
    },
    { path: ':layout/:tab', component: SupportView, name: 'support_tab' },
    {
        path: ':layout/:tab/editor/:id?',
        component: SupportCreator,
        name: 'support_creator'
    },
    {
        path: 'upload/owners',
        name: 'support_upload_owner',
        component: SupportUploadOwner
    },
    {
        path: 'upload/products',
        name: 'support_upload_products',
        component: SupportUploadProducts
    }
]
export default routes
