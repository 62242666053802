import store, { shouldPreserve } from '@/store'
import {
    Action,
    Module,
    Mutation,
    VuexModule,
    getModule
} from 'vuex-module-decorators'
import type { LivekidAccountModel } from 'shared/lk/models/accounts.types'
import { type_support } from 'shared/lk/models/accounts.types'
import api from '@/api'
import ClientAccounts from './ClientAccounts'
const keys = ['account', 'current_role_id']
const name = 'account'
@Module({
    name,
    store,
    dynamic: true,
    preserveState: shouldPreserve(name, keys)
})
class Accounts extends VuexModule {
    private account: LivekidAccountModel = null
    private current_role_id: number = null
    @Mutation
    public RESET_ACCOUNT_STATE() {
        this.account = null
        this.current_role_id = null
    }
    @Mutation
    public SET_CURRENT_ROLE(role_id: number) {
        this.current_role_id = role_id
    }
    @Mutation
    public SET_ACCOUNT(account: LivekidAccountModel) {
        this.account = account
    }
    @Mutation
    public REFRESH_JWT_TOKEN(jwt: string) {
        const role_index = this.account.roles.findIndex(
            ({ id, nid }) =>
                id == this.current_role_id || nid == this.current_role_id
        )
        if (role_index == -1) return
        this.account.roles[role_index].jwt = jwt
    }
    @Action({ rawError: true })
    async login({ payload, config }) {
        const account = await api.post(`v1/accounts/login`, payload, {
            ...config,
            base_url: 'lk_api'
        })
        this.SET_ACCOUNT({
            ...account,
            mail: payload.mail,
            id: account.account_id
        })
        const supportRole = account.roles.find(
            ({ type }) => type == type_support
        )
        if (supportRole) this.SET_CURRENT_ROLE(supportRole.id)
        ClientAccounts.logOut()
        return account
    }
    @Action({ rawError: true })
    async checkIfExistsLK({ payload, config }) {
        const exists = await api.get(`v1/accounts/exists/${payload.email}`, {
            ...config,
            base_url: 'lk_api'
        })
        return exists
    }
    @Action({ rawError: true })
    async logout() {
        this.RESET_ACCOUNT_STATE()
    }
    get currentAccount() {
        return this.account
    }
    get currentRole() {
        return this.account?.roles?.find(({ id }) => id == this.current_role_id)
    }
    get currentRoleType() {
        return this.currentRole?.type
    }
    get isLogged(): boolean {
        return Boolean(this.currentRole)
    }
    get isSupport(): boolean {
        return this.currentRoleType == type_support
    }
}

export default getModule(Accounts)
