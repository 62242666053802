<template>
   <label class="checkbox" :class="{ '-disabled': disabled }">
      <input
         :checked="checked"
         :disabled="disabled"
         type="checkbox"
         @change="onChange"
      />
      {{ t(label) }}
   </label>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { t } from '@/i18n'
export default defineComponent({
   props: {
      modelValue: Boolean,
      label: { type: String, default: '' },
      checked: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false }
   },
   setup(_props, { emit }) {
      const onChange = (event: any) => {
         emit('update:modelValue', event.target.checked)
      }
      return { t, onChange }
   }
})
</script>
<style lang="scss" scoped>
.checkbox {
   user-select: none;
   &.-disabled {
      pointer-events: none;
   }
}
</style>
