import { getAsset } from './utils'

export const appTitle = 'FunClass'
export const appDescription = 'FunClass'
export const appCompanyName = 'LiveKid Sp. z o.o.'
export const appPhoneNumber = '+48 725 274 365'
export const appAddressStreet = 'ul. Energetyczna 6 lok. 7'
export const appPostalCode = '53-330'
export const appCity = 'Wrocław'
export const basicPrice = '0zł'
export const premiumPrice = '49zł'
export const appOgImage = getAsset('logo.svg')
export const appFacebookUrl =
    'https://www.facebook.com/FunClass-110452151735043'
export const appInstagramUrl = 'https://www.instagram.com/funclass_com/'
export const livekidTitle = 'Livekid'
