<template>
    <div v-if="props.selectedItem" class="sideview">
        <div class="sideview-background" @click="close" />
        <div class="sideview-card">
            <div class="sideview-header">
                <div class="sideview-title" v-text="sideviewTitle" />
                <Icon
                    class="sideview-close"
                    icon="close"
                    size="3"
                    @click="close"
                />
            </div>
            <div class="sideview-properties">
                <SupportSideviewUsers
                    v-if="props.type == SupportTabs.Users"
                    :item="props.selectedItem"
                />
                <SupportSideviewOwner
                    v-if="props.type == SupportTabs.Owners"
                    :item="props.selectedItem"
                />
                <SupportSideviewTags
                    v-if="props.type == SupportTabs.Tags"
                    :item="props.selectedItem"
                />
                <SupportSideviewArticles
                    v-if="props.type == SupportTabs.Articles"
                    :item="props.selectedItem"
                />
                <SupportSideviewProducts
                    v-if="props.type == SupportTabs.Products"
                    :item="props.selectedItem"
                />
                <SupportSideviewReservations
                    v-if="props.type == SupportTabs.Reservations"
                    :item="props.selectedItem"
                />
            </div>
            <div class="sideview-buttons">
                <button
                    v-for="button in sideviewButtons"
                    :key="button.key"
                    :class="button.class"
                    v-text="button.display"
                    @click="sideviewAction(button)"
                />
            </div>
        </div>
    </div>
    <Modal
        ref="duplicationModal"
        title="New title for duplicated product"
        :buttons="duplicationModalButtons"
    >
        <TextInput
            :field="state.duplicatedItemTitle"
            v-model="state.duplicatedItemTitle.value"
        />
    </Modal>
    <Modal ref="removeModal" :buttons="removeModalButtons">
        <div class="remove-modal-text">Are you sure you want to delete?</div>
    </Modal>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Icon from 'shared/components/utils/Icon.vue'
import SupportSideviewOwner from './Sideviews/SupportSideviewOwner.vue'
import { SupportTabs } from '@/models/support.types'
import SupportSideviewTags from './Sideviews/SupportSideviewTags.vue'
import { supportSideviewButtons } from '@/helpers/support/supportSideviews'
import { ref } from 'vue'
import Products from '@/store/modules/Products'
import { text_input } from 'shared/models/formContent.types'
import { reactive } from 'vue'
import Modal from '@/components/utils/Modal.vue'
import TextInput from 'shared/components/form/TextInput.vue'
import SupportSideviewArticles from './Sideviews/SupportSideviewArticles.vue'
import SupportSideviewProducts from './Sideviews/SupportSideviewProducts.vue'
import SupportSideviewReservations from './Sideviews/SupportSideviewReservations.vue'
import { ProductType } from '@/models/product.types'
import Classes from '@/store/modules/Classes'
import SupportSideviewUsers from './Sideviews/SupportSideviewUsers.vue'

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'duplicate'): void
    (e: 'remove'): void
}>()

const duplicationModal = ref()
const removeModal = ref()

interface Props {
    type: SupportTabs
    selectedItem: any
}
const props = defineProps<Props>()

const state = reactive({
    duplicatedItemTitle: text_input({
        placeholder: 'New title',
        value: ''
    })
})

const sideviewTitle = computed(() => {
    const titles = {
        [SupportTabs.Owners]: props.selectedItem.company_name,
        [SupportTabs.Products]: props.selectedItem.title,
        [SupportTabs.Reservations]: props.selectedItem.id,
        [SupportTabs.Tags]: props.selectedItem.id,
        [SupportTabs.Articles]: props.selectedItem.title
    }
    return titles[props.type]
})
const sideviewAction = async button => {
    switch (true) {
        case button.key.startsWith('remove'):
            removeModal.value.show()
            break
        case button.key == 'duplicate_product':
            state.duplicatedItemTitle.value = props.selectedItem.title
            duplicationModal.value.show()
            break
        case button.key.startsWith('edit'):
            await button.fn(props.selectedItem)
            break
    }
}
const sideviewButtons = computed(() => {
    return supportSideviewButtons[props.type]
})
const duplicationModalButtons = computed(() => {
    return [
        {
            display: 'Cancel',
            key: 'cancel',
            class: 'modal-secondary-button',
            action: () => {
                duplicationModal.value.close()
            }
        },
        {
            display: 'Confirm',
            key: 'yes',
            class: 'modal-primary-button',
            action: async () => {
                const duplicateProduct = async () => {
                    const newProduct = await Products.createProduct({
                        payload: {
                            ...props.selectedItem,
                            title: state.duplicatedItemTitle.value
                        }
                    })
                    await Products.editProduct({
                        payload: {
                            ...newProduct,
                            title: newProduct.title,
                            id: newProduct.id
                        }
                    })
                }

                const duplicateClass = async () => {
                    const newClass = await Classes.createClass({
                        payload: {
                            ...props.selectedItem,
                            title: state.duplicatedItemTitle.value
                        }
                    })
                    await Classes.editClass({
                        payload: {
                            ...newClass,
                            title: newClass.title,
                            id: newClass.id
                        }
                    })
                }

                switch (props.selectedItem.type) {
                    case ProductType.DayCamp:
                        await duplicateProduct()
                        break

                    case ProductType.Class:
                        await duplicateClass()
                        break
                }

                duplicationModal.value.close()
                emit('duplicate')
            }
        }
    ]
})
const removeModalButtons = computed(() => {
    return [
        {
            display: 'Cancel',
            key: 'cancel',
            class: 'modal-secondary-button',
            action: () => {
                removeModal.value.close()
            }
        },
        {
            display: 'Yes',
            key: 'yes',
            class: 'modal-primary-button',
            action: async () => {
                const button = sideviewButtons.value.find(action =>
                    action.key.startsWith('remove')
                )
                await button.fn(props.selectedItem)
                removeModal.value.close()
                emit('remove')
            }
        }
    ]
})

const close = () => {
    emit('close')
}
</script>

<style scoped lang="scss">
.sideview {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 30;
}
.sideview-background {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
}
.sideview-card {
    background-color: $white;
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
}
.sideview-header {
    border-bottom: 1px solid $gray-40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1rem 2rem;
}
.sideview-title {
    @extend .is-h6-web;
}
.sideview-close {
    cursor: pointer;
}
.sideview-properties {
    flex: 1;
    overflow-y: auto;
}

.sideview-image {
    object-fit: cover;
    width: 100px;
    height: 100px;
}
.sideview-map {
    width: 100%;
    height: 300px;
}
.sideview-map-caption {
    padding-top: 0.5rem;
    font-size: 14px;
    display: flex;
    gap: 1rem;
}
.sideview-area {
    white-space: pre-wrap;
    padding: 0.5rem;
}
.sideview-buttons {
    display: flex;
    justify-content: end;
    border-top: 1px solid $gray-40;
    padding: 1rem 1rem 1rem 2rem;
}
.sideview-primary-button {
    @extend .primary-button-admin;
    @extend .is-button-text-1-web;
    padding: 0.5rem 2rem;
}
.sideview-secondary-button {
    @extend .secondary-button-admin;
    @extend .is-button-text-1-web;
    padding: 0.5rem 2rem;
}
.remove-modal-text {
    @extend .is-h6-web;
    text-align: center;
    padding: 3rem;
}
</style>
