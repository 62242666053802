import dayjs from 'dayjs'
import { Hour } from '../../shared/models/generic.types'

type TimestampTypes = 'hour' | 'date' | 'both' | undefined

export const numberTotwoCharacterString = (value: number) => {
    return value < 10 ? `0${value}` : `${value}`
}
export const getHourFromMinutes = (minutes: number) => {
    const seconds = minutes * 60
    const hours = Math.floor(seconds / 3600)
    return minutes == 0 ? '00' : numberTotwoCharacterString(hours)
}
export const getMinutesFromMinutes = (value: number) => {
    const seconds = value * 60
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds - hours * 3600) / 60)
    return minutes == 0 ? '00' : numberTotwoCharacterString(minutes)
}
export const minutes = (minutes: number) => {
    if (minutes == undefined) {
        return '-'
    }
    const hoursDisplay = getHourFromMinutes(minutes)
    const minutesDisplay = getMinutesFromMinutes(minutes)
    return `${hoursDisplay}:${minutesDisplay}`
}
export const timestamp = (timestamp: number) => {
    if (!timestamp) {
        return {}
    }
    const hour = dayjs(timestamp).format('HH:mm')
    const date = dayjs(timestamp).format('DD.MM.YYYY')
    return { hour, date, both: `${date} - ${hour}` }
}
export const getPayloadDate = (payload: any, type?: TimestampTypes) => {
    const created_at: number = payload.created_at
    if (created_at) {
        const created_at_display: any = timestamp(created_at)
        const displayType = type ? type : 'both'
        payload.created_at_display = created_at_display[displayType]
    }
    return payload
}

export const hourOptions = () => {
    const arr = Array.from(Array(48).keys())
    return arr.map(number => {
        const value = number * 30
        return {
            display: minutes(value),
            value
        }
    })
    return []
}

export const getMinutesFromTimeString = (time: string) => {
    // format HH:MM
    const arr = time.split(':')
    return parseInt(arr[0]) * 60 + parseInt(arr[1])
}
export const getTimeStringFromMinutes = (minutes: number) => {
    const time = getHourObjectFromMinutes(minutes)
    return `${time.hours}:${time.minutes}`
}
export const getHourObjectFromMinutes = (minutes: number): Hour => {
    return {
        hours: Math.floor(minutes / 60),
        minutes: minutes % 60,
        seconds: 0
    }
}
export const getMinutesFromHourObject = (hourObject: Hour): number => {
    const { hours, minutes } = hourObject
    return hours * 60 + minutes
}
