import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators'
import store from '@/store'
import {
    ReservationGatewayID,
    ReservationID,
    ReservationInProgress,
    ReservationModel,
    ReservationRequestModel,
    ReservationWithGateways
} from '@/models/reservation.types'
import { RequestModel } from '@/models/api.types'
import api from '@/api'
import { addEntity, removeEntity, updateEntity } from '@/helpers/store'
import { ClassModel } from '@/models/class.types'
import { ProductModel } from '@/models/product.types'
import { KidModel } from '@/models/kids.types'

const base_url = 'v1/reservations'
const name = 'reservations'
@Module({
    name,
    dynamic: true,
    store
})
class Reservations extends VuexModule {
    reservations: ReservationModel[] = []
    reservationInProgress: ReservationInProgress = {
        product: null,
        ticket: {
            kids: [],
            type: null,
            dates: []
        },
        reservation: null
    }

    @Mutation
    public CLEAR_STATE() {
        this.reservations = []
    }
    @Mutation
    SET_RESERVATIONS(reservations: ReservationModel[]) {
        this.reservations = reservations
    }
    @Mutation
    ADD_RESERVATION(reservation: ReservationModel) {
        addEntity(reservation, this.reservations)
    }
    @Mutation
    SET_RESERVATION_IN_PROGRESS_PRODUCT(product: ProductModel | ClassModel) {
        this.reservationInProgress.product = product
    }
    @Mutation
    SET_RESERVATION_IN_PROGRESS_TICKET(ticket: any) {
        this.reservationInProgress.ticket = ticket
    }
    @Mutation
    EDIT_RESERVATION_IN_PROGRESS_TICKET_KID(kid: KidModel) {
        updateEntity(kid, this.reservationInProgress.ticket.kids)
    }
    @Mutation
    SET_RESERVATION_IN_PROGRESS_RESERVATION(
        reservation: ReservationWithGateways
    ) {
        this.reservationInProgress.reservation = reservation
    }
    @Action
    public async getReservations({ config = {} }: RequestModel = {}): Promise<
        ReservationModel[]
    > {
        const reservations = await api.get(`${base_url}/list`, config)
        this.SET_RESERVATIONS(reservations)
        return reservations
    }
    @Action
    public async getReservationById({
        config = {},
        payload
    }: RequestModel<{ id: ReservationID }> = {}): Promise<ReservationModel> {
        return api.get(`${base_url}/list/${payload.id}`, config)
    }
    @Action
    public async cancelReservationById({
        config = {},
        payload
    }: RequestModel<{ id: ReservationID }> = {}): Promise<ReservationModel> {
        return api.get(`${base_url}/cancel/${payload.id}`, config)
    }
    @Action
    public async createReservation({
        config = {},
        payload
    }: RequestModel<ReservationRequestModel> = {}): Promise<ReservationWithGateways> {
        const reservation = await api.post(
            `${base_url}/create`,
            payload,
            config
        )
        this.ADD_RESERVATION(reservation)
        this.SET_RESERVATION_IN_PROGRESS_RESERVATION(reservation)
        return reservation
    }
    @Action
    public async getReservationGateways({
        config = {},
        payload
    }: RequestModel<{
        id: ReservationID
    }> = {}): Promise<ReservationWithGateways> {
        const reservation = await api.post(
            `${base_url}/list-gateways/${payload.id}`,
            payload,
            config
        )
        return reservation
    }
    @Action
    public async payReservation({
        config = {},
        payload
    }: RequestModel<{
        id: ReservationID
        gateway_id: ReservationGatewayID
    }> = {}): Promise<string> {
        return api.post(`${base_url}/pay/${payload.id}`, payload, config)
    }
    get listReservations() {
        return this.reservations
    }

    get currentReservationInProgress() {
        return this.reservationInProgress
    }
}

export default getModule(Reservations)
