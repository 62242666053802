import store from '@/store'
import { Module, VuexModule, Action, getModule } from 'vuex-module-decorators'
import api from '@/api'
import {
    channels,
    DayCampReservationMessageModel,
    getDayCampMessage,
    getOwnerContactFormMessage,
    OwnerContactFormMessageModel
} from '@/helpers/slack'
import { RequestModel } from '@/models/api.types'

const name = 'slack'
const base_url = 'v1/tools/slack'
@Module({
    name,
    dynamic: true,
    store
})
class Slack extends VuexModule {
    @Action
    public async sendDayCampReservation({
        config = { base_url: 'lk_api', disableAuthorization: true },
        payload
    }: RequestModel<DayCampReservationMessageModel> = {}) {
        const message = getDayCampMessage(payload)
        const data = { ...channels.funclass_parent, message }
        return api.post(base_url, data, config)
    }

    @Action
    public async sendOwnerContactForm({
        config = { base_url: 'lk_api', disableAuthorization: true },
        payload
    }: RequestModel<OwnerContactFormMessageModel> = {}) {
        const message = getOwnerContactFormMessage(payload)
        const data = { ...channels.funclass_parent, message }
        return api.post(base_url, data, config)
    }
}
export default getModule(Slack)
