<template>
    <div class="owner-schedule">
        <div v-for="item in sortedItems" :key="item.key" class="schedule-item">
            <div class="day-container">
                <input
                    class="day-checkbox"
                    type="checkbox"
                    :id="item.key"
                    :checked="item.is_open"
                    v-model="item.is_open"
                    @input="e => updateCheckbox(item.key, e)"
                />
                <label class="day-name" :for="item.key" v-text="item.key" />
            </div>
            <div class="hours-container">
                <div
                    class="hours-label"
                    :class="{ disabled: !item.is_open }"
                    v-text="`Opening hour`"
                />
                <TimeInput
                    :class="{ disabled: !item.is_open }"
                    class="hours-input"
                    :field="
                        time_input({
                            key: `${item.key}.open_at`,
                            value: item.open_at
                        })
                    "
                    v-model="item.open_at"
                    @update="update"
                />
            </div>
            <div class="hours-container">
                <div
                    class="hours-label"
                    :class="{ disabled: !item.is_open }"
                    v-text="`Closing hour`"
                />
                <TimeInput
                    :class="{ disabled: !item.is_open }"
                    class="hours-input"
                    :field="
                        time_input({
                            key: `${item.key}.close_at`,
                            value: item.close_at
                        })
                    "
                    v-model="item.close_at"
                    @update="update"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { OwnerScheduleFieldModel } from '@/models/formContent.types'
import { reactive, computed } from 'vue'
import TimeInput from 'shared/components/form/TimeInput.vue'
import { time_input } from 'shared/models/formContent.types'
import { OwnerDaysSchedule } from '@/models/owner.types'

const emit = defineEmits<{
    (e: 'update', value: OwnerDaysSchedule): void
    (e: 'update:modelValue', value: OwnerDaysSchedule): void
}>()

interface Props {
    field: OwnerScheduleFieldModel
    modelValue: OwnerDaysSchedule
}
const props = defineProps<Props>()

const state = reactive({
    items: Object.entries(props.field.value).map(([key, value]) => ({
        key,
        ...value
    }))
})
const sortedItems = computed(() => {
    return state.items.sort(
        (a, b) => getSortPriority(a.key) - getSortPriority(b.key)
    )
})
const getSortPriority = (key: string) => {
    const priority = {
        monday: 0,
        tuesday: 1,
        wednesday: 2,
        thursday: 3,
        friday: 4,
        saturday: 5,
        sunday: 6
    }
    return priority[key]
}
const getItemsObject = () => {
    return Object.fromEntries(
        Object.values(state.items).map(({ key, ...values }) => [
            key,
            { ...values }
        ])
    )
}
const updateCheckbox = (key, event) => {
    const value = getItemsObject()
    value[key].is_open = event.target.checked
    emit('update', value)
    emit('update:modelValue', value)
}
const update = v => {
    const value = getItemsObject()
    const keys = v.key.split('.')
    value[keys[0]][keys[1]] = v.value
    emit('update', value)
    emit('update:modelValue', value)
}
</script>

<style scoped lang="scss">
.owner-schedule {
    margin-left: 0.5rem;
}
.schedule-item {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}
.day-container {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    width: 150px;
    margin-top: 1rem;
}
.day-checkbox {
    @extend .primary-checkbox;
}
.day-name {
    text-transform: capitalize;
    cursor: pointer;
}
.hours-label {
    @extend .is-body-2-web;
    &.disabled {
        color: $gray-60;
    }
}
.hours-input {
    width: 200px;
    &.disabled {
        color: $gray-60;
    }
}
</style>
