<template>
    <div
        :class="{
            TextInput: !props.overrideClass,
            [props.overrideClass]: props.overrideClass
        }"
    >
        <div
            v-if="props.field.label"
            :class="
                props.overrideClass
                    ? [`${props.overrideClass}--label`]
                    : 'TextInput--label'
            "
            v-text="props.field.label"
        />
        <div style="position: relative">
            <div
                v-if="props.field.iconLeft"
                :class="
                    props.overrideClass
                        ? [`${props.overrideClass}--icons`]
                        : 'TextInput--icons'
                "
                class="is-left"
            >
                <span :class="['icon', `is-${props.field.size}`]">
                    <i class="material-icons-round" style="user-select: none">
                        {{ props.field.iconLeft }}
                    </i>
                </span>
            </div>
            <div
                v-if="props.field.iconRight"
                :class="
                    props.overrideClass
                        ? [`${props.overrideClass}--icons`]
                        : 'TextInput--icons'
                "
                class="is-right"
            >
                <span :class="['icon', `is-${props.field.size}`]">
                    <i class="material-icons-round" style="user-select: none">
                        {{ props.field.iconRight }}
                    </i>
                </span>
            </div>
            <div
                v-if="displayFloatingLabel"
                :class="{
                    'TextInput--floating-label': !props.overrideClass,
                    [`${props.overrideClass}--floating-label`]:
                        props.overrideClass,
                    'left-padding-normal': !props.field.iconLeft,
                    'right-padding-normal': !props.field.iconRight
                }"
                v-text="props.field.placeholder"
            />
            <input
                :type="fieldType"
                :name="`text-input-${props.field.key}`"
                :placeholder="
                    displayFloatingLabel ? '' : props.field.placeholder
                "
                :autocomplete="props.field.autocomplete"
                :value="props.modelValue"
                :class="{
                    'is-danger': props.error,
                    '-disabled-input': props.field.disabled,
                    'TextInput--input': !props.overrideClass,
                    [`${props.overrideClass}--input`]: props.overrideClass,
                    'left-padding-normal': !props.field.iconLeft,
                    'right-padding-normal': !props.field.iconRight
                }"
                :style="props.innerStyle"
                :disabled="props.field.disabled"
                :readonly="props.field.readonly"
                class="input is-shadowless"
                @input.prevent="input"
                @focus="onFocusChange"
                @blur="onFocusChange"
                @keydown.enter.prevent="onEnter"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { TextFieldModel } from '../../models/formContent.types'
import { computed, reactive } from 'vue'

const emit = defineEmits<{
    (e: 'enter'): void
    (e: 'submit'): void
    (e: 'blur'): void
    (e: 'input', value: string | number): void
    (e: 'debouncedInput', value: string | number): void
    (e: 'update:modelValue', value: string | number): void
    (e: 'update:isFocused', value: boolean): void
}>()

interface Props {
    innerStyle: string
    error: boolean
    passwordVisible: boolean
    field: TextFieldModel
    overrideClass: string
    floatingLabel: boolean
    isFocused: boolean
    modelValue: string | number
}
const props = withDefaults(defineProps<Props>(), {
    innerStyle: '',
    error: false,
    passwordVisible: false,
    overrideClass: '',
    floatingLabel: false,
    isFocused: false,
    modelValue: ''
})

const state = reactive({
    changesMade: false,
    timeout: null
})

const displayFloatingLabel = computed(() => {
    return (
        (props.floatingLabel && props.isFocused) ||
        (props.floatingLabel && props.field.value.toString().length > 0)
    )
})

const fieldType = computed(() => {
    const typesScopeMap = {
        password: 'password',
        price: 'number',
        int: 'number',
        float: 'number',
        string: 'text',
        email: 'text'
    }
    const type = typesScopeMap[props.field.scope] || 'text'
    if (type == 'password') return props.passwordVisible ? 'text' : 'password'

    return type
})
const parseValue = (value: string) => {
    switch (props.field.scope) {
        case 'email':
            return value.trim().toLowerCase()
        case 'int':
            return parseInt(value)
        case 'float':
            return parseFloat(value)
        case 'price':
            return value
        default:
            return value.trim()
    }
}

const input = (event: any): void => {
    const { value } = event.target
    state.changesMade = true
    clearTimeout(state.timeout)

    const parsedValue = parseValue(value)
    state.timeout = setTimeout(() => emit('debouncedInput', parsedValue), 1000)
    emit('update:modelValue', parsedValue)
    emit('input', parsedValue)
}
const onFocusChange = ({ type }: any) => {
    if (type == 'blur') onSubmit()
    emit('update:isFocused', type == 'focus')
}
const onEnter = () => {
    onSubmit()
    emit('enter')
}
const onSubmit = () => {
    if (!state.changesMade) return emit('blur')
    state.changesMade = false
    emit('submit')
}
</script>

<style scoped lang="scss">
.left-padding-normal {
    padding-left: 1rem;
}
.right-padding-normal {
    padding-right: 1rem;
}
</style>
