import { t } from '@/i18n'
import {
    ProductFaqModel,
    ProductModel,
    reported_to_board_of_education
} from '@/models/product.types'
import {
    getProductDateRange,
    getProductHoursRange,
    getProductRecruitmentEnd,
    getProductAgeRangeDisplay
} from '@/helpers/product'
export const daycampTexts = {
    back: t('RETURN'),
    showMore: t('SHOW_MORE'),
    showMoreDays: t('SHOW_FOLLOWING_DAYS'),
    dayPlan: t('DAYCAMP_PLAN'),
    foodPlan: t('ALIMENTATION'),
    location: t('LOCATION_AND_SPACE'),
    FAQ: t('FREQUENTLY_ASKED_QUESTIONS'),
    organizer: t('ORGANIZER_AND_STAFF'),
    otherCamps: t('OTHER_DAYCAMPS_OF_ORGANIZER'),
    organizerVerified: t('ORGANIZER_VERIFIED'),
    verifiedTitle: t('DAYCAMPS_AND_ORGANIZATORS_ARE_VERIFIED'),
    verifiedTitleClasses: t('CLASSES_AND_ORGANIZER_ARE_VERIFIED'),
    verifiedDescription: t('WE_CARE_ABOUT_PLACES_SAFETY'),
    reportedTag: t('HAS_BEEN_REPORTED_TO_BOARD_OF_EDUCATION'),
    reportedTitle: t('DAYCAMP_HAS_BEEN_REPORTED_TO_BOARD_OF_EDUCATION'),
    reportedDescription: t('YOUR_CHILD_SAFETY_IS_ABSOLUTE_PRIORITY_FOR_US'),
    seeMore: t('SEE_MORE'),
    getToKnowUs: t('GET_TO_KNOW_US_BETTER'),
    learnMore: t('LEARN_MORE'),
    reserveDaycamp: t('RESERVE_DAYCAMP'),
    reserveClasses: t('RESERVE_CLASSES'),
    daycamp: t('DAYCAMP'),
    daycampSingleDay: t('DAYCAMP_SINGLE_DAY'),
    singleDayCanChooseMore: t('PRICE_FOR_ONE_DAY_YOU_CAN_CHOOSE_MORE'),
    wantReserve: t('DO_YOU_WANT_TO_RESERVE_THIS_DAYCAMP'),
    giveEmail: t('TYPE_YOUR_EMAIL_WE_WILL_SEND_INFO'),
    email: t('ADDRESS_EMAIL_*'),
    send: t('SENT'),
    clickingSend: `${t('BY_CLICKING_SEND_YOU_CONFIRM_REGUALTIONS')} `,
    verified: t('HAS_BEEN_VERIFIED'),
    description: t('DAYCAMP_DESCRIPTION'),
    checkTicket: t('CHECK_TICKETS'),
    collapse: t('COLLAPSE'),
    chooseReservationType: t('CHOOSE_RESERVATION_TYPE')
}
import { computed } from 'vue'
import Tags from '@/store/modules/Tags'
import { TagModel } from '@/models/tag.types'
import { BreadcrumbModel } from './breadcrumbs'
import router from '@/router'
import { OwnerModel } from '@/models/owner.types'

export const reportedToBoardTag = computed(() => {
    return Tags.listTags.find(
        (tag: TagModel) => tag.id == reported_to_board_of_education
    )
})

export const getDayCampCategoryTags = (product: ProductModel) => {
    return Tags.listTags.filter((tag: TagModel) =>
        product.category_tags.includes(tag.id)
    )
}

export const getProductOtherTags = (product: ProductModel | OwnerModel) => {
    const tags = product.category_tags || product.other_tags
    return Tags.listTags.filter((tag: TagModel) => tags.includes(tag.id))
}

export const prepareFAQ = (faq: ProductFaqModel[]) => {
    return faq.map((item: ProductFaqModel) => {
        const { question } = item
        return {
            ...item,
            question: question.substring(4, question.length - 1)
        }
    })
}
export const prepareFAQQuestion = (question: string) => {
    const startIndex = question.indexOf('“')
    const lastIndex = question.lastIndexOf('”')
    return question.substring(startIndex + 1, lastIndex)
}

export const getDayCampDateDisplay = (product: ProductModel) => {
    return `${t('DEADLINE')}: ${getProductDateRange(product)}`
}
export const getDayCampHoursDisplay = (product: ProductModel) => {
    return `${t('HOUR_ABBR')} ${getProductHoursRange(product)}`
}
export const getDayCampRecruitmentEndDisplay = (product: ProductModel) => {
    return `${t('END_OF_REGISTRATION')}: ${getProductRecruitmentEnd(product)}`
}
export const getDayCampAgeDisplay = (product: ProductModel) => {
    const range = getProductAgeRangeDisplay(product)
    return `${t('AGE')}: ${range}`
}
export const getDayCampProfileBreadcrumbs = (
    product: ProductModel
): BreadcrumbModel[] => {
    const setAction = route => {
        if (route.name == 'daycamp_profile')
            return router.push({
                name: 'daycamp_listing_city',
                params: { city: product.city }
            })
        if (route.name == 'class_profile')
            return router.push({
                name: 'class_listing_city',
                params: { city: product.city }
            })
        return router.push({ path: route.path })
    }
    return [
        ...router.currentRoute.value.matched
            .map(r => {
                return {
                    display:
                        r.name == 'daycamp_profile'
                            ? `${t(r.meta.display as string)} ${product.city}`
                            : t(r.meta.display as string),
                    action: () => {
                        setAction(r)
                    }
                }
            })
            .filter((item: BreadcrumbModel) => item.display),
        {
            display: product.title,
            action: () => {}
        }
    ]
}
export const isOwner = computed(() => {
    return router.currentRoute.value.name == 'owner_profile'
})
