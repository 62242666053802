<template>
    <div class="upload-products">
        <div class="upload-title" v-text="`Upload day camps`" />
        <SupportUploader :uploadFunction="uploadProducts" />
    </div>
</template>

<script setup lang="ts">
import Products from '@/store/modules/Products'
import SupportUploader from './components/SupportUploader.vue'

const uploadProducts = async (file: File) => {
    await Products.uploadProducts({
        payload: { file }
    })
}
</script>

<style scoped lang="scss">
.upload-products {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.upload-title {
    @extend .is-h3-web;
    margin-bottom: 5rem;
}
</style>
