<template>
    <!-- DOCS: https://vue3datepicker.com/api/props/ -->
    <Datepicker
        class="DatePicker"
        inputClassName="DatePicker--input"
        menuClassName="DatePicker--menu"
        calendarClassName="DatePicker--calendar"
        calendarCellClassName="DatePicker--cell"
        :allowedDates="props.field.allowedDates"
        :autoApply="props.field.autoApply"
        :cancelText="props.field.cancelText"
        :enableTimePicker="props.field.enableTimePicker"
        :format="props.field.format"
        :previewFormat="props.field.previewFormat"
        :inline="props.field.inline"
        :locale="props.field.locale"
        :modeHeight="props.field.modeHeight"
        :multiDates="props.field.multiDates"
        :placeholder="props.field.placeholder"
        :range="props.field.range"
        :selectText="props.field.selectText"
        :startDate="props.field.startDate"
        :timePicker="props.field.timePicker"
        :transitions="props.field.transitions"
        :value="props.field.value"
        @update:modelValue="updateDate"
    />
</template>

<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { DatePickerFieldModel } from '../../models/formContent.types'
import { dateWithFormat } from '../../helpers/date'

const emit = defineEmits<{
    (e: 'update', value: DatePickerFieldModel): void
}>()

interface Props {
    field: DatePickerFieldModel
}
const props = defineProps<Props>()

const updateDate = (value: Date) => {
    const parsedValue = value
        ? Array.isArray(value)
            ? value.map(d => dateWithFormat({ date: d, format: 'YYYY-MM-DD' }))
            : dateWithFormat({ date: value, format: 'YYYY-MM-DD' })
        : ''

    emit('update', { ...props.field, value: parsedValue })
}
</script>
