import {
    DateYYYYMMDD,
    DayOfWeek,
    Price,
    Timestamp
} from 'shared/models/generic.types'
import { ProductCompactModel, ProductID, ProductModel } from './product.types'

export enum ClassPriceType {
    Trial = 'trial',
    Advance = 'advance',
    Semester = 'semester'
}

export type ClassPriceID = string
export type ClassScheduleID = string

export interface ClassPrice {
    created_at: Timestamp
    id: ClassPriceID
    product_id: ProductID
    value: Price
    type: ClassPriceType
    data: any
}
export interface ClassWeekDay {
    date?: DateYYYYMMDD
    start_time: number
    end_time: number
    week_day: DayOfWeek
}
export interface ClassSchedule {
    created_at: Timestamp
    id: ClassScheduleID
    product_id: ProductID
    start_date: DateYYYYMMDD
    end_date: DateYYYYMMDD
    week_days: ClassWeekDay[]
    exceptions: ClassWeekDay[]
}

export interface ClassModel extends ProductModel {
    prices: ClassPrice[]
    schedule: ClassSchedule
    base_free_slots: number
}

export interface ClassCompactModel extends ProductCompactModel {
    schedule: ClassSchedule
    prices: ClassPrice[]
}

export type ClassRequestModel = Omit<
    ClassModel,
    'id' | 'created_id' | 'slug' | 'dates'
>
