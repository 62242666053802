import router from '@/router'

export const goToRegulations = () => {
    const route = router.resolve({ name: 'regulations' })
    window.open(route.href, '_blank')
}

export const goToPrivacyPolicy = () => {
    const route = router.resolve({ name: 'privacy_policy' })
    window.open(route.href, '_blank')
}
