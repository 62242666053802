<template>
    <div class="sideview-owner">
        <ElTabs v-model="state.activeTab" class="sideview-tabs">
            <ElTabPane
                label="Information & profile"
                name="info"
                class="sideview-owner-info"
            >
                <SupportSideviewBoolean
                    label="Verified"
                    :value="props.item.verified"
                />
                <SupportSideviewText
                    v-for="text in texts"
                    :key="text.label"
                    :label="text.label"
                    :value="text.value"
                />
                <SupportSideviewCreatedAt :created_at="props.item.created_at" />
                <hr />
                <SupportSideviewStaff
                    :staff_description="props.item.staff_description"
                    :staff="props.item.staff"
                />
            </ElTabPane>
            <ElTabPane
                class="sideview-owner-info"
                label="Products"
                name="products"
            >
                <ElTable :data="productsTable" fit>
                    <ElTableColumn
                        v-for="column in tableColumns"
                        :key="column.key"
                        :type="column.type"
                        :prop="column.prop"
                        :label="column.label"
                        :width="column.width"
                    />
                </ElTable>
            </ElTabPane>
        </ElTabs>
    </div>
</template>

<script setup lang="ts">
import { OwnerModel } from '@/models/owner.types'
import SupportSideviewText from '../SupportSideviewText.vue'
import SupportSideviewBoolean from '../SupportSideviewBoolean.vue'
import SupportSideviewStaff from '../SupportSideviewStaff.vue'
import { computed, onMounted, reactive } from 'vue'
import { ElTabs, ElTabPane, ElTable, ElTableColumn } from 'element-plus'
import Products from '@/store/modules/Products'
import { ProductModel, ProductType } from '@/models/product.types'
import 'element-plus/es/components/table/style/css'
import { getProductDateRange } from '@/helpers/product'
import { getClassDateRange } from '@/helpers/classProfile'
import { ClassModel } from '@/models/class.types'
import SupportSideviewCreatedAt from '../SupportSideviewCreatedAt.vue'

interface Props {
    item: OwnerModel
}
const props = defineProps<Props>()

const state = reactive({
    activeTab: 'info'
})

const productsTable = computed(() => {
    const products = Products.listProducts.filter(
        (product: ProductModel) => product.owner_id == props.item.id
    )
    return products.map((product: ProductModel) => ({
        type: product.type,
        name: product.title,
        date: getDateRange(product),
        status: product.recrutation_status
    }))
})

const getDateRange = (product: ProductModel) => {
    switch (product.type) {
        case ProductType.Class:
            return getClassDateRange(product as ClassModel)
        case ProductType.DayCamp:
            return getProductDateRange(product)
    }
}
const tableColumns = [
    {
        key: 'index',
        type: 'index',
        width: '30'
    },
    {
        key: 'type',
        prop: 'type',
        label: 'Type',
        width: '100'
    },
    {
        key: 'name',
        prop: 'name',
        label: 'Name',
        width: '300'
    },
    {
        key: 'date',
        prop: 'date',
        label: 'Date'
    },
    {
        key: 'status',
        prop: 'status',
        label: 'Status'
    }
]

const texts = computed(() => {
    return [
        {
            label: 'Company name',
            value: props.item.company_name
        },
        {
            label: 'Address e-mail',
            value: props.item.email
        },
        {
            label: 'Phone number',
            value: props.item.phone
        },
        {
            label: 'Business owner',
            value: `${props.item.name} ${props.item.surname}`
        },
        {
            label: 'Country',
            value: props.item.country
        },
        {
            label: 'City',
            value: props.item.address_city
        },
        {
            label: 'Post code',
            value: props.item.address_post_code
        },
        {
            label: 'Street',
            value: props.item.address_street
        },
        {
            label: 'Building number',
            value: props.item.address_street_number
        },
        {
            label: 'NIP',
            value: props.item.nip
        },
        {
            label: 'Bank account number (NRB)',
            value: props.item.nrb
        },
        {
            label: 'Commission',
            value: `${props.item.commission}%`
        },
        {
            label: 'Description',
            value: props.item.description
        },
        {
            label: 'ID',
            value: props.item.id
        }
    ]
})

onMounted(async () => {
    await Products.getProducts()
})
</script>

<style scoped lang="scss">
.sideview-owner {
    height: 100%;
}
.sideview-owner-info {
    padding: 1rem 2rem;
}
.sideview-tabs {
    @include sideview-tabs;
}
</style>
