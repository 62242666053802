import query from './query'
import { ReqConfigModel, SortModel } from './network.types'

/**
 * @param pagination
 * @returns Pagination object prepared for request params
 */
export const getPagination = (pagination: { limit: number; page: number }) => {
    if (!pagination) return {}
    const { limit, page } = pagination
    const offset = limit * (page - 1)
    return { offset, limit }
}

// NOTE: QUERY FILTER
/**
 * Gets filter query
 *
 * @param filter Array of objects ```{ key: value }``` to filter by
 * @returns Object containing filter query ```{ query: string }```
 */
export const getFilter = (filter: any[]) => query(filter)

// NOTE: QUERY SORT
/**
 * @param sort
 * @returns Sort object prepared for request params
 */
export const getSort = (sort: SortModel) => {
    if (!sort) return {}
    return { sort: sort.keys, reverse: sort.reverse || false }
}
/**
 * Extracts pagination, filter and sort objects from request config
 * and adds their properties to params.
 *
 * @param config
 * @returns Config object with params prepared for request
 */
export const getRequestConfig = (config: ReqConfigModel) => {
    const { filter, params, sort, pagination } = config || {}
    const paginationQuery = getPagination(pagination)
    const filterQuery = getFilter(filter)
    const sortQuery = getSort(sort)
    return {
        ...config,
        params: { ...params, ...filterQuery, ...sortQuery, ...paginationQuery }
    }
}
