// import { FieldModels } from 'shared/helpers/formContent.types'
// TODO: Replace any with FieldModel
export interface ErrorModel {
    message: string
    name: string
    section?: any
}

class Errors {
    errors: ErrorModel[] = []
    constructor() {
        this.errors = []
    }
    /**
     * Adds new error, if it is not already included in 'this.errors' array
     *
     * @param name Error's name
     * @param message Error's message
     * @returns
     */
    set(name: string, message: string = '') {
        if (this.has(name)) return
        this.errors.push({ name, message })
    }
    /**
     * Checks whether error is included in 'this.errors' array
     *
     * @param name Error's name
     * @param section FormCreator's section key
     * @returns True - if error with specified name and section is included
     */
    // TODO: section should be a string
    has(name: string, section?: any): boolean {
        return this.errors.some(
            error =>
                error.name == name &&
                (!error.section || error.section == section)
        )
    }
    /**
     * Removes error from 'this.errors' array
     *
     * @param name Error's name
     */
    clear(name: string): void {
        const index = this.errors.findIndex(error => error.name == name)
        if (index != -1) this.errors.splice(index, 1)
    }
    /**
     * Gets error's message
     *
     * @param name Error's name
     * @returns Error's message or empty string if error is undefined
     */
    getMessage(name: string): string {
        return this.errors.find(error => error.name == name)?.message || ''
    }
    /**
     * Removes all errors from 'this.errors'
     */
    reset(): void {
        this.errors.length = 0
    }
    // TODO: unused
    check(): void {
        if (this.errors.length > 0) throw new Error()
    }
    /**
     * Determines whether there are any errors
     */
    get exist(): boolean {
        return this.errors.length > 0
    }
}

export default Errors
/**
 * Extracts data and status property from try/catch exception's response
 *
 * @param error Try/catch exception
 * @returns Normalized network error
 */
export const normalizeNetworkError = (error: any) => {
    const { data, status } = error?.response
    return { status, data: data.error || {} }
}
