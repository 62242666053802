import { FCReqConfigModel } from '@/models/api.types'
import gateway from 'shared/network/gateway'
import { ErrorFN, MethodType } from 'shared/network/gateway.types'
import { getRequestConfig } from 'shared/network/network'
import { provideEnvConfig } from '@/helpers/env'
import { provideCurrentJWT } from '@/helpers/accounts'
import toast from '@/helpers/toast'
import { provideBaseUrls } from 'shared/helpers/urlProvider'

export const getHeaders = (
    jwt: string,
    headers: any,
    disableAuthorization?: boolean
) => {
    return disableAuthorization === true
        ? headers
        : {
              ...headers,
              Authorization: `Bearer ${jwt || provideCurrentJWT()}`
          }
}
export const getApiBaseUrl = (url_key: 'fc_api' | 'lk_api' = 'fc_api') => {
    const api_env = provideEnvConfig().api_key
    return provideBaseUrls(api_env)[url_key].replace('{region}', 'pl')
}

export const getFCRequestConfig = ({
    config
}: {
    config: FCReqConfigModel
}): any => {
    return getRequestConfig(config)
}

const errorFN: ErrorFN<FCReqConfigModel> = async (error, config, callback) => {
    const response = error?.response?.data?.error
    if (response.schema && config.errors) {
        Object.entries(response.schema).forEach(([key, value]) => {
            config.errors?.set(key, value[0])
        })
        toast.error('Bad schema')
    }
    throw error
}

const request = async (
    method: MethodType,
    url: string,
    data?: any,
    config: FCReqConfigModel = {}
) => {
    const requestConfig = getFCRequestConfig({ config })
    const configParams = requestConfig.params || {}
    return gateway({
        method,
        url,
        payload: data,
        params: { ...config?.params, ...configParams },
        headers: getHeaders(
            config?.jwt,
            config?.headers,
            config?.disableAuthorization
        ),
        base_url: getApiBaseUrl(config.base_url),
        config: requestConfig,
        errorFN
    })
}

const parseResponse = res => res.data ?? res

export default {
    get: async (url: string, config?: FCReqConfigModel): Promise<any> =>
        request('get', url, {}, config).then(parseResponse),

    post: async (
        url: string,
        payload: unknown,
        config?: FCReqConfigModel
    ): Promise<any> =>
        request('post', url, payload, config).then(parseResponse),

    put: async (
        url: string,
        payload: unknown,
        config?: FCReqConfigModel
    ): Promise<any> => request('put', url, payload, config).then(parseResponse),

    patch: async (
        url: string,
        payload: unknown,
        config?: FCReqConfigModel
    ): Promise<any> =>
        request('patch', url, payload, config).then(parseResponse),

    delete: async (url: string, config?: FCReqConfigModel): Promise<any> =>
        request('delete', url, {}, config).then(parseResponse)
}
