import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators'
import store, { shouldPreserve } from '@/store'
import { RequestModel } from '@/models/api.types'
import api from '@/api'
import {
    OwnerID,
    OwnerModel,
    OwnerPipedriveModel,
    OwnerRequestModel
} from '@/models/owner.types'
import { addEntity, removeEntity, updateEntity } from '@/helpers/store'
const base_url = 'v1/owners'

const name = 'owners'
const keys = ['owners']
@Module({
    name,
    dynamic: true,
    preserveState: shouldPreserve(name, keys),
    store
})
class Owners extends VuexModule {
    owner: OwnerModel
    owners: OwnerModel[] = []

    @Mutation
    public CLEAR_STATE() {
        this.owners = []
    }
    @Mutation
    SET_CURRENT_OWNER(owner: OwnerModel) {
        this.owner = owner
    }
    @Mutation
    SET_OWNERS(owners: OwnerModel[]) {
        this.owners = owners
    }
    @Mutation
    ADD_OWNER(owner: OwnerModel) {
        addEntity(owner, this.owners)
    }
    @Mutation
    EDIT_OWNER(owner: OwnerModel) {
        updateEntity(owner, this.owners)
    }
    @Mutation
    REMOVE_OWNER(owner_id: OwnerModel['id']) {
        removeEntity(owner_id, this.owners)
    }

    @Action
    public async getOwners({ config = {} }: RequestModel = {}): Promise<
        OwnerModel[]
    > {
        const owners = await api.get(`${base_url}/list`, config)
        this.SET_OWNERS(owners)
        return owners
    }
    @Action
    public async getOwnerById({
        config = {},
        payload
    }: RequestModel<{ id: OwnerID }> = {}): Promise<OwnerModel> {
        const owner = await api.get(`${base_url}/list/${payload.id}`, config)
        this.EDIT_OWNER(owner)
        return owner
    }
    @Action
    public async getOwnerBySlug({
        config = {},
        payload
    }: RequestModel<{ slug: string }> = {}): Promise<OwnerModel> {
        const owner = await api.get(`${base_url}/slug/${payload.slug}`, config)
        this.EDIT_OWNER(owner)
        return owner
    }
    @Action
    public async createOwner({
        config = {},
        payload
    }: RequestModel<OwnerRequestModel> = {}): Promise<OwnerModel> {
        const owner = await api.post(`${base_url}/list`, payload, config)
        this.ADD_OWNER(owner)
        return owner
    }
    @Action
    public async editOwner({
        config = {},
        payload,
        id
    }: RequestModel<OwnerRequestModel> = {}): Promise<OwnerModel> {
        const owner = await api.patch(`${base_url}/list/${id}`, payload, config)
        this.EDIT_OWNER(owner)
        return owner
    }
    @Action
    public async deleteOwner({
        config = {},
        payload
    }: RequestModel<{ id: string }> = {}): Promise<OwnerModel> {
        const owner = await api.delete(`${base_url}/list/${payload.id}`, config)
        this.REMOVE_OWNER(owner.id)
        return owner
    }

    @Action
    public async addOwnerContactInfo({
        config = {},
        payload
    }: RequestModel<OwnerPipedriveModel> = {}) {
        return api.post(`${base_url}/pipedrive/create-person`, payload, config)
    }

    @Action
    public async uploadOwners({
        config = { headers: { 'Content-Type': 'multipart/form-data' } },
        payload
    }: RequestModel<{ file: File }> = {}) {
        const { file } = payload
        const formData = new FormData()
        formData.append('file', file)
        return api.post(`${base_url}/upload/xlsx`, formData, config)
    }

    get currentOwner() {
        return this.owner
    }
    get listOwners() {
        return this.owners || []
    }
}

export default getModule(Owners)
