<template>
    <div
        class="Switch section-selector"
        :class="{ '-disabled': field.disabled }"
    >
        <label class="switch mobile">
            <input
                :checked="field.value"
                :name="`switch-${field.key}`"
                type="checkbox"
                @change="onChange"
            />
            <span class="slider round"></span>
        </label>
        <div class="option mobile" v-text="t(field.label)"></div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { t } from '@/i18n'
export default defineComponent({
    props: {
        field: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, { emit }) {
        const onChange = ({ target }: any) => {
            emit('update', { ...props.field, value: target.checked })
            emit('update:modelValue', target.checked)
        }
        return { t, onChange }
    }
})
</script>
<style lang="scss" scoped>
.section-selector {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0.4rem 1rem;
}
.option {
    margin-top: 3px;
    font-size: 18px;
    &.mobile {
        margin-left: 1rem;
        font-size: 16px;
    }
}
.switch {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 0.6rem;
    margin: 0 1rem;
    margin-bottom: -0.2rem;
    &.mobile {
        margin-left: 0rem;
    }
}
.switch input {
    opacity: 0;
    width: 0px;
    height: 0px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(233, 233, 233);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.slider:before {
    position: absolute;
    content: '';
    height: 1.2rem;
    width: 1.2rem;
    left: -4px;
    bottom: -0.32rem;
    background-color: rgb(197, 197, 197);

    -webkit-transition: 0.4s;
    transition: 0.4s ease;
}

input:checked + .slider:before {
    transform: translateX(1.2rem);
    background-color: $checked-input-switch;
}
input:checked + .slider {
    background-color: #abe5ff;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
