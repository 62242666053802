// TODO: it'll stay until we figure out how to import SCSS variables directly to Vue template 06.06.22
export const COLORS = {
    green: '#2ed573',
    blue: '#2badff',
    red: '#ff4757',
    yellow: '#ffd32a',
    purple: '#ffd32a',
    sea: '#00c5d1',
    yellowReviews: '#ffe16d',
    green20: '#f6f9f6',
    green40: '#f0f8f3',
    green60: '#e1f0e7',
    navyblue20: '#949ca6',
    navyblue40: '#29384e',
    navyblue60: '#17263c',
    gray100: '#1e1e1e',
    gray80: '#454545',
    gray70: '#777777',
    gray60: '#dfdfdf',
    gray40: '#efefef',
    gray20: '#f5f5f5',
    gray00: '#fafafa',
    white: '#ffffff',
    black: 'black'
}
