export const addScriptToHead = (
    scriptContent: string,
    attributes?: { key: string; value?: string }[]
) => {
    const scriptElement = document.createElement('script')
    scriptElement.innerHTML = scriptContent

    const head = document.getElementsByTagName('head')[0]
    attributes?.forEach(attr => {
        head.setAttribute(attr.key, attr.value)
    })
    head.append(scriptElement)
}

export const addCommentToHead = (commentContent: string) => {
    const head = document.getElementsByTagName('head')[0]
    const comment = document.createComment(commentContent)
    head.append(comment)
}

export const addNoScriptToBody = (noscriptContent: string) => {
    const noscriptElement = document.createElement('noscript')
    noscriptElement.innerHTML = noscriptContent
    document.body.append(noscriptElement)
}
