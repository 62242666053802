import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators'
import store, { shouldPreserve } from '@/store'
import {
    ProductID,
    ProductModel,
    ProductRequestModel,
    ProductSearch
} from '@/models/product.types'
import { RequestModel } from '@/models/api.types'
import api from '@/api'
import { addEntity, removeEntity, updateEntity } from '@/helpers/store'
import { ClassCompactModel } from '@/models/class.types'
const base_url = 'v1/products'
const name = 'products'
const keys = ['products']
@Module({
    name,
    dynamic: true,
    preserveState: shouldPreserve(name, keys),
    store
})
class Products extends VuexModule {
    products: ProductModel[] = []

    @Mutation
    public CLEAR_STATE() {
        this.products = []
    }
    @Mutation
    SET_PRODUCTS(products: ProductModel[]) {
        this.products = products
    }
    @Mutation
    ADD_PRODUCT(product: ProductModel) {
        addEntity(product, this.products)
    }
    @Mutation
    EDIT_PRODUCT(product: ProductModel) {
        updateEntity(product, this.products)
    }
    @Mutation
    REMOVE_PRODUCT(product_id: ProductModel['id']) {
        removeEntity(product_id, this.products)
    }
    @Action
    public async getProducts({ config }: RequestModel = {}): Promise<
        ProductModel[]
    > {
        const products = await api.get(`${base_url}/list`, config)
        this.SET_PRODUCTS(products)
        return products
    }
    @Action
    public async getProductById({
        config,
        payload
    }: RequestModel<{ id: ProductID }> = {}): Promise<ProductModel> {
        const { id } = payload
        const product = await api.get(`${base_url}/list/${id}`, config)
        this.EDIT_PRODUCT(product)
        return product
    }
    @Action
    public async getProductBySlug({
        config,
        payload
    }: RequestModel<{ slug: string }> = {}): Promise<ProductModel> {
        const { slug } = payload
        const product = await api.get(`${base_url}/slug/${slug}`, config)
        this.EDIT_PRODUCT(product)
        return product
    }
    @Action
    public async createProduct({
        config,
        payload
    }: RequestModel<ProductRequestModel> = {}): Promise<ProductModel> {
        const product = await api.post(`${base_url}/list`, payload, config)
        this.ADD_PRODUCT(product)
        return product
    }
    @Action
    public async editProduct({
        config,
        payload
    }: RequestModel<
        ProductRequestModel & { id: ProductID }
    > = {}): Promise<ProductModel> {
        const { id, ...request_payload } = payload
        const product = await api.patch(
            `${base_url}/list/${id}`,
            request_payload,
            config
        )
        this.ADD_PRODUCT(product)
        return product
    }
    @Action
    public async deleteProduct({
        config = {},
        payload
    }: RequestModel<{ id: ProductID }> = {}): Promise<{ id: ProductID }> {
        const product = await api.delete(
            `${base_url}/list/${payload.id}`,
            config
        )
        this.REMOVE_PRODUCT(product.id)
        return product
    }
    @Action
    public async uploadProducts({
        config = { headers: { 'Content-Type': 'multipart/form-data' } },
        payload
    }: RequestModel<{ file: File }> = {}) {
        const { file } = payload
        const formData = new FormData()
        formData.append('file', file)
        return api.post(`${base_url}/upload/xlsx`, formData, config)
    }
    @Action
    public async changeProductsPublishedStatus({
        config = {},
        payload
    }: RequestModel<{ ids: ProductID[]; is_published: boolean }> = {}) {
        return api.post(`${base_url}/publish`, payload, config)
    }
    @Action
    public async searchProducts({
        config = {},
        payload
    }: RequestModel<ProductSearch> = {}): Promise<ProductModel[]> {
        const products = await api.post(`${base_url}/search`, payload, config)
        this.SET_PRODUCTS(products)
        return products
    }
    @Action
    public async searchCompactProducts({
        config = {},
        payload
    }: RequestModel<ProductSearch> = {}): Promise<ClassCompactModel[]> {
        const products = await api.post(
            `${base_url}/search-compact`,
            payload,
            config
        )
        this.SET_PRODUCTS(products)
        return products
    }

    get listProducts() {
        return this.products
    }
}

export default getModule(Products)
