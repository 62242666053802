import { t } from '@/i18n'
import { ClassModel } from '@/models/class.types'
import { OwnerModel } from '@/models/owner.types'
import { ProductModel } from '@/models/product.types'
import router from '@/router'
import Owners from '@/store/modules/Owners'
import Products from '@/store/modules/Products'
import { minutes } from 'shared/helpers/time'
import { BreadcrumbModel } from './breadcrumbs'
import { PipedriveContactType, PipedriveCustomFieldKeys } from './pipedrive'
import { getProductAgeRangeObject, getProductUrl } from './product'

const anonymousCustomerName = 'Anonymous Customer'

export const ownersToSelectOptions = (owners: OwnerModel[]) =>
    owners.map(({ company_name, id }) => ({ value: id, display: company_name }))

export const ownerSelectOptions = () => ownersToSelectOptions(Owners.listOwners)

export const getCustomerPipedrivePayload = (
    email: string,
    product: ProductModel | ClassModel
) => {
    return {
        name: anonymousCustomerName,
        email,
        [PipedriveCustomFieldKeys.PRODUCT_ID]: product.id,
        [PipedriveCustomFieldKeys.PRODUCT_LINK]: getProductUrl(product),
        [PipedriveCustomFieldKeys.PRODUCT_TITLE]: product.title,
        [PipedriveCustomFieldKeys.CONTACT_TYPE]: PipedriveContactType.Customer
    }
}
export const getCustomerPipedrivePayloadForOwnerProfile = (
    email: string,
    product: OwnerModel
) => {
    return {
        name: anonymousCustomerName,
        email,
        [PipedriveCustomFieldKeys.PRODUCT_ID]: product.id,
        [PipedriveCustomFieldKeys.PRODUCT_LINK]: getOwnerUrl(product),
        [PipedriveCustomFieldKeys.PRODUCT_TITLE]: product.company_name,
        [PipedriveCustomFieldKeys.CONTACT_TYPE]: PipedriveContactType.Customer
    }
}
export const getOwnerFullAddress = (product: OwnerModel) => {
    const { address_street, address_street_number, address_city } = product
    return `${address_street} ${address_street_number}, ${address_city}`
}

export const getOwnerProducts = (owner: OwnerModel) => {
    return Products.listProducts.filter(
        (product: ProductModel) => product.owner_id == owner.id
    )
}
export const getOwnerAgeRangeObject = (owner: OwnerModel) => {
    const ranges = getOwnerProducts(owner).map(product =>
        getProductAgeRangeObject(product)
    )
    return {
        min: ranges.sort((a, b) => a.min - b.min)[0].min,
        max: ranges.sort((a, b) => a.max - b.max)[0].max
    }
}
export const getOwnerHoursDisplay = day => {
    return day.is_open
        ? `${minutes(day.open_at)}-${minutes(day.close_at)}`
        : '-'
}

export const getOwnerProfileRouterOptions = (owner: OwnerModel) => {
    return {
        name: 'owner_profile',
        params: {
            owner_slug: owner.slug
        }
    }
}
export const getOwnerUrl = (owner: OwnerModel) => {
    const route = router.resolve(getOwnerProfileRouterOptions(owner))
    return `${window.location.origin}${route.href}`
}
export const getOwnerProfileBreadcrumbs = (
    owner: OwnerModel
): BreadcrumbModel[] => {
    return [
        ...router.currentRoute.value.matched
            .map(r => {
                return {
                    display: t(r.meta.display as string),
                    action: () => {
                        router.push({ path: r.path })
                    }
                }
            })
            .filter((item: BreadcrumbModel) => item.display),
        {
            display: owner.company_name,
            action: () => {}
        }
    ]
}
