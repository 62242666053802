import Errors from '../helpers/errors'
import { default_field_values, FieldModels } from '../models/formContent.types'

export interface FormModel {
    [key: string]: FieldModels
}
interface PayloadConfig {
    form: FormModel
    errors?: Errors
    omitHidden?: boolean
    checkRequired?: boolean
}

export const setForm = (payload: unknown, form: FormModel) => {
    if (!payload || !form) return
    Object.entries(form).forEach(([key, field]) => {
        const hasNestedValue =
            field.object &&
            payload[field.object] &&
            payload[field.object][key] !== undefined
        if (payload[key] === undefined && !hasNestedValue) return field.value
        const object = form[key].object

        switch (true) {
            case field.object != undefined:
                field.value = payload[object][key]
                break
            default:
                field.value = payload[key]
        }
    })
}

const parseStringValue = (field: FieldModels) => {
    let new_value = `${field.value}`
    if (field.no_white_space) new_value = new_value.replace(/\s+/g, '')
    return new_value.trim()
}
const getFieldValue = (field: FieldModels) => {
    const { type, value } = field
    switch (type) {
        case 'number-input':
            return Number(value) || 0
        default:
            return typeof value == 'string'
                ? parseStringValue(field)
                : value ?? ''
    }
}

const shouldOmit = (field: FieldModels, omitHidden: boolean) =>
    (field.hidden && omitHidden) || field.omit

const validateField = (field: FieldModels, errors: Errors) => {
    if (field.required && field.value == default_field_values[field.type])
        errors.set(field.key, 'ERROR_FIELD_REQUIRED')
}

export const createPayload = ({
    form,
    errors,
    omitHidden = true,
    checkRequired = false
}: PayloadConfig): any => {
    if (errors) errors.errors = []
    return Object.entries(form).reduce((all, [key, field]) => {
        if (shouldOmit(field, omitHidden)) return all
        if (checkRequired) validateField(field, errors)

        if (field.object) {
            all[field.object]
                ? (all[field.object][key] = getFieldValue(field))
                : (all[field.object] = {
                      [key]: getFieldValue(field)
                  })
        } else {
            all[key] = getFieldValue(field)
        }
        return all
    }, {})
}

export const createSectionPayload = ({
    field,
    errors,
    omitHidden
}: {
    field: FieldModels
    errors: any
    omitHidden: boolean
}) => {
    const form = { [field.key]: field }
    return createPayload({ form, errors, omitHidden })
}
