<template>
    <div class="signup-email-form">
        <TextInput
            :field="state.email"
            v-model="state.email.value"
            overrideClass="TextInputRoundedSimple"
            class="signup-email"
        />
        <div
            class="signup-error"
            :style="{
                visibility: state.errorMessageVisible ? 'visible' : 'hidden'
            }"
            v-text="t('INCORRECT_EMAIL_ADDRESS')"
        />
        <button
            class="signup-button"
            :style="{ width: props.inModal ? '100%' : 'auto' }"
            v-text="daycampTexts.send"
            @click="makeReservation"
        />
        <div class="signup-caption">
            <span v-text="daycampTexts.clickingSend" />
            <b v-text="t('OF_REGULATIONS')" @click="goToRegulations" />
            <span v-text="` ${t('AND')} `" />
            <b
                v-text="`${t('OF_PRIVACY_POLICY')}.`"
                @click="goToPrivacyPolicy"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { daycampTexts } from '@/helpers/daycampProfile'
import { goToPrivacyPolicy, goToRegulations } from '@/helpers/regulations'
import { t } from '@/i18n'
import { text_input } from 'shared/models/formContent.types'
import { reactive } from 'vue'
import TextInput from 'shared/components/form/TextInput.vue'

const emit = defineEmits<{
    (e: 'sendReservationMessage', email: string): void
}>()

interface Props {
    inModal: boolean
}
const props = withDefaults(defineProps<Props>(), {
    inModal: false
})

const state = reactive({
    email: text_input({
        placeholder: daycampTexts.email,
        label: daycampTexts.email,
        value: ''
    }),
    errorMessageVisible: false
})

const makeReservation = () => {
    if ((state.email.value as string).includes('@')) {
        emit('sendReservationMessage', state.email.value as string)
    } else state.errorMessageVisible = true
}
</script>

<style scoped lang="scss">
.signup-email {
    margin-top: 1.2rem;
    :deep(input) {
        @extend .is-body-2-web;
    }
}
.signup-error {
    @extend .is-body-3-web;
    color: $red;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
}
.signup-button {
    @extend .primary-button;
    @extend .is-button-text-1-web;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    padding: 0.7rem 5rem;
}
.signup-caption {
    @extend .is-body-3-web;
    b {
        cursor: pointer;
    }
}

@include touch {
    .signup-button {
        width: 100%;
    }
}
</style>
