<template>
    <div class="sideview-daycamps">
        <SupportSideviewBoolean
            label="Published"
            :value="props.item.is_published"
        />
        <SupportSideviewText label="ID" :value="props.item.id" />
        <SupportSideviewText label="Slug" :value="props.item.slug" />
        <SupportSideviewText
            label="Status"
            :value="props.item.recrutation_status"
        />
        <SupportSideviewText
            label="Reservations"
            :value="props.item.reservations.toString()"
        />
        <SupportSideviewCreatedAt :created_at="props.item.created_at" />
        <hr />
        <SupportSideviewText label="Category tags" :value="categoryTags" />
        <hr />
        <SupportSideviewLabel label="Company" />
        <!-- TODO: remove when BE is fixed 9.08.22 -->
        <SupportSideviewText
            label="Company"
            :value="props.item.owner?.company_name || 'error'"
        />
        <SupportSideviewText
            label="Display company name"
            :value="props.item.owner_name"
        />
        <SupportSideviewText label="Address e-mail" :value="props.item.email" />
        <SupportSideviewText label="Phone number" :value="props.item.phone" />
        <hr />
        <SupportSideviewLabel label="Location" />
        <SupportSideviewText label="Country" :value="props.item.country" />
        <SupportSideviewText
            label="Voivodeship"
            :value="props.item.voivodeship"
        />
        <SupportSideviewText label="District" :value="props.item.district" />
        <SupportSideviewText label="City" :value="props.item.city" />
        <SupportSideviewText label="Post code" :value="props.item.post_code" />
        <SupportSideviewText label="Street" :value="props.item.street" />
        <SupportSideviewText
            label="Street number"
            :value="props.item.building_number"
        />
        <SupportSideviewText
            label="Apartment number"
            :value="props.item.apartment_number"
        />
        <hr />
        <div v-if="props.item.type == ProductType.DayCamp">
            <SupportSideviewLabel label="Date" />
            <SupportSideviewText label="From" :value="daycampStartDate" />
            <SupportSideviewText label="To" :value="daycampEndDate" />
            <hr />
            <SupportSideviewLabel label="Time" />
            <SupportSideviewText
                label="Start"
                :value="minutes(props.item.time_start)"
            />
            <SupportSideviewText
                label="End"
                :value="minutes(props.item.time_end)"
            />
            <hr />
        </div>
        <div v-if="props.item.type == ProductType.Class">
            <SupportSideviewLabel label="Date and time" />
            <SupportSideviewText label="Schedule" value="" />
            <div
                class="description"
                v-for="day in props.item.schedule.week_days"
                :key="day.id"
                v-text="getClassDayDisplaySupport(day)"
            />
            <SupportSideviewText label="From" :value="classStartDate" />
            <SupportSideviewText label="To" :value="classEndDate" />
            <SupportSideviewText label="Exceptions" :value="classExceptions" />
            <hr />
        </div>
        <SupportSideviewLabel label="Recruitment" />
        <SupportSideviewText
            label="Start"
            :value="
                dateWithFormat({
                    date: props.item.recrutation_start_date,
                    format: 'D.MM.YYYY'
                })
            "
        />
        <SupportSideviewText
            label="End"
            :value="
                dateWithFormat({
                    date: props.item.recrutation_end_date,
                    format: 'D.MM.YYYY'
                })
            "
        />
        <hr />
        <SupportSideviewLabel label="Children age" />
        <SupportSideviewText label="From" :value="ageRange.min?.toString()" />
        <SupportSideviewText label="To" :value="ageRange.max?.toString()" />
        <hr />
        <div v-if="props.item.type == ProductType.Class">
            <SupportSideviewLabel label="Groups" />
            <SupportSideviewText
                label="Number of children in group"
                :value="getGroupSizeTag(props.item)"
            />
            <hr />
        </div>
        <SupportSideviewLabel label="Informations" />
        <SupportSideviewText
            label="Free slots"
            :value="props.item.base_free_slots.toString()"
        />
        <SupportSideviewBoolean
            v-if="props.item.type == ProductType.DayCamp"
            :label="badgeLabel()"
            :value="isReportedToBoard"
        />
        <hr />
        <SupportSideviewLabel label="Descriptions" />
        <div v-text="`Camp description:`" class="description-label" />
        <div v-text="props.item.description" class="description" />
        <div v-if="props.item.type == ProductType.DayCamp">
            <div v-text="`Food description:`" class="description-label" />
            <div v-text="props.item.food_description" class="description" />
        </div>
        <div v-text="`Location description:`" class="description-label" />
        <div v-text="props.item.localization_description" class="description" />
        <hr />
        <SupportSideviewLabel label="FAQ" />
        <div v-for="faqItem in props.item.faq" :key="faqItem.question">
            <div class="description-label" v-text="faqItem.question" />
            <div class="description" v-text="faqItem.answer" />
        </div>
        <hr />
        <SupportSideviewStaff
            :staff_description="props.item.staff_description"
            :staff="props.item.staff"
        />
        <hr />
        <SupportSideviewLabel label="Gallery" />
        <div class="gallery">
            <Image
                class="gallery-image"
                v-for="image in props.item.attachments"
                :key="image"
                :a_src="image"
            />
        </div>
        <hr />
        <SupportSideviewLabel label="Ticket" />

        <div v-if="props.item.type == ProductType.DayCamp">
            <SupportSideviewText
                label="Camp price"
                :value="getPriceDisplay(props.item.price_bundle)"
            />
            <SupportSideviewText
                label="Single day ticket"
                :value="getPriceDisplay(props.item.price_single_day)"
            />
        </div>
        <div v-if="props.item.type == ProductType.Class">
            <SupportSideviewText label="Trial" :value="trialPrice.value" />
            <SupportSideviewText
                label="Description"
                :value="trialPrice.description"
            />
            <SupportSideviewText
                label="Voucher description"
                :value="trialPrice.caption"
            />
            <hr class="ticket-hr" />
            <SupportSideviewText
                label="Semester"
                :value="semesterPrice.value"
            />
            <SupportSideviewText
                label="Description"
                :value="semesterPrice.description"
            />
            <hr class="ticket-hr" />
            <SupportSideviewText label="Advance" :value="advancePrice.value" />
            <SupportSideviewText
                label="Description"
                :value="advancePrice.description"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    ProductModel,
    ProductType,
    reported_to_board_of_education
} from '@/models/product.types'
import SupportSideviewBoolean from '../SupportSideviewBoolean.vue'
import SupportSideviewText from '../SupportSideviewText.vue'
import SupportSideviewCreatedAt from '../SupportSideviewCreatedAt.vue'
import { computed } from 'vue'
import SupportSideviewLabel from '../SupportSideviewLabel.vue'
import {
    getProductAgeRangeObject,
    getProductEndDate,
    getProductStartDate
} from '@/helpers/product'
import { minutes } from 'shared/helpers/time'
import { dateWithFormat } from 'shared/helpers/date'
import { t } from '@/i18n'
import SupportSideviewStaff from '../SupportSideviewStaff.vue'
import Image from '@/components/utils/Image.vue'
import { getPriceDisplay } from '@/helpers/utils'
import {
    getClassDayDisplaySupport,
    getClassEndDate,
    getGroupSizeTag,
    getClassStartDate,
    getPriceByType
} from '@/helpers/classProfile'
import { ClassModel, ClassPriceType } from '@/models/class.types'

interface Props {
    item: ProductModel | ClassModel
}
const props = defineProps<Props>()

const categoryTags = computed(() => {
    return props.item.category_tags.join(', ')
})
const daycampStartDate = computed(() => {
    return getProductStartDate(props.item)
})
const daycampEndDate = computed(() => {
    return getProductEndDate(props.item)
})
const classStartDate = computed(() => {
    return getClassStartDate(props.item as ClassModel)
})
const classEndDate = computed(() => {
    return getClassEndDate(props.item as ClassModel)
})
const trialPrice = computed(() => {
    const price = getPriceByType(props.item as ClassModel, ClassPriceType.Trial)
    return {
        value: getPriceDisplay(price.value),
        description: price.data.description,
        caption: price.data.caption
    }
})
const semesterPrice = computed(() => {
    const price = getPriceByType(
        props.item as ClassModel,
        ClassPriceType.Semester
    )
    return {
        value: getPriceDisplay(price.value),
        description: price.data.description
    }
})
const advancePrice = computed(() => {
    const price = getPriceByType(
        props.item as ClassModel,
        ClassPriceType.Advance
    )
    return {
        value: getPriceDisplay(price.value),
        description: price.data.description
    }
})
const classExceptions = computed(() => {
    const classProfile = props.item as ClassModel
    return classProfile.schedule.exceptions
        .map(({ date }) => dateWithFormat({ date, format: 'D.MM.YYYY' }))
        .join(', ')
})
const isReportedToBoard = computed(() => {
    return props.item.other_tags.includes(reported_to_board_of_education)
})
const ageRange = computed(() => {
    return getProductAgeRangeObject(props.item)
})

const badgeLabel = () => {
    return `Badge "${t('REPORTED_TO_BOARD_OF_EDUCATION')}"`
}
</script>

<style scoped lang="scss">
.description-label {
    @extend .is-body-2-web;
    margin-bottom: 0.5rem;
}
.description {
    @extend .is-body-2-web;
    margin-bottom: 1rem;
}
.gallery {
    display: flex;
    gap: 1rem;
}
.gallery-image {
    object-fit: cover;
    width: 200px;
    height: 150px;
    border-radius: 8px;
}
.ticket-hr {
    width: 50%;
}
</style>
