import {
    payment_status,
    ReservationPriceType,
    ReservationTicketType
} from '@/models/reservation.types'
import {
    DayCampPriceType,
    ProductRecrutationStatus
} from '@/models/product.types'
import { t } from '@/i18n'
import router from '@/router'
import { ClassPriceType } from '@/models/class.types'

export const getPaymentStatusDisplay = (
    status: keyof typeof payment_status
) => {
    // TODO: 'payment_status' replace it with enum 1.09.22
    const displays = {
        created: 'UNPAID',
        pending: 'PENDING_PAYMENT',
        paid: 'PAID',
        cancelled: 'CANCELLED'
    }

    return t(displays[status]) || ''
}

export const goToReservationForm = () => {
    router.push({ name: 'reservation_form' })
}

export const goToReservationPayment = () => {
    router.push({ name: 'reservation_payment' })
}

export const goToReservationThanks = () => {
    router.push({ name: 'reservation_thanks' })
}

export const goToReservationFailed = () => {
    router.push({ name: 'reservation_failed' })
}

export const getRecruitmentStatusDisplay = (
    status: ProductRecrutationStatus
) => {
    const displays = {
        [ProductRecrutationStatus.Draft]: 'DRAFT',
        [ProductRecrutationStatus.ToBeStarted]: 'INCOMING',
        [ProductRecrutationStatus.Started]: 'IN_MOTION',
        [ProductRecrutationStatus.Finished]: 'ENDED'
    }

    return t(displays[status]) || ''
}

export const getReservationType = (type: ReservationTicketType) => {
    const displays = {
        [ReservationTicketType.Range]: 'WHOLE_DAYCAMP',
        [ReservationTicketType.SingleDay]: 'SINGLE_DAYS',
        [ReservationTicketType.Trial]: 'TRIAL_CLASSES',
        [ReservationTicketType.Advance]: 'CASH_ADVANCE',
        [ReservationTicketType.Semester]: 'SEMESTER'
    }

    return t(displays[type]) || ''
}

export const convertPriceTypeToTicketType = (
    priceType: ReservationPriceType
) => {
    const ticketTypes = {
        [ClassPriceType.Trial]: ReservationTicketType.Trial,
        [ClassPriceType.Advance]: ReservationTicketType.Advance,
        [ClassPriceType.Semester]: ReservationTicketType.Semester,
        [DayCampPriceType.Bundle]: ReservationTicketType.Range,
        [DayCampPriceType.SingleDay]: ReservationTicketType.SingleDay
    }
    return ticketTypes[priceType]
}
