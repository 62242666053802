<template>
    <div class="account-reservations">
        <div v-if="state.reservations.length > 0">
            <div
                v-if="!isMobile"
                class="reservations-title"
                v-text="t('MY_RESERVATIONS')"
            />
            <div class="reservations-filter">
                <div
                    class="reservations-filter-text"
                    v-text="`${t('FILTER')}:`"
                />
                <AccountKidsProfilesTabs
                    class="reservations-filter-tabs"
                    :kids="state.kids"
                    :selected="state.selectedKid"
                    @changeTab="changeSelectedKid"
                />
            </div>
            <div class="reservations-list">
                <div
                    v-for="reservation in selectedKidReservations"
                    :key="reservation.id"
                    class="reservation"
                >
                    <div class="reservation-product">
                        <DayCampProductPreview
                            :product="reservation.product"
                            :reservationPreview="true"
                            :verified="reservation.owner.verified"
                        />
                    </div>
                    <div class="reservation-details-container">
                        <div class="reservation-details">
                            <div
                                v-for="item in getReservationDetails(
                                    reservation
                                )"
                                :key="item.label"
                                class="reservation-details-item"
                            >
                                <b v-text="`${t(item.label)}: `" />
                                <span v-text="item.value" />
                            </div>
                        </div>
                        <button
                            class="reservation-details-button"
                            v-text="t('RESERVATION_DETAILS')"
                            @click="showDetails(reservation)"
                        />
                    </div>
                    <hr class="reservation-divider" />
                </div>
            </div>
        </div>
        <div v-else class="reservations-empty">
            <div
                class="reservations-empty-text"
                v-text="t('YOU_DO_NOT_HAVE_ANY_RESERVATION')"
            />
            <div
                class="reservations-empty-button"
                v-text="t('SEARCH_ACTIVITIES')"
                @click="goToListing(ProductType.Class)"
            />
        </div>

        <Modal v-if="!isMobile" ref="detailsModal" class="details-modal">
            <AccountReservationsDetails
                v-if="state.selectedReservation"
                :reservation="state.selectedReservation"
                :tableElements="tableElements"
            />
        </Modal>
        <MobileModal v-else ref="detailsModal">
            <AccountReservationsDetails
                v-if="state.selectedReservation"
                :reservation="state.selectedReservation"
                :tableElements="tableElements"
            />
        </MobileModal>
    </div>
</template>

<script setup lang="ts">
import { computed, reactive, onMounted, ref } from 'vue'
import { t } from '@/i18n'
import { goToListing } from '@/helpers/dayCampListing'
import { ProductType } from '@/models/product.types'
import AccountKidsProfilesTabs from './components/AccountKidsProfilesTabs.vue'
import DayCampProductPreview from '../DayCampProfile/components/DayCampProductPreview.vue'
import { ReservationModel } from '@/models/reservation.types'
import { getFullName } from 'shared/helpers/utils'
import { getPriceDisplay } from '@/helpers/utils'
import {
    getPaymentStatusDisplay,
    getRecruitmentStatusDisplay,
    getReservationType
} from '@/helpers/reservation'
import Modal from '@/components/utils/Modal.vue'
import { timestamp } from 'shared/helpers/time'
import AccountReservationsDetails from './components/AccountReservationsDetails.vue'
import { isMobile } from '@/helpers/utils'
import MobileModal from '@/components/utils/MobileModal.vue'
import Parents from '@/store/modules/Parents'
import ClientAccounts from '@/store/modules/ClientAccounts'
import { dateWithFormat } from 'shared/helpers/date'
import { KidModel } from '@/models/kids.types'

const detailsModal = ref<InstanceType<typeof Modal> | null>(null)

const state = reactive({
    reservations: [],
    kids: [],
    selectedKid: null,
    selectedReservation: null
})

const selectedKidReservations = computed(() => {
    return state.reservations.filter(
        (reservation: ReservationModel) =>
            reservation.kid_id == state.selectedKid?.id
    )
})
const tableElements = computed(() => {
    return [
        {
            head: 'RESERVATION_TYPE',
            value: getReservationType(state.selectedReservation.scope)
        },
        {
            head: 'RESERVATION_DATE',
            value: timestamp(state.selectedReservation.created_at).date
        },

        {
            head: 'PAYMENT_DEADLINE',
            value: dateWithFormat({
                date: state.selectedReservation.payment_date,
                format: 'D.MM.YY'
            })
        },
        {
            head: 'AMOUNT_OF_MONEY',
            value: getPriceDisplay(state.selectedReservation.amount)
        },
        {
            head: 'PAYMENT_STATUS',
            value: getPaymentStatusDisplay(
                state.selectedReservation.payment_status
            )
        },
        {
            head: 'CLASSES_STATUS',
            value: getRecruitmentStatusDisplay(
                state.selectedReservation.product.recrutation_status
            )
        }
    ]
})

const changeSelectedKid = (kid: KidModel) => {
    state.selectedKid = kid
}
const getReservationDetails = (reservation: ReservationModel) => {
    return [
        {
            label: 'PARTICIPANT',
            value: getFullName(reservation.kid_name, reservation.kid_surname)
        },
        {
            label: 'AMOUNT_OF_MONEY',
            value: getPriceDisplay(reservation.amount)
        },
        {
            label: 'PAYMENT_STATUS',
            value: getPaymentStatusDisplay(reservation.payment_status)
        },
        {
            label: 'CLASSES_STATUS',
            value: getRecruitmentStatusDisplay(
                reservation.product.recrutation_status
            )
        }
    ]
}
const showDetails = (reservation: ReservationModel) => {
    state.selectedReservation = reservation
    detailsModal.value.show()
}

onMounted(async () => {
    const parent = await Parents.getParentByAccountId({
        payload: { id: ClientAccounts.clientAccount.id }
    })
    const payload = { id: parent.id }
    state.reservations = await Parents.getParentReservations({
        payload
    })
    state.kids = await Parents.getParentKids({ payload })
    state.selectedKid = state.kids[0]
})
</script>

<style scoped lang="scss">
.account-reservations {
    width: 750px;
}

.reservations-title {
    @extend .is-subtitle-bold-web;
    margin-bottom: 2rem;
}
.reservations-filter {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}
.reservations-filter-text {
    @extend .is-body-2-bold-web;
    margin-top: 0.4rem;
}
.reservations-filter-tabs {
    margin-bottom: 0;
}

.reservation-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reservation-details-item {
    @extend .is-body-2-web;
    padding: 0.2rem;
}
.reservation-details-button {
    @extend .primary-button;
    @extend .is-button-text-2-web;
    text-transform: uppercase;
    padding: 0.5rem 1.2rem;
}
.reservation-divider {
    @extend .account-divider;
    margin: 1rem 0;
}

.reservations-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-top: 5rem;
}
.reservations-empty-text {
    @extend .is-subtitle-bold-web;
}
.reservations-empty-button {
    @extend .primary-button;
    @extend .is-button-text-1-web;
    text-transform: uppercase;
    padding: 0.6rem 3rem;
}

.details-modal {
    :deep(.modal-card) {
        width: fit-content;
    }
    :deep(.modal-card-body) {
        padding: 0.5rem 2rem;
    }
}

@include touch {
    .account-reservations {
        width: 100%;
    }

    .reservations-filter {
        @include hide-scrollbar;
        overflow-x: auto;
        padding: 0 1rem;
        margin: 0 -1rem;
    }
    .reservations-filter-tabs {
        overflow-x: visible;
    }
    .reservation-details-container {
        flex-direction: column;
        align-items: start;
        gap: 1rem;
    }
    .reservation-details-button {
        @include is-button-text-mobile;
        padding: 0.5rem 3rem;
        margin-bottom: 1rem;
    }
}
</style>
