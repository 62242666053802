<template>
    <div class="p-3 list-layout">
        <div class="level pb-3 m-0">
            <div class="level-left"></div>
            <div class="level-right buttons">
                <Button
                    v-for="action in actions"
                    :key="action.event.key"
                    :display="action.display"
                    @click="actionEmitted(action)"
                />
            </div>
        </div>
        <Table
            :enableCheckbox="table.enableCheckbox"
            :config="table.config"
            :data="table.data"
            :pagination="pagination"
            :loading="table.loading"
            @select="payload => $emit('select', payload)"
            @check="checkRow"
            @uncheck="uncheckRow"
        />
        <slot />
    </div>
</template>
<script setup lang="ts">
import Table from 'shared/components/utils/Table.vue'
import { reactive, computed, onMounted } from 'vue'
interface Props {
    table: any
    actions: string[]
}
const emit = defineEmits<{
    (e: 'select', row: any): void
    (e: 'updateCheckedRows', row: any): void
}>()
const props: Props = withDefaults(defineProps<Props>(), {
    actions: () => [],
    table: () => ({})
})
const state = reactive({
    checkedRows: []
})
const checkRow = row => {
    state.checkedRows.push(row)
    updateCheckedRows()
}
const uncheckRow = row => {
    state.checkedRows.splice(state.checkedRows.indexOf(row), 1)
    updateCheckedRows()
}
const updateCheckedRows = () => {
    emit('updateCheckedRows', [...state.checkedRows])
}
const checkAll = () => {
    state.checkedRows = table.data.map(row => row.payload)
    updateCheckedRows()
}
const uncheckAll = () => {
    state.checkedRows = []
    updateCheckedRows()
}
</script>
<style lang="scss" scoped>
.list-layout {
    position: relative;
    height: 100%;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
}
</style>
