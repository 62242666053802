<template>
    <slot></slot>
</template>
<script lang="ts">
import { Feature } from 'ol'
import Point from 'ol/geom/Point'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Icon, Stroke, Style, Text } from 'ol/style'
import {
    computed,
    ComputedRef,
    defineComponent,
    inject,
    onMounted,
    onUnmounted,
    PropType,
    watch
} from 'vue'
import { getAsset } from '@/helpers/utils'
export default defineComponent({
    props: {
        id: String,
        coordinates: {
            type: Array as PropType<number[]>,
            default: () => [0, 0]
        },
        icon: String,
        text: {
            type: String,
            defualt: ''
        },
        hovered: {
            type: Boolean,
            defualt: false
        },
        visited: {
            type: Boolean,
            defualt: false
        },
        zIndex: {
            type: Number,
            defualt: 0
        },
        payload: {
            type: Object,
            defualt: undefined
        }
    },
    setup(props, { emit }) {
        const vectorSource: ComputedRef<VectorSource<any>> | undefined =
            inject('vectorSource')
        const vectorLayer: ComputedRef<VectorLayer<any>> | undefined =
            inject('vectorLayer')
        if (!vectorSource?.value || !vectorLayer?.value) return

        const geometry = computed(() => new Point(props.coordinates))
        const style = computed(() => {
            return new Style({
                image: new Icon({
                    src: props.icon && getAsset(props.icon),
                    anchor:
                        props.payload?.['db/type'] == 'cluster'
                            ? [0.5, 0.5]
                            : [1, 1],
                    opacity: props.visited ? 0.5 : 1
                }),
                text: new Text({
                    font: 'normal 14px Rubik-Regular, sans-serif',
                    text: props.text,
                    fill: new Fill({
                        color: props.hovered ? '#00AEFF' : '#FFF'
                    }),
                    stroke: new Stroke({
                        color: props.hovered ? '#00AEFF' : '#FFF'
                    }),
                    overflow: true
                }),
                zIndex: props.zIndex
            })
        })
        const feature = computed(() => new Feature({ payload: props.payload }))
        const setFeature = (feature: Feature<any>) => {
            feature.setGeometry(geometry.value)
            feature.setStyle(style.value)
            feature.setId(props.id)
        }
        const updateFeature = (newVal, oldVal) => {
            vectorSource.value.removeFeature(oldVal)
            setFeature(newVal)
            vectorSource.value.addFeature(newVal)
            vectorSource.value.changed()
        }
        watch(
            () => props.icon,
            () => {
                setFeature(feature.value)
            }
        )
        watch(feature, updateFeature)
        watch(vectorSource, (newVal, oldVal) => {
            oldVal.removeFeature(feature.value)
            newVal.addFeature(feature.value)
            newVal.changed()
        })

        onMounted(() => {
            setFeature(feature.value)
            vectorSource.value.addFeature(feature.value)
        })
        onUnmounted(() => {
            vectorSource.value.removeFeature(feature.value)
        })

        return { feature }
    }
})
</script>

<style scoped></style>
