import { fromLonLat, toLonLat, transformExtent } from 'ol/proj'
import { getBottomLeft, getTopRight } from 'ol/extent'
import axios from 'axios'
export interface BoundsModel {
    s: number
    n: number
    w: number
    e: number
}

const constants = {
    zz_format: 'EPSG:4326',
    ol_format: 'EPSG:3857'
}

export const default_cords = { long: 21.017332, lat: 52.237049 } // Warsaw coordinates

export const map_config = {
    zoom: 12,
    minZoom: 7,
    maxZoom: 18,
    fitDuration: 400,
    defaultCenter: fromLonLat([19.23, 52.2319])
}

export const getMapBounds = (map: any): BoundsModel => {
    // Creates bounds from current map view
    const extent = map.getView().calculateExtent(map.getSize())
    const bottomLeft = toLonLat(getBottomLeft(extent))
    const topRight = toLonLat(getTopRight(extent))
    const [w, s] = bottomLeft
    const [e, n] = topRight
    return { n, w, s, e }
}
export const arrayBoundsToBounds = ([s, n, w, e]: number[]) => {
    return { e, s, w, n }
}

export const boundsToArrayBounds = ({ e, s, w, n }) => {
    return [s, n, w, e]
}

export const extentToBounds = (extent: number[]): BoundsModel => {
    // Creates bounds from OL extent (extent is OL's name for boundingbox)
    const [e, s, w, n] = transformExtent(
        extent,
        constants.ol_format,
        constants.zz_format
    )
    return { e, s, w, n }
}

export const boundsToExtent = ({ e, s, w, n }: BoundsModel): number[] => {
    const epsg4326 = [e, s, w, n]
    return transformExtent(epsg4326, constants.zz_format, constants.ol_format)
}

export const isPointInBoundingBox = (
    point: { long: number; lat: number },
    boundingbox: number[]
) => {
    const { lat, long } = point

    return (
        boundingbox[0] <= lat &&
        lat <= boundingbox[1] &&
        boundingbox[2] <= long &&
        long <= boundingbox[3]
    )
}

export const getCenterOfBoundingBox = (boundingbox: number[]) => {
    return {
        lat: boundingbox[0] - (boundingbox[0] - boundingbox[1]) / 2,
        long: boundingbox[2] - (boundingbox[2] - boundingbox[3]) / 2
    }
}

export const getBoundingBoxFromText = async (text: string) => {
    const data = await axios
        .request({
            method: 'GET',
            url: `https://nominatim.openstreetmap.org/search/${text}?format=json`
        })
        .then(({ data }) => {
            return data
        })
    if (data.length == 0) return
    const { boundingbox } = data[0]
    return boundingbox
}
