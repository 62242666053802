<template>
    <div :class="['daycamp-badge', props.badge.colorClass]">
        <ExclamationMarkCircle
            class="daycamp-badge-icon"
            :color="getIconColor"
        />
        <div
            :class="['daycamp-badge-text', props.badge.colorClass]"
            v-text="props.badge.text"
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ExclamationMarkCircle from '@/components/icons/ExclamationMarkCircle.vue'
const props = withDefaults(
    defineProps<{
        badge: {
            text: string
            colorClass: string
        }
    }>(),
    {
        badge: () => ({
            text: '',
            colorClass: 'purple'
        })
    }
)
const getIconColor = computed(() => {
    const colors = {
        purple: '#6708C6',
        sea: '#10626E',
        pink: '#6C106E'
    }
    return colors[props.badge.colorClass]
})
</script>

<style scoped lang="scss">
.daycamp-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.3rem 0.5rem;
    border-radius: 8px;
    &.purple {
        background-color: $purple-20;
    }
    &.sea {
        background-color: $sea-20;
    }
    &.pink {
        background-color: $pink-20;
    }
}
.daycamp-badge-text {
    @extend .is-body-2-web;
    display: flex;
    line-height: 110%;
    &.purple {
        color: $purple-60;
    }
    &.sea {
        color: $sea-60;
    }
    &.pink {
        color: $pink-60;
    }
}
.daycamp-badge-icon {
    width: 14px;
    height: 14px;
}
</style>
