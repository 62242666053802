import { createI18n } from 'vue-i18n'
import pl from '../../../submodules/i18n/fc/pl.json'
import en from '../../../submodules/i18n/fc/en.json'
import ua from '../../../submodules/i18n/fc/ua.json'
const messages = { pl, en, ua }

function customRule(choice: number, choicesLength: number) {
    if (choice === 0) {
        return 0
    }
    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1
    if (!teen && endsWithOne) {
        return 1
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
    }
    return choicesLength < 4 ? 2 : 3
}

const i18n = createI18n({
    legacy: false,
    missingWarn: false,
    fallbackWarn: false,
    locale: 'pl',
    fallbackLocale: 'en',
    pluralRules: {
        pl: customRule
    },
    messages
})
export default i18n

export const t = (key: string, arg?: any) => {
    if (key == undefined || typeof key != 'string') return ''
    return i18n.global.t(key, arg)
}
