<template>
    <div ref="mobileModal" v-if="state.visible" class="modal-mobile-container">
        <div class="mobile-modal">
            <div class="mobile-modal-head">
                <div class="mobile-modal-title" v-text="props.title" />
                <Icon
                    icon="close"
                    size="3"
                    class="mobile-modal-close"
                    @click="close"
                />
            </div>
            <div
                class="mobile-modal-body"
                :class="{ 'disabled-padding': props.disableBodyPadding }"
            >
                <slot />
            </div>
            <div class="mobile-modal-footer">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onUnmounted, reactive, ref } from 'vue'
import Icon from 'shared/components/utils/Icon.vue'
import { disableBodyScroll, enableBodyScroll } from '@/helpers/utils'
import { onClickOutside } from '@vueuse/core'

const mobileModal = ref(null)

interface Props {
    title?: string
    disableBodyPadding?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    title: '',
    disableBodyPadding: false
})

const state = reactive({
    visible: false
})

const show = () => {
    state.visible = true
    disableBodyScroll()
}
const close = () => {
    state.visible = false
    enableBodyScroll()
}

onClickOutside(mobileModal, close)

defineExpose({
    show,
    close
})
onUnmounted(() => {
    enableBodyScroll()
})
</script>

<style scoped lang="scss">
.modal-mobile-container {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    animation-name: fadein;
    animation-duration: 0.3s;
}
@keyframes fadein {
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}
.mobile-modal {
    position: absolute;
    bottom: 0;
    width: 100%;
    animation-name: slideup;
    animation-duration: 0.3s;
    transform: translateY(0);
}
@keyframes slideup {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
.mobile-modal-head {
    position: relative;
    background-color: white;
    border-radius: 20px 20px 0 0;
    height: 60px;
    border-bottom: 2px solid $gray-20;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -1px;
}
.mobile-modal-title {
    @include is-body-1-bold-mobile;
}
.mobile-modal-body {
    background-color: white;
    padding: 1rem;

    &.disabled-padding {
        padding: 0;
    }
}
.mobile-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
}
.mobile-modal-footer {
    background-color: white;
}
</style>
