import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators'
import store, { shouldPreserve } from '@/store'
import { RequestModel } from '@/models/api.types'
import api from '@/api'
import {
    ParentID,
    ParentModel,
    ParentRequestModel
} from '@/models/parent.types'
import { addEntity, removeEntity, updateEntity } from '@/helpers/store'
import { ClientAccountID } from '@/models/clientAccount.types'
import { KidModel } from '@/models/kids.types'
import { ReservationModel } from '@/models/reservation.types'
const base_url = 'v1/parents'

const name = 'parents'
const keys = ['parents']
@Module({
    name,
    dynamic: true,
    preserveState: shouldPreserve(name, keys),
    store
})
class Parents extends VuexModule {
    parent: ParentModel = null
    parents: ParentModel[] = []
    parentKids: KidModel[] = []

    @Mutation
    public CLEAR_STATE() {
        this.parents = []
    }
    @Mutation
    SET_PARENT(parent: ParentModel) {
        this.parent = parent
    }
    @Mutation
    SET_PARENTS(parents: ParentModel[]) {
        this.parents = parents
    }
    @Mutation
    ADD_PARENT(parent: ParentModel) {
        addEntity(parent, this.parents)
    }
    @Mutation
    REMOVE_PARENT(parent_id: ParentModel['id']) {
        removeEntity(parent_id, this.parents)
    }
    @Mutation
    SET_KIDS(kids: KidModel[]) {
        this.parentKids = kids
    }

    @Action
    public async getParents({ config = {} }: RequestModel = {}): Promise<
        ParentModel[]
    > {
        const parents = await api.get(`${base_url}/list`, config)
        this.SET_PARENTS(parents)
        return parents
    }
    @Action
    public async getParentByAccountId({
        config = {},
        payload
    }: RequestModel<{
        id: ClientAccountID
    }> = {}): Promise<ParentModel> {
        const parent = await api.get(
            `${base_url}/by-account-id/${payload.id}`,
            config
        )
        this.SET_PARENT(parent)
        return parent
    }
    @Action
    public async getParentById({
        config = {},
        payload
    }: RequestModel<{ id: ParentID }> = {}): Promise<ParentModel> {
        const parent = await api.get(`${base_url}/list/${payload.id}`, config)
        this.SET_PARENT(parent)
        return parent
    }
    @Action
    public async getParentKids({
        config = {},
        payload
    }: RequestModel<{ id: ParentID }> = {}): Promise<KidModel[]> {
        const kids = await api.get(
            `${base_url}/list/${payload.id}/kids`,
            config
        )
        this.SET_KIDS(kids)
        return kids
    }
    @Action
    public async getParentReservations({
        config = {},
        payload
    }: RequestModel<{ id: ParentID }> = {}): Promise<ReservationModel[]> {
        return api.get(`${base_url}/list/${payload.id}/reservations`, config)
    }
    @Action
    public async createParent({
        config = {},
        payload
    }: RequestModel<ParentRequestModel> = {}): Promise<ParentModel> {
        const parent = await api.post(`${base_url}/list`, payload, config)
        this.ADD_PARENT(parent)
        return parent
    }
    @Action
    public async deleteParent({
        config = {},
        payload
    }: RequestModel<{ id: string }> = {}): Promise<ParentModel> {
        const parent = await api.delete(
            `${base_url}/list/${payload.id}`,
            config
        )
        this.REMOVE_PARENT(parent.id)
        return parent
    }

    get currentParent() {
        return this.parent
    }
    get listParents() {
        return this.parents || []
    }
    get listParentKids() {
        return this.parentKids || []
    }
}

export default getModule(Parents)
