// LAYOUT

import Errors from 'shared/helpers/errors'
import { FormModel } from 'shared/helpers/form'
import { TableConfig } from 'shared/models/table.types'

export interface SideViewProperty {
    label?: string
    key?: string
    type?: string
    modify?: (value?: unknown) => unknown
}

export interface SupportButtonFnConfig {
    id?: string
    form?: unknown
    customFields?: object
    errors?: Errors
    loading?: boolean
    publishAllModal?: any
    draftAllModal?: any
    productTypeModal?: any
}
export interface SupportButton {
    key: string
    display: string
    class?: string
    fn?: (config?: SupportButtonFnConfig) => void | Promise<void>
}
export interface SupportSideView {
    title: { key: string }
    properties: SideViewProperty[]
    buttons: SupportButton[]
}
export interface SupportDataTableFilter {
    key: string
    placeholder: string
    value: string[]
    scope: string
    search_options?: boolean
    options: {
        key: string
        value: string
        display: string
    }
}
export interface SupportDataTable<DataModel = void> {
    filters?: SupportDataTableFilter[]
    config: TableConfig
    loadData: () => Promise<DataModel>
}
export interface SupportTab<DataModel> {
    key: string
    display: string
    dataTable?: SupportDataTable<DataModel>
    buttons?: SupportButton[]
    sideview?: SupportTabs
}
export interface SupportLayout {
    tabs: SupportTab<any>[]
}

export const layouts = {
    actvity: 'activity',
    tags: 'tags',
    articles: 'articles',
    business_landing: 'business_landing'
}
export enum SupportTabs {
    Users = 'users',
    Owners = 'owners',
    Products = 'products',
    Classes = 'classes',
    Reservations = 'reservations',
    Tags = 'tags',
    Articles = 'articles',
    Authors = 'authors',
    Reviews = 'reviews',
    Businesses = 'businesses'
}
export type Layouts =
    | 'users'
    | 'activity'
    | 'tags'
    | 'articles'
    | 'business_landing'

export type SupportLayouts = Record<
    'users' | 'activity' | 'tags' | 'articles' | 'business_landing',
    SupportLayout
>

interface SupportCreatorLoadFnPayload {
    loading: boolean
    id: string
    form: any
}
export interface SupportCreator {
    title: {
        key: string
        default: string
    }
    form: any
    buttons: SupportButton[]
    prepare?: () => Promise<void>
    loadFn: (payload: SupportCreatorLoadFnPayload) => Promise<any> // TODO:
    goBackFn: () => Promise<unknown>
}

export type SupportCreators = Record<
    'owners' | 'products' | 'classes' | 'tags' | 'articles',
    SupportCreator
>

export interface SupportPayloadProps {
    form: FormModel
    id?: string
}
