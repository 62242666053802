import { ClassModel } from '@/models/class.types'
import { OwnerModel } from '@/models/owner.types'
import { ProductID, ProductModel } from '@/models/product.types'
import { getOwnerUrl } from './owners'
import { getProductUrl } from './product'

export interface SlackChannelModel {
    url: string
    hash: string
    channel: string
}
type ChannelKey = 'funclass_parent'

export const channels: Record<ChannelKey, SlackChannelModel> = {
    funclass_parent: {
        url: 'https://hooks.slack.com/services/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',
        channel: '#funclass_parent',
        hash: '9d97de3f6140fa6fa07567250af39f5caf5332ea6c627ca02756389c59e251d8'
    }
}
export interface DayCampReservationMessageModel {
    email: string
    title: string
    link: string
    id: ProductID
}

export interface OwnerContactFormMessageModel {
    email: string
    name: string
    phone: string
}

export const getDayCampMessage = (message: DayCampReservationMessageModel) => {
    return `Email: ${message.email}\nTitle: ${message.title}\nLink: ${message.link}\nID: ${message.id}`
}

export const getOwnerContactFormMessage = (
    message: OwnerContactFormMessageModel
) => {
    return `OWNER CONTACT FORM\nName: ${message.name}\nEmail: ${message.email}\nPhone: ${message.phone}`
}

export const getSlackCustomerPayload = (
    email: string,
    product: ProductModel | ClassModel
) => {
    return {
        email,
        id: product.id,
        title: product.title,
        link: getProductUrl(product)
    }
}

export const getSlackCustomerPayloadForOwnerProfile = (
    email: string,
    owner: OwnerModel
) => {
    return {
        email,
        id: owner.id,
        title: owner.company_name,
        link: getOwnerUrl(owner)
    }
}
