<template>
    <div class="faq-input">
        <div class="faq-preset-items">
            <TextInput
                v-for="item in state.presetItemsFields"
                :key="item.key"
                v-model="item.value"
                :field="item"
                class="faq-preset-item"
                @input="updateItems"
            />
        </div>
        <div class="faq-new-items">
            <hr />
            <SupportFAQItemInput
                v-for="item in state.newItemsFields"
                :key="item.key"
                :id="item.key"
                :questionField="item.questionField"
                :answerField="item.answerField"
                @remove="removeNewItem"
                @update="updateNewItems"
            />
        </div>
        <div class="faq-add-button" @click="addItem">
            <Icon icon="add" />
            <span v-text="`Add next question`" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, onMounted } from 'vue'
import Icon from 'shared/components/utils/Icon.vue'
import { ProductFaqModel } from '@/models/product.types'
import Errors from 'shared/helpers/errors'
import { text_input } from 'shared/models/formContent.types'
import TextInput from 'shared/components/form/TextInput.vue'
import { FAQFieldModel } from '@/models/formContent.types'
import SupportFAQItemInput from './SupportFAQItemInput.vue'
import { v4 as uuidv4 } from 'uuid'
import { prepareFAQQuestion } from '@/helpers/daycampProfile'

const emit = defineEmits<{
    (e: 'update', value: ProductFaqModel[]): void
    (e: 'update:modelValue', value: ProductFaqModel[]): void
}>()

interface Props {
    field: FAQFieldModel
    modelValue: ProductFaqModel[]
}
const props = defineProps<Props>()

const state = reactive({
    presetItemsFields: [],
    newItemsFields: [],
    errors: new Errors()
})

const getItemsCount = () => {
    return state.newItemsFields.length + state.presetItemsFields.length
}

const addItem = () => {
    const itemsCount = getItemsCount()

    if (itemsCount < 12)
        state.newItemsFields.push({
            key: uuidv4(),
            questionField: text_input({
                label: `${itemsCount + 1}. Add question`,
                placeholder: 'Question'
            }),
            answerField: text_input({
                label: 'Answer',
                placeholder: 'Answer'
            })
        })

    updateItems()
}
const removeNewItem = (id: number) => {
    const index = state.newItemsFields.findIndex(day => day.key === id)
    if (index != -1) {
        state.newItemsFields.splice(index, 1)
        state.newItemsFields = state.newItemsFields.map((field, index) => {
            return {
                ...field,
                questionField: {
                    ...field.questionField,
                    label: `${getItemsCount() + index}. Add question`
                }
            }
        })
        updateItems()
    }
}
const updateItems = () => {
    const value = [
        ...state.presetItemsFields.map(item => ({
            question: item.label,
            answer: item.value
        })),
        ...state.newItemsFields.map((item, index) => ({
            question: `${getItemsCount() + index - 1}. “${
                item.questionField.value
            }”`,
            answer: item.answerField.value
        }))
    ]
    emit('update', value)
    emit('update:modelValue', value)
}
const updateNewItems = ({ key, question, answer }) => {
    const updatedField = state.newItemsFields.find(field => field.key == key)
    updatedField.questionField.value = question
    updatedField.answerField.value = answer
    updateItems()
}

const setNewItemsFields = () => {
    state.newItemsFields = props.field.value
        .filter(item => !isPresetItem(item))
        .map((item, index) => ({
            key: index,
            questionField: text_input({
                label: `${getItemsCount() + 1}. Add question`,
                placeholder: 'Question',
                value: prepareFAQQuestion(item.question)
            }),
            answerField: text_input({
                label: 'Answer',
                placeholder: 'Answer',
                value: item.answer
            })
        }))
}
const setPresetItemsFields = () => {
    state.presetItemsFields = props.field.value.filter(isPresetItem).map(item =>
        text_input({
            label: item.question,
            key: item.question,
            placeholder: 'Answer',
            value: item.answer
        })
    )
}
const isPresetItem = item => {
    return props.field.presetItems.some(
        presetItem => presetItem.question == item.question
    )
}

onMounted(() => {
    setPresetItemsFields()
    setNewItemsFields()
})
</script>

<style scoped lang="scss">
.faq-preset-items {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.faq-preset-item {
    :deep(.TextInput--label) {
        @extend .is-body-2-web;
    }
}
.faq-new-items {
    margin-bottom: 1.5rem;
}
.faq-add-button {
    @extend .is-body-2-bold-web;
    display: flex;
    align-items: center;
    cursor: pointer;
}
</style>
