<template>
    <form @submit.prevent>
        <div
            v-for="field in fields"
            :key="field.key"
            :style="field.style"
            class="field"
            :class="[`-size-${field.size}`, field.class]"
        >
            <template v-if="field.hidden != true">
                <div style="position: relative !important">
                    <label
                        v-if="field.title"
                        class="field-label"
                        v-text="t(field.title)"
                    />
                    <span
                        v-if="field.required"
                        class="required-mark"
                        v-text="`*`"
                    />
                    <div class="control p-0" @click="onFieldClick(field)">
                        <div
                            v-if="field.type == type_group_label"
                            class="group-label"
                            v-text="field.text"
                        />
                        <TextInput
                            v-if="field.type == type_text"
                            v-model="field.value"
                            :name="`text-input-${field.key}`"
                            :error="props.errors?.has(field.key)"
                            :overrideClass="field.overrideClass"
                            :field="field"
                            style="height: 40px"
                            @input="() => onUpdate(fields)"
                            @enter="() => onEnter(field)"
                            @submit="() => onSubmit(field)"
                        />
                        <Selector
                            v-else-if="field.type == type_single_select"
                            v-model="field.value"
                            :field="field"
                            height="35px"
                            @update="() => onUpdate(fields)"
                            @submit="() => onSubmit(field)"
                        />
                        <Selector
                            v-else-if="field.type == type_multi_select"
                            v-model="field.value"
                            :field="field"
                            height="35px"
                            @update="() => onUpdate(fields)"
                            @submit="() => onSubmit(field)"
                        />
                        <AreaInput
                            v-if="field.type == type_area"
                            v-model="field.value"
                            :error="errors?.has(field.key)"
                            :field="field"
                            @input="() => onUpdate(fields)"
                            @submit="() => onSubmit(field)"
                        />
                        <Switch
                            v-if="field.type == type_switch"
                            v-model="field.value"
                            style="margin-bottom: -1rem"
                            :field="field"
                            @update="onUpdate"
                        />
                        <TimeInput
                            v-if="field.type == type_time"
                            v-model="field.value"
                            :field="field"
                            @update="onUpdate"
                            @submit="() => onSubmit(field)"
                        />
                        <DateInput
                            v-if="field.type == type_date"
                            v-model="field.value"
                            :field="field"
                            @input="() => onUpdate(fields)"
                            @update="onUpdate"
                        />
                        <DatePicker
                            v-if="field.type == type_date_picker"
                            v-model="field.value"
                            :field="field"
                            @input="() => onUpdate(fields)"
                            @update="onUpdate"
                        />
                        <AttachmentInput
                            v-if="field.type == type_attachments"
                            v-model="field.value"
                            :field="field"
                            @input="() => onUpdate(field)"
                            @submit="onSubmit(field)"
                        />
                        <ColorInput
                            v-if="field.type == type_color"
                            v-model="field.value"
                            :field="field"
                            @input="() => onUpdate(fields)"
                            @update="onUpdate"
                        />
                        <TextEditorInput
                            v-if="field.type == type_rich_text"
                            v-model="field.value"
                            :field="field"
                            @input="() => onUpdate(fields)"
                        />
                        <StaffInput
                            v-if="field.type == type_staff_input"
                            v-model="field.value"
                            :field="field"
                            @update="onUpdate"
                        />
                        <MapSelector
                            v-if="field.type == type_map_selector"
                            v-model="field.value"
                            :field="field"
                            @input="onUpdate"
                        />
                        <SupportClassScheduleInput
                            v-if="field.type == type_class_schedule_input"
                            v-model="field.value"
                            :field="field"
                            @input="onUpdate"
                        />
                        <SupportOwnerScheduleInput
                            v-if="field.type == type_owner_schedule_input"
                            v-model="field.value"
                            :field="field"
                            @input="onUpdate"
                        />
                        <SupportFAQInput
                            v-if="field.type == type_faq_input"
                            v-model="field.value"
                            :field="field"
                            @input="onUpdate"
                        />
                        <p
                            v-if="props.errors?.getMessage(field.key)"
                            class="help is-danger error"
                            v-text="t(props.errors?.getMessage(field.key))"
                        />
                        <p
                            v-else-if="field.description"
                            class="help"
                            :class="field.description.class"
                            v-text="
                                t(field.description.text || field.description)
                            "
                        />
                    </div>
                </div>
            </template>
        </div>
    </form>
</template>

<script setup lang="ts">
import TextInput from 'shared/components/form/TextInput.vue'
import Selector from 'shared/components/form/Selector.vue'
import AreaInput from 'shared/components/form/AreaInput.vue'
import TimeInput from 'shared/components/form/TimeInput.vue'
import DateInput from 'shared/components/form/DateInput.vue'
import DatePicker from 'shared/components/form/DatePicker.vue'
import Switch from 'shared/components/utils/Switch.vue'
import AttachmentInput from '@/components/form/AttachmentInput.vue'
import ColorInput from 'shared/components/form/ColorInput.vue'
import TextEditorInput from 'shared/components/form/TextEditorInput.vue'
import StaffInput from '@/components/form/StaffInput.vue'
import MapSelector from '@/components/utils/MapSelector.vue'
import { t } from '@/i18n'
import {
    FieldModel,
    type_text,
    type_single_select,
    type_multi_select,
    type_group_label,
    type_area,
    type_time,
    type_date,
    type_switch,
    type_attachments,
    type_color,
    type_rich_text,
    type_map_selector,
    type_date_picker
} from 'shared/models/formContent.types'
import {
    type_staff_input,
    type_class_schedule_input,
    type_owner_schedule_input,
    type_faq_input
} from '@/models/formContent.types'
import SupportClassScheduleInput from './SupportClassScheduleInput.vue'
import SupportOwnerScheduleInput from './SupportOwnerScheduleInput.vue'
import SupportFAQInput from './SupportFAQInput.vue'

const props = defineProps({
    errors: {
        type: Object,
        required: true
    },
    fields: {
        type: Object,
        default: () => ({})
    }
})
const emit = defineEmits<{
    (e: 'enter', update: FieldModel): void
    (e: 'submit', update: FieldModel): void
    (e: 'update', update: any): void
    (e: 'update:fields', update: any): void
}>()
const onEnter = (update: FieldModel) => {
    emit('enter', update)
}
const onSubmit = (update: FieldModel) => {
    emit('submit', update)
}
const onUpdate = (update: any) => {
    const key: any = update.key
    emit('update', update)
    emit('update:fields', { ...props.fields, [key]: update })
}
const onFieldClick = (field: any) => {
    props.errors.clear(field.key)
}
</script>

<style scoped lang="scss">
form > * {
    padding-bottom: 0.5rem;
}
form {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    // column-gap dependent on gapEqualizer
    column-gap: 9px;
    margin-bottom: 0.5rem;
}

.field {
    width: 100%;
    margin: 0;
    @media (min-width: 1000px) {
        &.-size-3 {
            width: calc((100% / 2) * 3);
        }
        &.-size-2 {
            width: calc(50% - 5px);
        }
        &.-size-1 {
            width: calc((100% / 3) - 9px);
        }
    }
}
.field-label {
    @extend .is-body-2-web;
    margin-left: 0.2rem;
    margin-bottom: 0.3rem;
    margin-right: 0;
}
.required-mark {
    color: $gray-70;
    font-size: 11px;
}
.group-label {
    @extend .is-body-1-bold-web;
    padding-top: 2rem;
}
.error {
    position: absolute;
    top: 3rem;
}
</style>
