import {
    goToSupportProductCreator,
    goToSupportOwnerCreator,
    goToSupportTagCreator,
    goToSupportArticleCreator,
    goToOwnerUpload,
    goToProductsUpload
} from '@/helpers/support'
import { SupportButton } from '@/models/support.types'
export const ownerTabButtons: SupportButton[] = [
    {
        key: 'add_owner',
        display: 'Add owner',
        fn: () => goToSupportOwnerCreator()
    },
    {
        key: 'upload_owners',
        display: 'Upload owners',
        fn: () => goToOwnerUpload()
    }
]

export const productTabButtons: SupportButton[] = [
    {
        key: 'add_product',
        display: 'Add product',
        fn: ({ productTypeModal }) => {
            productTypeModal.show()
        }
    },
    {
        key: 'upload_products',
        display: 'Upload products',
        fn: () => goToProductsUpload()
    },
    {
        key: 'publish_all',
        display: 'Publish all',
        fn: ({ publishAllModal }) => {
            publishAllModal.show()
        }
    },
    {
        key: 'save_all_as_draft',
        display: 'Save all as draft',
        fn: ({ draftAllModal }) => {
            draftAllModal.show()
        }
    }
]

export const categoryTabButtons: SupportButton[] = [
    {
        key: 'add_category',
        display: 'Add tag',
        fn: () => goToSupportTagCreator()
    }
]

export const articlesTabButtons: SupportButton[] = [
    {
        key: 'add_article',
        display: 'Add article',
        fn: () => goToSupportArticleCreator()
    }
]

export const authorsTabButtons: SupportButton[] = [
    {
        key: 'add_author',
        display: 'Add author',
        fn: () => {}
    }
]

export const reviewsTabButtons: SupportButton[] = [
    {
        key: 'add_review',
        display: 'Add review',
        fn: () => {}
    }
]
export const businessesTabButtons: SupportButton[] = [
    {
        key: 'add_business',
        display: 'Add business',
        fn: () => {}
    }
]
