<template>
    <div class="dropdown is-active">
        <div
            id="dropdown_selector"
            ref="selector"
            :style="{ width: '100%' }"
            tabindex="-1"
            @click="setFocus"
            @blur="onBlur"
        >
            <slot name="selector" />
        </div>
        <transition name="fade">
            <div
                v-if="state.isActive"
                id="dropdown_body"
                class="dropdown-body"
                ref="dropdown_body"
                tabindex="0"
            >
                <slot name="body" />
            </div>
        </transition>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
export default defineComponent({
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const dropdown_body = ref()
        const selector = ref()
        const state = reactive({
            isActive: false,
            cooledDown: true,
            isSelfClick: false,
            searchActive: false
        })
        const toggleBody = () => {
            state.isActive = false
        }
        const setFocus = () => {
            if (props.disabled) return
            if (state.isSelfClick) {
                toggleBody()
                state.isSelfClick = false
                return
            }
            selector.value?.focus()
            state.searchActive = false
            state.isActive = true
        }
        const setCooldown = () => {
            state.cooledDown = false
            setTimeout(() => (state.cooledDown = true), 250)
        }
        const onBlur = ({ relatedTarget, currentTarget }: any) => {
            state.isSelfClick = currentTarget.contains(relatedTarget)
            if (!state.cooledDown) return setFocus()
            setCooldown()
            if (
                relatedTarget?.name == 'text-input-dropdown-search' &&
                !state.searchActive
            ) {
                state.searchActive = true
                return
            }
            relatedTarget?.id == 'dropdown_body'
                ? setFocus()
                : (state.isActive = false)
        }
        return {
            state,
            setFocus,
            dropdown_body,
            toggleBody,
            onBlur,
            selector
        }
    }
})
</script>
<style scoped>
.dropdown {
    width: 100%;
    background-color: white;
}
.dropdown-blur-area {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
}

.dropdown-body {
    position: absolute;
    background-color: white;
    width: 100%;
    margin-top: 3rem;
}
</style>
