<template>
    <div class="sideview-products">
        <ElTabs v-model="state.activeTab" class="sideview-tabs">
            <ElTabPane
                label="Information & profile"
                name="info"
                class="sideview-products-info"
            >
                <SupportSideviewProductsProperties :item="props.item" />
            </ElTabPane>
            <ElTabPane
                class="sideview-products-info"
                label="Reservations"
                name="reservations"
            >
                <ElTable :data="reservationsTable" fit>
                    <ElTableColumn
                        v-for="column in tableColumns"
                        :key="column.key"
                        :type="column.type"
                        :prop="column.prop"
                        :label="column.label"
                        :width="column.width"
                    />
                </ElTable>
            </ElTabPane>
        </ElTabs>
    </div>
</template>

<script setup lang="ts">
import { ClassModel } from '@/models/class.types'
import { ProductModel } from '@/models/product.types'
import { ReservationModel } from '@/models/reservation.types'
import Reservations from '@/store/modules/Reservations'
import { ElTabs, ElTabPane, ElTable, ElTableColumn } from 'element-plus'
import 'element-plus/es/components/table/style/css'
import { timestamp } from 'shared/helpers/time'
import { onMounted } from 'vue'
import { computed, reactive } from 'vue'
import SupportSideviewProductsProperties from './SupportSideviewProductsProperties.vue'

interface Props {
    item: ProductModel | ClassModel
}
const props = defineProps<Props>()

const state = reactive({
    activeTab: 'info'
})

const reservationsTable = computed(() => {
    return Reservations.listReservations
        .filter(
            (reservation: ReservationModel) =>
                reservation.product_id == props.item.id
        )
        .map((reservation: ReservationModel) => ({
            name: `${reservation.kid_name} ${reservation.kid_surname}`,
            tickets: reservation.scope,
            status: reservation.payment_status,
            created: timestamp(reservation.created_at).both
        }))
})
const tableColumns = [
    {
        key: 'index',
        type: 'index',
        width: '30'
    },
    {
        key: 'name',
        prop: 'name',
        label: 'Name'
    },
    {
        key: 'tickets',
        prop: 'tickets',
        label: 'Tickets'
    },
    {
        key: 'status',
        prop: 'status',
        label: 'Status'
    },
    {
        key: 'created',
        prop: 'created',
        label: 'Created'
    }
]
onMounted(async () => {
    await Reservations.getReservations()
})
</script>

<style scoped lang="scss">
.sideview-products-info {
    padding: 1rem 2rem;
}
.sideview-tabs {
    @include sideview-tabs;
}
</style>
