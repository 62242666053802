<template>
    <i
        :class="[`is-size-${size}`, `material-icons-${type}`]"
        :style="{
            'user-select': 'none',
            color: color
        }"
        >{{ icon }}</i
    >
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        type: { type: String, default: 'round' },
        icon: String,
        size: {
            type: String,
            default: '4'
        },
        color: {
            type: String,
            default: '#000'
        }
    }
})
</script>
