import { t } from '@/i18n'
import {
    ClassModel,
    ClassPriceType,
    ClassSchedule,
    ClassWeekDay
} from '@/models/class.types'
import { ProductRecrutationStatus, ProductType } from '@/models/product.types'
import Classes from '@/store/modules/Classes'
import {
    dateWithFormat,
    getDatesInRange,
    getWeekDayDisplay,
    months
} from 'shared/helpers/date'
import { minutes } from 'shared/helpers/time'
import { getProductRecruitmentStart } from './product'
import { getTagById, isTagGroupSize } from './tags'

export const getClassGroupSizeDisplay = (product: ClassModel) => {
    return `${t('GROUP_SIZE')}:  ${getGroupSizeTag(product)} ${t(
        'OF_KIDS'
    ).toLowerCase()}`
}

export const getFirstClassDateDisplay = (product: ClassModel) => {
    return `${t('FIRST_CLASSES')}: ${getClassStartDate(product)}`
}

export const getClassRecruitmentStartDisplay = (product: ClassModel) => {
    return `${t('START_OF_REGISTRATION')}: ${getProductRecruitmentStart(
        product
    )}`
}

export const getClassStartDate = (product: ClassModel) => {
    return dateWithFormat({
        date: product.schedule.start_date,
        format: 'D.MM.YYYY'
    })
}

export const getClassEndDate = (product: ClassModel) => {
    return dateWithFormat({
        date: product.schedule.end_date,
        format: 'D.MM.YYYY'
    })
}

export const getClassDateRange = (product: ClassModel) => {
    if (!product.schedule) return t('MISSING_DATA')
    return `${getClassStartDate(product)}-${getClassEndDate(product)}`
}

export const getClassDayHoursRange = (day: ClassWeekDay) => {
    const { start_time, end_time } = day
    return `${minutes(start_time)}-${minutes(end_time)}`
}

export const getClassDayHoursDisplay = (day: ClassWeekDay) => {
    return `${t('HOUR_ABBR')} ${getClassDayHoursRange(day)}`
}

export const getClassDayDisplay = (day: ClassWeekDay) => {
    return `${t(
        getWeekDayDisplay(day.week_day)
    ).toLowerCase()}, ${getClassDayHoursDisplay(day)}`
}
export const getClassDayDisplaySupport = (day: ClassWeekDay) => {
    return `${getWeekDayDisplay(
        day.week_day
    ).toLowerCase()}, ${getClassDayHoursRange(day)}`
}
export const getClassDayShortDisplay = (day: ClassWeekDay) => {
    return `${t(
        getWeekDayDisplay(day.week_day) + '_ABBREVIATION'
    ).toLowerCase()}, ${getClassDayHoursDisplay(day)}`
}
export const getGroupSizeTag = (product: ClassModel) => {
    const tagId = product.other_tags.find((id: string) =>
        isTagGroupSize(getTagById(id))
    )
    const ranges = tagId.split('_')
    return `${ranges[0]}-${ranges[1]}`
}
export const getClassPriceTypeDisplay = (priceType: string) => {
    const types = {
        trial: t('TRIAL_CLASSES'),
        advance: t('CASH_ADVANCE'),
        semester: t('SEMESTER')
    }
    return types[priceType]
}
export const getScheduleObject = (schedule: ClassSchedule) => {
    const { start_date, end_date, exceptions, week_days } = schedule

    const dates = getDatesInRange(start_date, end_date).filter(date =>
        week_days.some(day => day.week_day == date.getDay())
    )
    const scheduleObj = {}

    dates.forEach(date => {
        const d = new Date(date)
        const key = `${d.getFullYear()}_${d.getMonth()}`

        if (
            !Array.isArray(exceptions) ||
            !exceptions.some(
                exception =>
                    exception.date ==
                    dateWithFormat({ date, format: 'YYYY-MM-DD' })
            )
        )
            scheduleObj[key]
                ? scheduleObj[key].dates.push(date)
                : (scheduleObj[key] = {
                      year: d.getFullYear(),
                      month: t(months[d.getMonth()].toUpperCase()),
                      dates: [date]
                  })
    })
    return scheduleObj
}
export const getScheduleDates = (schedule: ClassSchedule) => {
    return Object.values(getScheduleObject(schedule)).flatMap(
        (value: { dates: string[] }) =>
            value.dates.map(date =>
                dateWithFormat({ date, format: 'YYYY-MM-DD' })
            )
    )
}
export const getPriceByType = (product: ClassModel, type: ClassPriceType) => {
    return (
        product.prices.find(price => price.type == type) || {
            value: 0,
            data: {
                description: '',
                caption: ''
            }
        }
    )
}
export const getScheduleForOverlay = (product: ClassModel) => {
    return product.schedule
        ? product.schedule.week_days
              .map(day => getClassDayShortDisplay(day))
              .join('\n')
        : ''
}
