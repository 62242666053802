import { createApp } from 'vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import App from './App.vue'
import 'material-icons/iconfont/material-icons.css'
import 'vue-toastification/dist/index.css'
import Toast from 'vue-toastification'
import { toastOptions } from '@/helpers/toast'
import { createMetaManager } from 'vue-meta'
import Tags from '@/store/modules/Tags'
import '@/firebase'
import VueSocialSharing from 'vue-social-sharing'
import VueGtag from 'vue-gtag'

const app = createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(createMetaManager())
    .use(Toast, toastOptions)
    .use(VueSocialSharing)
    .use(VueSocialSharing)
    .use(VueGtag, {
        config: {
            id: 'G-YNX1TK2ZG9'
            //cookieDomain: 'none' // NOTE: uncomment for debugging on localhost
        }
    })

app.config.warnHandler = () => {
    return null
}
router.isReady().then(async () => {
    await Tags.getTags().catch(() => {})
    app.mount('#app')
})
