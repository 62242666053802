import { Layouts, SupportTabs } from '@/models/support.types'
import router from '@/router'
import { computed } from 'vue'

export const isSupport = computed(() =>
    router.currentRoute.value.path.includes('/support')
)

export const goToSupportTab = (layout: Layouts, tab: SupportTabs) =>
    router.push({
        name: 'support_tab',
        params: { layout, tab }
    })
export const goToSupportCreator = (
    layout: Layouts,
    tab: SupportTabs,
    id?: string
) => {
    router.push({
        name: 'support_creator',
        params: { layout, tab, id }
    })
}

export const goToSupportArticleCreator = (id?: string) =>
    goToSupportCreator('articles', SupportTabs.Articles, id)
export const isSupportSubpageActive = (tab: string) =>
    router.currentRoute.value.params.tab == tab

export const goToSupportUsers = () => goToSupportTab('users', SupportTabs.Users)

export const goToSupportActivity = () =>
    goToSupportTab('activity', SupportTabs.Owners)

export const goToSupportCategories = () =>
    goToSupportTab('tags', SupportTabs.Tags)

export const goToSupportArticles = () =>
    goToSupportTab('articles', SupportTabs.Articles)

export const goToSupportBusinessLanding = () =>
    goToSupportTab('business_landing', SupportTabs.Reviews)

export const goToSupportOwnerCreator = (id?: string) =>
    goToSupportCreator('activity', SupportTabs.Owners, id)

export const goToSupportProductCreator = (product_id?: string) =>
    goToSupportCreator('activity', SupportTabs.Products, product_id)

export const goToSupportClassCreator = (product_id?: string) =>
    goToSupportCreator('activity', SupportTabs.Classes, product_id)

export const goToSupportTagCreator = (id?: string) =>
    goToSupportCreator('tags', SupportTabs.Tags, id)

export const goToOwnerUpload = () => {
    router.push({ name: 'support_upload_owner' })
}

export const goToProductsUpload = () => {
    router.push({ name: 'support_upload_products' })
}
