<template>
    <div class="map-selector">
        <Map
            ref="mapRef"
            class="map"
            :isMapSelector="!props.field.disabled"
            @dragDrop="onDragEnd"
        >
            <Marker
                :id="marker.id.toString()"
                :coordinates="marker.coordinates"
                :icon="marker.icon"
                :payload="marker.coordinates"
            />
        </Map>
        <div v-if="props.field.search" class="search-container">
            <TextInput
                :field="state.search"
                v-model="state.search.value"
                class="search-input"
            />
            <div
                class="search-button"
                v-text="t('SEARCH')"
                @click="searchLocation"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import Map from '@/components/openlayers/Map.vue'
import Marker from '@/components/openlayers/Marker.vue'
import { fromLonLat } from 'ol/proj'
import { MapSelectorFieldModel } from 'shared/models/formContent.types'
import { text_input } from 'shared/models/formContent.types'
import TextInput from 'shared/components/form/TextInput.vue'
import { t } from '@/i18n'
import { getSocketUrl } from '@/helpers/socket'
import {
    arrayBoundsToBounds,
    boundsToExtent,
    getCenterOfBoundingBox,
    getBoundingBoxFromText
} from 'shared/helpers/openLayers'
import { GeoModel } from 'shared/models/generic.types'

const mapRef = ref()
const emit = defineEmits<{
    (e: 'update', value: GeoModel): void
    (e: 'update:modelValue', value: GeoModel): void
}>()
interface Props {
    field: MapSelectorFieldModel
}
const props = defineProps<Props>()

const state = reactive({
    connection: null,
    loading: {
        connection: false
    },
    search: text_input({
        placeholder: t('SEARCH')
    })
})

const marker = computed(() => {
    const coordinates = getCords()
    return {
        id: 1,
        coordinates: fromLonLat([coordinates.long, coordinates.lat]),
        icon: 'icons/pin_green.svg'
    }
})
const setMapPosition = () => {
    const coordinates = getCords()
    mapRef.value.centerMap(coordinates.long, coordinates.lat)
}
const getCords = () => {
    return props.field.value
        ? { long: props.field.value.long, lat: props.field.value.lat }
        : { long: 21.017332, lat: 52.237049 }
}
const onDragEnd = (coords: [number, number]) => {
    // coords in payload [long, lat]
    const value = {
        long: coords[0],
        lat: coords[1]
    }
    emit('update', value)
    emit('update:modelValue', value)
}
const searchLocation = async () => {
    const boundingbox = await getBoundingBoxFromText(
        state.search.value as string
    )
    const extent = boundsToExtent(arrayBoundsToBounds(boundingbox))
    mapRef.value.setPosition(extent, 10)
    const value = getCenterOfBoundingBox(boundingbox)
    emit('update', value)
    emit('update:modelValue', value)
}
onMounted(() => {
    setMapPosition()
})
</script>

<style scoped lang="scss">
.map-selector {
    height: 400px;
    display: flex;
    flex-direction: column;
}
.map {
    flex: 1;
}
.search-container {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
}
.search-input {
    flex: 1;
}
.search-button {
    @extend .primary-button-admin;
    @extend .is-button-text-1-web;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
