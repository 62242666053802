interface EnvConfigModel {
    key: 'localhost' | 'stage' | 'production'
    api_key: 'localhost' | 'stage' | 'production'
    socket_url: string
    autopilot_api_key: string
    recaptcha_sitekey: string
}

export const isDEV = () => {
    return import.meta.env.DEV
}
export const provideEnvConfig = (): EnvConfigModel => {
    const {
        VITE_ENV_KEY,
        VITE_APP_API_KEY,
        VITE_APP_SOCKET_URL,
        VITE_AUTOPILOT_API_KEY,
        VITE_RECAPTCHA_SITEKEY
    } = import.meta.env
    return {
        key: VITE_ENV_KEY || '',
        api_key: VITE_APP_API_KEY || '',
        socket_url: VITE_APP_SOCKET_URL || '',
        autopilot_api_key: VITE_AUTOPILOT_API_KEY || '',
        recaptcha_sitekey: VITE_RECAPTCHA_SITEKEY || ''
    }
}
