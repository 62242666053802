interface Entity {
    [key: string]: any
    id: string
}

export const setEntity = (entity: Entity, allEntities: Entity[]) => {
    const index = allEntities.findIndex(({ id }) => id == entity.id)
    if (index != -1) {
        allEntities[index] = entity
    }
}
export const addEntity = (entity: Entity, allEntities: Entity[]) => {
    const index = allEntities.findIndex(({ id }) => id == entity.id)
    if (index == -1) {
        allEntities.push(entity)
    }
}
export const updateEntity = (entity: Entity, allEntities: Entity[]) => {
    const index = allEntities.findIndex(({ id }) => id == entity.id)
    index == -1 ? allEntities.push(entity) : (allEntities[index] = entity)
}

export const removeEntity = (
    entity_id: Entity['id'],
    allEntities: Entity[]
) => {
    const index = allEntities.findIndex(({ id }) => id == entity_id)
    if (index != -1) {
        allEntities.splice(index, 1)
    } else console.log('Entity index not found')
}
