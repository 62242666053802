<template>
    <div class="staff-input">
        <div class="staff-list">
            <StaffInputItem
                v-for="(member, index) in state.fields"
                :key="index"
                :id="index"
                :form="member"
                @remove="removeMember"
                @update="updateMember"
            />
        </div>
        <button class="add-button" @click.prevent="addMember">
            <Icon icon="add" />
            <span v-text="`Add new person`" />
        </button>
    </div>
</template>
<script setup lang="ts">
import StaffInputItem from './StaffInputItem.vue'
import { reactive, watch, onMounted } from 'vue'
import {
    text_input,
    attachment_input,
    area_input
} from 'shared/models/formContent.types'
import Icon from 'shared/components/utils/Icon.vue'
const props = defineProps({
    field: {
        type: Object,
        default: () => ({})
    },
    modelValue: String
})
const emit = defineEmits<{
    (e: 'update', staff: any): void
    (e: 'update:modelValue', staff: any): void
}>()
const state = reactive({
    fields: []
})
const emitUpdate = value => {
    emit('update:modelValue', value)
    emit('update', value)
}
const addMember = () => {
    const members = [...props.field.value]
    members.push({
        avatar: '',
        name: '',
        surname: '',
        description: ''
    })
    emitUpdate(members)
}
const removeMember = (id: number) => {
    const members = [...props.field.value]
    const index = members.findIndex(member => member.key === id)
    members.splice(index, 1)
    emitUpdate(members)
}
const updateMember = () => {
    const members = state.fields.map(form => {
        return {
            name: form.name.value,
            surname: form.surname.value,
            description: form.description.value,
            avatar: form.avatar.value[0]?.url || form.avatar.value || ''
        }
    })
    emitUpdate(members)
}
const setMembers = () => {
    state.fields = props.field.value?.map(member => {
        return {
            avatar: attachment_input({
                title: '',
                key: 'avatar',
                instant_upload: true,
                value: member.avatar
            }),
            name: text_input({
                title: 'Name',
                key: 'name',
                placeholder: 'Name',
                value: member.name
            }),
            surname: text_input({
                title: 'Surname',
                key: 'surname',
                placeholder: 'Surname',
                value: member.surname
            }),
            description: area_input({
                title: 'Description',
                key: 'description',
                placeholder: 'Description (0-200 characters with spaces)',
                value: member.description
            })
        }
    })
}
watch(
    () => props.field.value,
    () => {
        setMembers()
    }
)
onMounted(() => {
    setMembers()
})
</script>

<style scoped lang="scss">
.add-button {
    @include is-body-2-bold-mobile;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    cursor: pointer;
}
</style>
