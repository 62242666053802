import { TagID } from './tag.types'
import {
    DateYYYYMMDD,
    Minutes,
    Price,
    Timestamp,
    URL,
    GeoModel,
    NumberID
} from 'shared/models/generic.types'
import { OwnerID, OwnerModel } from '@/models/owner.types'

export type ProductID = string

export interface ProductDayModel {
    date: DateYYYYMMDD
    free_slots: number
}
export interface ProductFaqModel {
    question: string
    answer: string
}
export interface ProductStaff {
    name: string
    surname: string
    description: string
    avatar: URL
}
export enum ProductRecrutationStatus {
    Draft = 'draft',
    Started = 'recrutation_started',
    Finished = 'recrutation_finished',
    ToBeStarted = 'recrutation_to_be_started'
}
export enum ProductType {
    DayCamp = 'day_camp',
    Class = 'class_profile'
}

export enum DayCampPriceType {
    Bundle = 'price_bundle',
    SingleDay = 'price_single_day'
}

export interface ProductModel {
    id: ProductID
    created_at: Timestamp

    account_id: NumberID
    owner_id: OwnerID
    time_start: Minutes
    time_end: Minutes
    recrutation_start_date: DateYYYYMMDD
    recrutation_end_date: DateYYYYMMDD
    days: ProductDayModel[]
    dates: string[]
    price_bundle: Price
    can_reserve_single_day: boolean
    price_single_day: Price
    // ADDRESS
    country: 'pl'
    phone: string
    email: string
    district: string
    voivodeship: string
    county: string
    city: string
    post_code: string
    street: string
    building_number: string
    apartment_number: string
    localization_coordinates: GeoModel
    localization_description: string
    // DESCRIPTION
    title: string
    slug: string
    type: ProductType
    age_tags: TagID[]
    category_tags: TagID[]
    other_tags: TagID[]
    owner_name: string
    description: string
    day_plan: string
    food_description: string
    attachments: URL[]
    faq: ProductFaqModel[]
    staff: ProductStaff[]
    staff_description: string
    is_published: boolean
    reservations: number
    recrutation_status: ProductRecrutationStatus
    owner: OwnerModel
    base_free_slots: number
}

export interface ProductSearch {
    geo_nw: GeoModel
    geo_se: GeoModel
    category_tags?: TagID[]
    age_tags?: TagID[]
    other_tags?: TagID[]
    dates: string[]
    text?: string
    limit?: number
}

export interface ProductCompactModel {
    id: ProductID
    owner_id: OwnerID
    days: ProductDayModel[]
    dates: string[]
    city: string
    street: string
    building_number: string
    apartment_number: string
    localization_coordinates: GeoModel
    title: string
    slug: string
    type: ProductType
    category_tags: TagID[]
    age_tags: TagID[]
    owner_name: string
    attachments: string[]
    recrutation_status: ProductRecrutationStatus
    owner: {
        slug: string
        verified: boolean
    }
    price_bundle: Price
    price_single_day: Price
}

export type ProductRequestModel = Omit<
    ProductModel,
    'id' | 'created_id' | 'slug' | 'dates'
>

export const reported_to_board_of_education = 'reported_to_board_of_education'
