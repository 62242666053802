<template>
    <div
        ref="modal"
        class="modal"
        :class="{ 'is-active': state.isActive || props.basic }"
    >
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header v-if="!props.hideHeader" class="modal-card-head">
                <div v-if="props.backButton" class="modal-back-button">
                    <Icon
                        icon="chevron_left"
                        size="3"
                        @click="backButtonClick"
                    />
                </div>
                <p class="modal-card-title" v-text="props.title" />
                <Icon class="close" icon="close" size="3" @click="close" />
            </header>
            <section class="modal-card-body">
                <slot />
            </section>
            <footer v-if="props.buttons.length > 0" class="modal-card-foot">
                <div class="modal-buttons">
                    <button
                        v-for="button in props.buttons"
                        :key="button.key"
                        :class="button.class"
                        v-text="button.display"
                        @click="button.action"
                    />
                </div>
            </footer>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import Icon from 'shared/components/utils/Icon.vue'
import { onClickOutside } from '@vueuse/core'
import { Button } from 'shared/models/generic.types'

const modal = ref(null)
const emit = defineEmits<{
    (e: 'backButtonClick'): void
    (e: 'exitWithoutSaving'): void
    (e: 'close'): void
}>()

const state = reactive({
    isActive: false
})

interface Props {
    title: string
    buttons: Button[]
    backButton: boolean
    hideHeader: boolean
    withAlertOnClose: boolean
    basic: boolean
}
const props = withDefaults(defineProps<Props>(), {
    title: '',
    buttons: () => [],
    backButton: false,
    hideHeader: false,
    withAlertOnClose: false,
    basic: false
})

const show = () => {
    state.isActive = true
}
const close = () => {
    if (props.withAlertOnClose) return emit('exitWithoutSaving')
    if (props.basic) return emit('close')
    state.isActive = false
}
const backButtonClick = () => {
    emit('backButtonClick')
}

onClickOutside(modal, close)

defineExpose({
    show,
    close
})
</script>

<style scoped lang="scss">
.modal {
    position: fixed !important;
}
.modal-card {
    border-radius: 16px;
}
.modal-card-head {
    background-color: $white;
    padding: 1rem;
    display: flex;
    align-items: center;
}
.modal-back-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.modal-card-title {
    @extend .is-h6-web;
    padding-left: 1rem;
}
.modal-card-body {
    @include slim-scrollbar;
    padding: 2rem;
}
.modal-card-foot {
    background-color: $white;
}
.modal-buttons {
    display: flex;
    justify-content: end;
    width: 100%;
}
.modal-primary-button {
    @extend .primary-button-admin;
    @extend .is-button-text-1-web;
    padding: 0.5rem 3rem;
}
.modal-secondary-button {
    @extend .secondary-button-admin;
    @extend .is-button-text-1-web;
    padding: 0.5rem 3rem;
}
.close {
    padding: 0.3rem;
    cursor: pointer;

    &:hover {
        background-color: $gray-20;
        border-radius: 50%;
    }
}
</style>
