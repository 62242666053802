import dayjs from 'dayjs'

import 'dayjs/locale/de'
import 'dayjs/locale/pl'
import 'dayjs/locale/es'
import 'dayjs/locale/uk'
import 'dayjs/locale/en'

import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import calendar from 'dayjs/plugin/calendar'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(dayOfYear)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(isBetween)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(calendar)

type LocaleType = 'pl' | 'en' | 'de' | 'es' | 'ua'
type UnitType = 'day' | 'month' | 'year' | 'week'

type DateFormat =
    | 'YYYY-MM-DD'
    | 'YYYY'
    | 'YYYY-MM'
    | 'HH:mm'
    | 'D MMMM YYYY'
    | 'D MMM YYYY'
    | 'D.MM.YYYY'
    | 'D MMMM'
    | 'dddd'
    | 'D.MM.YY'

export const dateWithFormat = (payload?: {
    date?: any
    format?: DateFormat
}): string => {
    const { date, format = 'YYYY-MM-DD' } = payload || {}
    return dayjs(date).format(format)
}

// IS FIRST DATE AFTER SECOND
export const isAfter = (firstDate: string, secondDate: string) =>
    dayjs(firstDate).isAfter(secondDate)

// IS FIRST DATE BEFORE SECOND
export const isBefore = (firstDate: string = '', secondDate: string = '') =>
    dayjs(firstDate).isBefore(secondDate)

interface AddSubtractModel {
    date?: string
    unit: UnitType
    value: number
    format?: DateFormat
}

export const dateAdd = ({
    date,
    unit,
    value,
    format = 'YYYY-MM-DD'
}: AddSubtractModel): string =>
    dayjs(date || undefined)
        .add(value, unit)
        .format(format)

export const dateSubtract = ({
    date,
    unit,
    value,
    format = 'YYYY-MM-DD'
}: AddSubtractModel): string =>
    dayjs(date || undefined)
        .subtract(value, unit)
        .format(format)

export const dateDifference = (
    firstDate: string,
    secondDate: string
): number => {
    const date1 = dayjs(firstDate)
    const date2 = dayjs(secondDate)
    return date1.diff(date2)
}

export const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
]
export const setLocale = (locale: LocaleType) => {
    const dayjsLocale = () => {
        switch (locale) {
            case 'ua':
                return 'uk'
            default:
                return locale
        }
    }
    dayjs.locale(dayjsLocale())
}
export const datesRange = (
    date_from: string,
    date_to: string,
    format: string = 'YYYY-MM-DD',
    unit: UnitType = 'day'
) => {
    const ranges = []
    let currentDate = dayjs(date_from)
    do {
        ranges.push(currentDate.format(format))
        currentDate = currentDate.add(1, unit)
    } while (currentDate.isBefore(date_to) || currentDate.isSame(date_to))
    return ranges
}
export const startOfDate = ({
    date,
    unit
}: {
    date: string
    unit: UnitType
}) => {
    return dayjs(date).startOf(unit).format('YYYY-MM-DD')
}
export const endOfDate = ({ date, unit }: { date: string; unit: UnitType }) => {
    return dayjs(date).endOf(unit).format('YYYY-MM-DD')
}

// date -  day of the month
// day - day of the week
export const dateAsNumber = ({
    date,
    unit
}: {
    date?: string
    unit:
        | 'week'
        | 'month'
        | 'year'
        | 'dayOfYear'
        | 'day'
        | 'date'
        | 'hour'
        | 'minute'
}): number => {
    dayjs(date).hour()
    return dayjs(date || undefined)[unit]()
}

interface CalendarFormat {
    sameDay: string
    nextDay: string
    nextWeek: string
    lastDay: string
    lastWeek: string
    sameElse: string
}
export const dateWithCalendarFormat = ({
    date,
    format
}: {
    date?: any
    format?: Partial<CalendarFormat>
}) => {
    return dayjs(date || undefined)['calendar'](null, format || undefined)
}
export const dateFromTimestamp = (timestamp: number) => {
    return dayjs(timestamp)
}
export const getCurrentTimestamp = () => {
    return new Date().getTime()
}
export const getMonthDate = (monthIndex: number) => {
    return dayjs().month(monthIndex).format('YYYY-MM-DD')
}
export const getMonthRange = (monthIndex: number) => {
    const monthDate = getMonthDate(monthIndex)
    return {
        start: startOfDate({ date: monthDate, unit: 'month' }),
        end: endOfDate({ date: monthDate, unit: 'month' })
    }
}
export const rangeDisplay = (start: string | number, end: string | number) => {
    return `${start}-${end}`
}

export const weekDays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
]

export const getWeekDayDisplay = (index: number) => {
    return weekDays[index].toUpperCase()
}

export const getDatesInRange = (startDate: string, endDate: string) => {
    const date = new Date(startDate)
    const end = new Date(endDate)
    const dates = []

    while (date <= end) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
    }

    return dates
}

export const defaultDatePickerFormat = (date: Date) => {
    return Array.isArray(date)
        ? date
              .map(d => dateWithFormat({ date: d, format: 'D.MM.YYYY' }))
              .join(' ')
        : dateWithFormat({ date, format: 'D.MM.YYYY' })
}

export const isEmptyDate = (date: string) => {
    return date == '0000-00-00'
}

export const createDateFromScraps = ({ month, year, day }) => {
    if (!month || !year || !day) return
    const index = months.findIndex(m => month == m) + 1
    return dateWithFormat({ date: `${year}-${index}-${day}` })
}

export default dayjs
