<template>
    <div
        v-on-click-outside="focusOff"
        class="tiptap-container"
        @click="focusOn"
    >
        <TipTap
            v-model="state.content"
            :formCreator="true"
            :editable="state.focus"
            @update="onUpdate"
        />
    </div>
</template>
<script setup lang="ts">
import TipTap from '../utils/TipTap.vue'
import { reactive, defineProps, defineEmits, onMounted, nextTick } from 'vue'
import { vOnClickOutside } from '@vueuse/components'

const props = defineProps({
    field: {
        type: Object,
        default: () => ({})
    }
})
const emit = defineEmits(['update', 'update:modelValue'])
const state = reactive({
    content: '',
    focus: false
})

const setContent = () => {
    nextTick(() => {
        const { value } = props.field
        state.content = value == `<p></p>` ? `` : value
    })
}
const onUpdate = value => {
    emit('update:modelValue', value)
}
const focusOff = () => (state.focus = false)
const focusOn = () => (state.focus = true)
onMounted(() => setContent())
</script>
<style lang="scss" scoped></style>
