<template>
    <textarea
        ref="inputRef"
        class="input AreaInput--input"
        :name="field.name"
        :class="{ 'is-danger': error, '-disabled-input': field.disabled }"
        :placeholder="placeholder"
        :value="modelValue"
        :disabled="field.disabled"
        @input.prevent="input"
        @focus="onFocusChange"
        @blur="onFocusChange"
    />
</template>
<script lang="ts">
import { defineComponent, ref, reactive } from 'vue'
import { t } from '@/i18n'
export default defineComponent({
    props: {
        error: {
            type: Boolean,
            default: false
        },
        field: {
            type: Object,
            default: () => ({})
        },
        modelValue: String
    },
    exmits: ['update:modelValue'],
    setup(props, { emit }) {
        const state = reactive({ changesMade: false })
        const inputRef = ref()
        const placeholder = props.field.placeholder
            ? props.field.placeholder
            : t('TYPE_HERE')
        const input = (event: any): void => {
            const value = event.target.value
            state.changesMade = true
            emit('update:modelValue', value)
        }
        const onFocusChange = ({ type }: any) => {
            if (type == 'blur') onSubmit()
            emit('update:isFocused', type == 'focus')
        }
        const onSubmit = () => {
            if (!state.changesMade) return
            state.changesMade = false
            emit('submit')
        }
        const blur = () => {
            inputRef.value.blur()
        }
        return { placeholder, input, inputRef, onFocusChange, blur }
    }
})
</script>
<style lang="scss" scoped>
.AreaInput--input {
    resize: none;
    min-height: 100px;
}
</style>
