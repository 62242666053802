<template>
    <input
        type="date"
        class="DateInput--input"
        :name="field.name"
        :class="{ 'is-danger': error }"
        :value="modelValue"
        @input.prevent="input"
    />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        error: {
            type: Boolean,
            default: false
        },
        field: {
            type: Object,
            default: () => ({})
        },
        modelValue: String
    },
    exmits: ['update:modelValue'],
    setup(props, { emit }) {
        const input = (event: any): void => {
            const value = event.target.value
            emit('update', { ...props.field, value })
            emit('update:field', { ...props.field, value })
            emit('update:modelValue', value)
        }
        return { input }
    }
})
</script>
<style lang="scss" scoped>
.DateInput--input {
    @extend .input;
    cursor: pointer;
}
</style>
