<template>
    <Image :a_src="iconSource" class="custom-icon" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getAsset } from '@/helpers/utils'
import Image from '@/components/utils/Image.vue'

interface Props {
    icon: string
}
const props = withDefaults(defineProps<Props>(), {
    icon: 'calendar'
})

const iconSource = computed(() => {
    return getAsset(`icons/${props.icon}.svg`)
})
</script>

<style scoped lang="scss">
.custom-icon {
    display: block;
}
</style>
