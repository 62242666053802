<template>
    <div :class="[`is-${size}`, 'image']">
        <img
            v-if="!state.error && source"
            :class="[{ 'is-rounded': rounded }]"
            :src="source"
            :alt="alt_text"
            :style="[{ objectFit: object_fit }, computed_class]"
            style="height: 100%"
            @error="state.error = true"
        />
        <img
            v-else-if="placeholderSrc"
            :class="[{ 'is-rounded': rounded }]"
            :src="placeholderSrc"
            :style="[{ objectFit: object_fit }, computed_class]"
            style="height: 100%"
        />
        <div
            v-else-if="placeholder != 'none'"
            class="svg-icon"
            v-html="svgString"
        />
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, reactive } from 'vue'
import { toSvg } from 'jdenticon'
// import { getAsset } from '@/helpers/utils'
export default defineComponent({
    props: {
        // relative source
        r_src: {
            type: String,
            default: ''
        },
        // absolute source
        a_src: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: ''
        },
        rounded: {
            type: Boolean,
            default: false
        },
        alt_text: {
            type: String,
            default: 'image'
        },
        placeholder: {
            type: String,
            default: ''
        },
        customPlaceholder: {
            type: String,
            default: ''
        },
        // passed as normal css class - but is applied as style! so it cannot use bulma classes
        computed_class: Object,
        object_fit: { type: String, default: 'contain' }
    },
    setup(props) {
        const state = reactive({ error: false })
        const svgString = computed(() => {
            return toSvg(props.alt_text, 100, {
                hues: [217],
                lightness: {
                    color: [0.5, 0.82],
                    grayscale: [0.5, 0.83]
                },
                saturation: {
                    color: 1.0,
                    grayscale: 1.0
                }
            })
        })
        const placeholderSrc = computed(() => {
            switch (props.placeholder) {
                case 'facility':
                // return getAsset('facility_placeholder.svg')
                case 'none':
                    return ''
                case 'custom':
                // return getAsset(props.customPlaceholder)
                default:
                // return getAsset('placeholder_avatar.svg')
            }
        })
        const source = computed(() => {
            return ''
            // return props.a_src || (props.r_src && getAsset(props.r_src))
        })
        return { props, source, svgString, placeholderSrc, state }
    }
})
</script>
<style lang="scss">
.svg-icon {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    svg {
        height: 100%;
    }
}
</style>
