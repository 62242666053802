import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
} from 'vuex-module-decorators'
import store, { shouldPreserve } from '@/store'
import { RequestModel } from '@/models/api.types'
import api from '@/api'
import { addEntity, removeEntity, updateEntity } from '@/helpers/store'
import { ClassModel, ClassRequestModel } from '@/models/class.types'
import { ProductID, ProductSearch } from '@/models/product.types'
const base_url = 'v1/class-profiles'
const name = 'classes'
const keys = ['classes']
@Module({
    name,
    dynamic: true,
    preserveState: shouldPreserve(name, keys),
    store
})
class Classes extends VuexModule {
    classes: ClassModel[] = []

    @Mutation
    public CLEAR_STATE() {
        this.classes = []
    }
    @Mutation
    SET_CLASSES(classes: ClassModel[]) {
        this.classes = classes
    }
    @Mutation
    ADD_CLASS(single_class: ClassModel) {
        addEntity(single_class, this.classes)
    }
    @Mutation
    EDIT_CLASS(single_class: ClassModel) {
        updateEntity(single_class, this.classes)
    }
    @Mutation
    REMOVE_CLASS(single_class_id: ClassModel['id']) {
        removeEntity(single_class_id, this.classes)
    }

    @Action
    public async getClasses({ config }: RequestModel = {}): Promise<
        ClassModel[]
    > {
        const classes = await api.get(`${base_url}/list`, config)
        this.SET_CLASSES(classes)
        return classes
    }
    @Action
    public async getClassById({
        config,
        payload
    }: RequestModel<{ id: ProductID }> = {}): Promise<ClassModel> {
        const { id } = payload
        const product = await api.get(`${base_url}/list/${id}`, config)
        this.EDIT_CLASS(product)
        return product
    }
    @Action
    public async getClassBySlug({
        config,
        payload
    }: RequestModel<{ slug: ProductID }> = {}): Promise<ClassModel> {
        const { slug } = payload
        const product = await api.get(`${base_url}/slug/${slug}`, config)
        this.EDIT_CLASS(product)
        return product
    }
    @Action
    public async searchClasses({
        config,
        payload
    }: RequestModel<ProductSearch> = {}): Promise<ClassModel[]> {
        const products = await api.post(`${base_url}/search`, payload, config)
        this.SET_CLASSES(products)
        return products
    }
    @Action
    public async searchCompactClasses({
        config,
        payload
    }: RequestModel<ProductSearch> = {}): Promise<ClassModel[]> {
        const products = await api.post(
            `${base_url}/search-compact`,
            payload,
            config
        )
        this.SET_CLASSES(products)
        return products
    }
    @Action
    public async createClass({
        config,
        payload
    }: RequestModel<ClassRequestModel> = {}): Promise<ClassModel> {
        const single_class = await api.post(`${base_url}/list`, payload, config)
        this.ADD_CLASS(single_class)
        return single_class
    }
    @Action
    public async editClass({
        config,
        payload
    }: RequestModel<
        ClassRequestModel & { id: ProductID }
    > = {}): Promise<ClassModel> {
        const { id, ...request_payload } = payload
        const single_class = await api.patch(
            `${base_url}/list/${id}`,
            request_payload,
            config
        )
        this.ADD_CLASS(single_class)
        return single_class
    }
    @Action
    public async deleteClass({
        config = {},
        payload
    }: RequestModel<{ id: ProductID }> = {}): Promise<{ id: ProductID }> {
        const single_class = await api.delete(
            `${base_url}/list/${payload.id}`,
            config
        )
        this.REMOVE_CLASS(single_class.id)
        return single_class
    }

    get listClasses() {
        return this.classes
    }
}

export default getModule(Classes)
