<template>
    <div class="sideview-tags">
        <SupportSideviewText
            label="Type of blog (scope)"
            :value="props.item.scope"
        />
        <SupportSideviewText label="Type" :value="props.item.type" />
        <SupportSideviewText label="Slug" :value="props.item.slug" />
        <SupportSideviewText label="Display" :value="props.item.display" />
        <SupportSideviewText
            label="Display translated"
            :value="t(props.item.display)"
        />
        <SupportSideviewText label="Display tags" :value="displayTags" />
        <SupportSideviewImage label="Icon" :value="props.item.icon" />
        <SupportSideviewText label="ID" :value="props.item.id" />
        <SupportSideviewCreatedAt :created_at="props.item.created_at" />
    </div>
</template>

<script setup lang="ts">
import { getTagById, getTagChildren } from '@/helpers/tags'
import { TagModel } from '@/models/tag.types'
import { computed } from 'vue'
import SupportSideviewCreatedAt from '../SupportSideviewCreatedAt.vue'
import SupportSideviewText from '../SupportSideviewText.vue'
import { t } from '@/i18n'
import SupportSideviewImage from '../SupportSideviewImage.vue'

interface Props {
    item: TagModel
}
const props = defineProps<Props>()

const displayTags = computed(() => {
    const tag = getTagById(props.item.id)
    return getTagChildren(tag)
        .map((tag: TagModel) => tag.id)
        .join(', ')
})
</script>

<style scoped lang="scss">
.sideview-tags {
    padding: 1rem 2rem;
}
</style>
