import { RouteRecordRaw } from 'vue-router'
import AccountReservations from '@/views/Account/AccountReservations.vue'
import AccountKidsProfiles from '@/views/Account/AccountKidsProfiles.vue'
import AccountSettings from '@/views/Account/AccountSettings.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: {
            name: 'account_reservations'
        }
    },
    {
        path: 'rezerwacje',
        component: AccountReservations,
        name: 'account_reservations'
    },
    {
        path: 'profile-dzieci',
        component: AccountKidsProfiles,
        name: 'account_kids_profiles'
    },
    {
        path: 'ustawienia',
        component: AccountSettings,
        name: 'account_settings'
    }
]
export default routes
