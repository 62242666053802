import { OwnerRequestModel } from '@/models/owner.types'
import {
    ProductRequestModel,
    ProductType,
    reported_to_board_of_education
} from '@/models/product.types'
import { SupportPayloadProps } from '@/models/support.types'
import { datesRange, dateWithFormat } from 'shared/helpers/date'
import { createPayload } from 'shared/helpers/form'
import storage from '@/firebase/storage'
import { getPriceToBackend } from 'shared/helpers/utils'
import { ClassPriceType, ClassRequestModel } from '@/models/class.types'
import { getScheduleDates } from '../classProfile'

export const createTagPayload = async ({ form }: SupportPayloadProps) => {
    const formPayload = createPayload({ form })
    if (formPayload.parent_id == '') delete formPayload.parent_id

    const files = await storage.uploadLocalFiles(formPayload.icon)
    return {
        ...formPayload,
        icon: files[0]
    }
}
export const createArticlePayload = async ({ form }: SupportPayloadProps) => {
    const formPayload = createPayload({ form })

    const background_image =
        typeof formPayload.background_image == 'string'
            ? formPayload.background_image
            : (await storage.uploadLocalFiles(formPayload.background_image))[0]

    const thumbnail_image =
        typeof formPayload.thumbnail_image == 'string'
            ? formPayload.thumbnail_image
            : (await storage.uploadLocalFiles(formPayload.thumbnail_image))[0]

    return {
        ...formPayload,
        is_published: true,
        account_id: 0, // TODO: replace when accounts are ready 9.05.22
        background_image: background_image,
        thumbnail_image: thumbnail_image
    }
}

export const createProductPayload = async ({
    form
}: SupportPayloadProps): Promise<ProductRequestModel> => {
    const {
        price_single_day,
        price_bundle,
        date_camp_from,
        date_camp_to,
        owner_id,
        reported_to_board,
        ...formPayload
    } = createPayload({ form })

    const days = datesRange(
        dateWithFormat({ date: date_camp_from, format: 'YYYY-MM-DD' }),
        dateWithFormat({ date: date_camp_to, format: 'YYYY-MM-DD' })
    ).map(day => ({
        date: day,
        free_slots: formPayload.slots_per_day
    }))
    const attachments = await storage.uploadLocalFiles(formPayload.attachments)
    return {
        ...formPayload,
        ...(owner_id && { owner_id }),
        price_bundle: getPriceToBackend(price_bundle) || null,
        price_single_day: getPriceToBackend(price_single_day) || null,
        type: ProductType.DayCamp,
        attachments,
        days,
        other_tags: reported_to_board ? [reported_to_board_of_education] : []
    }
}
export const createClassPayload = async ({
    form
}: SupportPayloadProps): Promise<ClassRequestModel> => {
    const {
        owner_id,
        recrutation_start_date,
        recrutation_end_date,
        group_size,
        schedule,
        base_free_slots,
        trial_price,
        trial_price_description,
        trial_price_caption,
        semester_price,
        semester_price_description,
        advance_price,
        advance_price_description,
        ...formPayload
    } = createPayload({ form })
    const attachments = await storage.uploadLocalFiles(formPayload.attachments)

    const scheduleParsed = {
        ...schedule,
        start_date: schedule.start_date
            ? dateWithFormat({
                  date: schedule.start_date,
                  format: 'YYYY-MM-DD'
              })
            : '0000-00-00',
        end_date: schedule.end_date
            ? dateWithFormat({
                  date: schedule.end_date,
                  format: 'YYYY-MM-DD'
              })
            : '0000-00-00'
    }

    const daysFromSchedule = getScheduleDates({
        ...scheduleParsed,
        exceptions: schedule.exceptions
    }).map(date => ({
        date,
        free_slots: parseInt(base_free_slots)
    }))
    const days =
        daysFromSchedule.length > 0
            ? daysFromSchedule
            : [{ date: '0000-00-00', free_slots: 0 }]
    return {
        ...formPayload,
        ...(owner_id && { owner_id }),
        type: ProductType.Class,
        attachments,
        days,
        time_end: 0,
        time_start: 0,
        recrutation_start_date: recrutation_start_date
            ? dateWithFormat({
                  date: recrutation_start_date,
                  format: 'YYYY-MM-DD'
              })
            : '0000-00-00',
        recrutation_end_date: recrutation_end_date
            ? dateWithFormat({
                  date: recrutation_end_date,
                  format: 'YYYY-MM-DD'
              })
            : '0000-00-00',
        other_tags: [group_size],
        schedule: scheduleParsed,
        base_free_slots,
        prices: [
            {
                type: ClassPriceType.Trial,
                value: getPriceToBackend(trial_price),
                data: {
                    description: trial_price_description,
                    caption: trial_price_caption
                }
            },
            {
                type: ClassPriceType.Semester,
                value: getPriceToBackend(semester_price),
                data: {
                    description: semester_price_description
                }
            },
            {
                type: ClassPriceType.Advance,
                value: getPriceToBackend(advance_price),
                data: {
                    description: advance_price_description
                }
            }
        ]
    }
}

export const createOwnerPayload = async ({
    form
}: SupportPayloadProps): Promise<OwnerRequestModel> => {
    const formPayload = createPayload({ form })
    const gallery =
        typeof formPayload.gallery[0] == 'string'
            ? formPayload.gallery
            : await storage.uploadLocalFiles(formPayload.gallery)
    return {
        ...formPayload,
        gallery,
        country: 'pl',
        faq: [],
        age_tags: ['10_10_years_old'] // BE requires at least one age tag, however form does not provide it
    }
}
