import router from '@/router'
import { appDescription, appOgImage, appTitle } from './app'
import { computed, ComputedRef } from 'vue'
import { t } from '@/i18n'
import { toSentenceCase } from 'shared/helpers/utils'
import Products from '@/store/modules/Products'
import { RouteParams } from 'vue-router'
import { ProductModel } from '@/models/product.types'
import Tags from '@/store/modules/Tags'
import { TagModel } from '@/models/tag.types'
import Blogs from '@/store/modules/Blogs'

const parent_landing = () => {
    return {
        title: t('META_TITLE_MAIN_PAGE'),
        description: t('META_DESCRIPTION_MAIN_PAGE')
    }
}

const daycamp_listing = () => {
    return {
        title: t('META_TITLE_DAYCAMP_LISTING', {
            productType: t('OF_DAYCAMPS').toLowerCase()
        }),
        description: t('META_DESCRIPTION_DAYCAMP_LISTING', {
            productType: t('DAYCAMPS').toLowerCase(),
            ofProductType: t('OF_DAYCAMPS').toLowerCase()
        })
    }
}

const daycamp_listing_city = (params: RouteParams) => {
    return {
        title: t('META_TITLE_DAYCAMP_LISTING_CITY', {
            productType: toSentenceCase(t('DAYCAMPS')),
            city: params.city
        }),
        description: t('META_DESCRIPTION_DAYCAMP_LISTING_CITY', {
            productType: t('DAYCAMPS').toLowerCase(),
            ofProductType: t('OF_DAYCAMPS').toLowerCase(),
            city: params.city
        })
    }
}

const daycamp_profile = (params: RouteParams) => {
    const dayCamp = Products.listProducts.find(
        (product: ProductModel) => product.slug == params.product_slug
    )
    return {
        title: dayCamp
            ? t('META_TITLE_DAYCAMP_PROFILE', {
                  productType: toSentenceCase(t('DAYCAMPS')),
                  title: dayCamp.title,
                  organizer: dayCamp.owner_name,
                  year: dayCamp.dates[0].substring(0, 4),
                  city: dayCamp.city
              })
            : t('META_TITLE_DAYCAMP_PROFILE'),
        description: dayCamp
            ? t('META_DESCRIPTION_DAYCAMP_PROFILE', {
                  ofProductType: t('OF_DAYCAMPS').toLowerCase(),
                  title: dayCamp.title,
                  organizer: dayCamp.owner_name,
                  year: dayCamp.dates[0].substring(0, 4),
                  appTitle
              })
            : t('META_DESCRIPTION_DAYCAMP_PROFILE')
    }
}

const parent_knowledge_zone = () => {
    return {
        title: t('META_TITLE_BLOG_PARENT_MAIN', { appTitle }),
        description: t('META_DESCRIPTION_BLOG_PARENT_MAIN')
    }
}

const parent_knowledge_zone_category = (params: RouteParams) => {
    const tag = Tags.listTags.find(
        (tag: TagModel) => tag.slug == params.category
    )
    return {
        title: t('META_TITLE_BLOG_PARENT_CATEGORY_OR_ARTICLE', {
            appTitle,
            name: t(tag.display)
        }),
        description: t('META_DESCRIPTION_BLOG_PARENT_CATEGORY', {
            category: t(tag.display)
        })
    }
}

const parent_knowledge_zone_article = () => {
    const article = Blogs.currentArticle
    const title = article ? t(article.title) : ''
    const ogImage = article ? article.background_image : appOgImage

    return {
        title: t('META_TITLE_BLOG_PARENT_CATEGORY_OR_ARTICLE', {
            appTitle,
            name: title
        }),
        description: t('META_DESCRIPTION_BLOG_PARENT_ARTICLE', {
            title
        }),
        ogImage
    }
}

const business_zone = () => {
    return {
        title: t('META_TITLE_BLOG_BUSINESS_LANDING', { appTitle }),
        description: t('META_DESCRIPTION_BLOG_BUSINESS_LANDING', {
            appTitle
        })
    }
}

const business_knowledge_zone = () => {
    return {
        title: t('META_TITLE_BLOG_BUSINESS_MAIN', { appTitle }),
        description: t('META_DESCRIPTION_BLOG_BUSINESS_MAIN', {
            appTitle
        })
    }
}

const business_knowledge_zone_category = (params: RouteParams) => {
    const tag = Tags.listTags.find(
        (tag: TagModel) => tag.slug == params.category
    )
    return {
        title: t('META_TITLE_BLOG_BUSINESS_CATEGORY', {
            appTitle,
            categoryName: t(tag.display)
        }),
        description: t('META_DESCRIPTION_BLOG_BUSINESS_CATEGORY', {
            category: t(tag.display)
        })
    }
}

const business_knowledge_zone_article = () => {
    const article = Blogs.currentArticle
    const title = article ? t(article.title) : ''
    const ogImage = article ? article.background_image : appOgImage
    return {
        title: t('META_TITLE_BLOG_BUSINESS_ARTICLE', {
            appTitle,
            name: title
        }),
        description: t('META_DESCRIPTION_BLOG_BUSINESS_ARTICLE', {
            title,
            appTitle
        }),
        ogImage
    }
}

interface Meta {
    title: string
    description: string
    ogImage?: string
}

export const currentMeta: ComputedRef<Meta> = computed(() => {
    const { params } = router.currentRoute.value

    switch (router.currentRoute.value.name) {
        case 'parent_landing':
            return parent_landing()

        case 'daycamp_listing':
            return daycamp_listing()

        case 'daycamp_listing_city':
            return daycamp_listing_city(params)

        case 'daycamp_profile':
            return daycamp_profile(params)

        case 'parent_knowledge_zone':
            return parent_knowledge_zone()

        case 'parent_knowledge_zone_category':
            return parent_knowledge_zone_category(params)

        case 'parent_knowledge_zone_article':
            return parent_knowledge_zone_article()

        case 'business_zone':
            return business_zone()

        case 'business_knowledge_zone':
            return business_knowledge_zone()

        case 'business_knowledge_zone_category':
            return business_knowledge_zone_category(params)

        case 'business_knowledge_zone_article':
            return business_knowledge_zone_article()

        default:
            return {
                title: appTitle,
                description: appDescription,
                ogImage: appOgImage
            }
    }
})
