<template>
    <img v-if="source" :src="source" :draggable="draggable" :alt="props.alt" />
    <img v-else :src="getAsset('image_placeholder.svg')" :alt="props.alt" />
</template>
<script setup lang="ts">
import { getAsset } from '@/helpers/utils'
import { computed } from 'vue'

interface Props {
    r_src?: string
    a_src?: string
    draggable?: boolean
    alt?: string
    width?: number
    height?: number
    fit?: string
}
const props = withDefaults(defineProps<Props>(), {
    draggable: false
})
const assetParams = () => {
    const width = props.width ? `&width=${props.width}` : ''
    const height = props.height ? `&height=${props.height}` : ''
    return width + height
}
const handleAbsolutePathAsset = (asset: string) => {
    if (!asset) return
    const sizeParams = assetParams()
    if (!sizeParams) return asset
    const assetParserURL = 'https://assets-w3uombm66q-ez.a.run.app'
    return asset.includes('firebasestorage')
        ? `${assetParserURL}/${asset}${sizeParams}`
        : asset
}
const source = computed(() => {
    return (
        handleAbsolutePathAsset(props.a_src) ||
        (props.r_src &&
            new URL(`/src/assets/img/${props.r_src}`, import.meta.url).href)
    )
})
</script>
