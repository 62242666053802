import { t } from '@/i18n'
import {
    ProductModel,
    ProductRecrutationStatus,
    ProductType
} from '@/models/product.types'
import router from '@/router'
import Tags from '@/store/modules/Tags'
import { BreadcrumbModel } from './breadcrumbs'

export const defaultCity = 'Warszawa'

export const dayCampListingTexts = {
    dayCamps: t('DAY_CAMP'),
    classes: t('CLASSES'),
    age: t('AGE'),
    deadline: t('DEADLINE'),
    categories: t('CATEGORIES'),
    searchMovingMap: t('SEARCH_BY_MOVING_MAP'),
    findDayCampsInCity: t('FIND_DAYCAMPS_IN_YOUR_CITY'),
    description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', // TODO replace with real data when available 12.06.22
    placeholderText: t('NO_SEARCH_RESULTS_PLACEHOLDER'),
    placeholderFilterText: t('UNFORTUNATELY_NO_RESULTS_FOUND'),
    placeholderFilterButton: t('CLEAR_FILTERS'),
    timeForHolidays: t('TIME_FOR_HOLIDAYS'),
    ofResults: t('OF_RESULTS'),
    dayCampType: t('TYPE_OF_DAYCAMP')
}

export const dayCampCityDisplay = (type: ProductType, cityName: string) => {
    const types = {
        [ProductType.Class]: dayCampListingTexts.classes,
        [ProductType.DayCamp]: dayCampListingTexts.dayCamps
    }
    return `${types[type]} ${cityName}`
}

export const metaListingTitle = (
    type: ProductType,
    tag: string,
    cityName: string
) => {
    const types = {
        [ProductType.Class]: dayCampListingTexts.classes,
        [ProductType.DayCamp]: dayCampListingTexts.dayCamps
    }
    const tagName = t(Tags.listTags.find(({ id }) => id == tag).display)
    return `${types[type]} ${tagName} ${cityName}`
}

export const getDayCampListingBreadcrumbs = (
    type: ProductType,
    cityName: string
): BreadcrumbModel[] => {
    return [
        ...router.currentRoute.value.matched
            .map(r => {
                return {
                    display: r.meta.display as string,
                    action: () => {
                        router.push({ path: r.path })
                    }
                }
            })
            .filter((item: BreadcrumbModel) => item.display),
        {
            display: dayCampCityDisplay(type, cityName),
            action: () => {}
        }
    ]
}

export const goToListing = (type: ProductType) => {
    const names = {
        [ProductType.Class]: 'class_listing',
        [ProductType.DayCamp]: 'daycamp_listing'
    }
    router.push({
        name: names[type]
    })
}
export const goToCityDayCampListing = (cityName: string) => {
    router.push({
        name: 'daycamp_listing_city',
        params: {
            city: cityName
        }
    })
}
export const goToCityClassListing = (cityName: string) => {
    router.push({
        name: 'class_listing_city',
        params: {
            city: cityName
        }
    })
}

export const compareLocalizationCoordinates = (
    firstProduct: ProductModel,
    secondProduct: ProductModel
) => {
    return (
        firstProduct.localization_coordinates.long ==
            secondProduct.localization_coordinates.long &&
        firstProduct.localization_coordinates.lat ==
            secondProduct.localization_coordinates.lat
    )
}
export const getRecruitmentStatusSortPriority = (
    status: ProductRecrutationStatus
) => {
    const priorities = {
        [ProductRecrutationStatus.Draft]: 0,
        [ProductRecrutationStatus.Finished]: 1,
        [ProductRecrutationStatus.ToBeStarted]: 2,
        [ProductRecrutationStatus.Started]: 3
    }
    return priorities[status]
}
