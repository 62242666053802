import {
    articlesTable,
    productsTable,
    ownersTable,
    reservationsTable,
    tagsTable,
    usersTable
} from './supportTables'
import {
    articlesTabButtons,
    authorsTabButtons,
    businessesTabButtons,
    productTabButtons,
    categoryTabButtons,
    ownerTabButtons,
    reviewsTabButtons
} from './supportButtons'
import { SupportLayouts, SupportTabs } from '@/models/support.types'

export const supportLayouts: SupportLayouts = {
    users: {
        tabs: [
            {
                key: 'users',
                display: 'USERS',
                dataTable: usersTable,
                sideview: SupportTabs.Users
            }
        ]
    },
    activity: {
        tabs: [
            {
                key: 'owners',
                display: 'OWNERS',
                buttons: ownerTabButtons,
                dataTable: ownersTable,
                sideview: SupportTabs.Owners
            },
            {
                key: 'products',
                display: 'PRODUCTS',
                buttons: productTabButtons,
                dataTable: productsTable,
                sideview: SupportTabs.Products
            },
            {
                key: 'reservations',
                display: 'RESERVATIONS',
                dataTable: reservationsTable,
                sideview: SupportTabs.Reservations
            }
        ]
    },
    tags: {
        tabs: [
            {
                key: 'tags',
                display: 'TAGS',
                buttons: categoryTabButtons,
                dataTable: tagsTable,
                sideview: SupportTabs.Tags
            }
        ]
    },
    articles: {
        tabs: [
            {
                key: 'articles',
                display: 'ARTICLES',
                buttons: articlesTabButtons,
                dataTable: articlesTable,
                sideview: SupportTabs.Articles
            },
            {
                key: 'authors',
                display: 'AUTHORS',
                buttons: authorsTabButtons
            }
        ]
    },
    business_landing: {
        tabs: [
            {
                key: 'reviews',
                display: 'REVIEWS',
                buttons: reviewsTabButtons
            },
            {
                key: 'businesses',
                display: 'BUSINESSES',
                buttons: businessesTabButtons
            }
        ]
    }
}
