<template>
    <div>
        <div
            class="remove-text"
            v-text="t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_ACCOUNT')"
        />
        <PasswordInput
            class="remove-password"
            overrideClass="TextInputRoundedSimple"
            :field="state.removePasswordField"
            v-model="state.removePasswordField.value"
        />
        <button
            class="remove-button"
            v-text="t('REMOVE_ACCOUNT')"
            @click="removeAccount"
        />
    </div>
</template>

<script setup lang="ts">
import PasswordInput from 'shared/components/form/PasswordInput.vue'
import { client_password } from '@/helpers/formContent'
import { reactive } from 'vue'
import { t } from '@/i18n'
import ClientAccounts from '@/store/modules/ClientAccounts'
import toast from '@/helpers/toast'
import { checkClientPasswordRequirements } from '@/helpers/accounts'

const state = reactive({
    loading: false,
    removePasswordField: client_password({
        placeholder: t('CURRENT_ACCOUNT_PASSWORD')
    })
})

const removeAccount = async () => {
    try {
        const password = state.removePasswordField.value as string
        if (state.loading || !checkClientPasswordRequirements(password)) return
        state.loading = true
        await ClientAccounts.deleteAccount({
            payload: { password }
        })
        toast.success('TOAST_DELETE_ACCOUNT_SUCCESS')
        ClientAccounts.logOut()
    } catch (error) {
        toast.error('INCORRECT_PASSWORD')
    } finally {
        state.loading = false
    }
}
</script>

<style scoped lang="scss">
.remove-text {
    margin-bottom: 2rem;
}
.remove-password {
    margin-bottom: 2rem;
}
.remove-button {
    @extend .wide-button;
}

@include touch {
    .remove-text {
        @include is-body-2-mobile;
    }
}
</style>
