import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Accounts from '@/store/modules/Accounts'
import supportRoutes from '@/router/supportRoutes'
import accountRoutes from '@/router/accountRoutes'
import { t } from '@/i18n'
import { appTitle } from '@/helpers/app'
import { ProductType } from '@/models/product.types'
import ClientAccounts from '@/store/modules/ClientAccounts'

const UserWrapper = () => import('@/wrappers/UserWrapper.vue')
const BlogWrapper = () => import('@/wrappers/BlogWrapper.vue')
const BlogLanding = () => import('@/views/Blog/BlogLanding.vue')
const BlogMain = () => import('@/views/Blog/BlogMain.vue')
const BlogCategory = () => import('@/views/Blog/BlogCategory.vue')
const BlogSearch = () => import('@/views/Blog/BlogSearch.vue')
const BlogArticle = () => import('@/views/Blog/BlogArticle.vue')
const DayCampProfile = () => import('@/views/DayCampProfile/DayCampProfile.vue')
const DayCampListing = () => import('@/views/DayCampListing/DayCampListing.vue')
const SupportWrapper = () => import('@/wrappers/SupportWrapper.vue')
const SupportLogin = () => import('@/views/Support/SupportLogin.vue')
const ParentLanding = () => import('@/views/ParentLanding/ParentLanding.vue')
const NotFound = () => import('@/views/NotFound/NotFound.vue')
const PrivacyPolicyAndRegulations = () =>
    import(
        '@/views/PrivacyPolicyAndRegulations/PrivacyPolicyAndRegulations.vue'
    )
const AccountPasswordReset = () =>
    import('@/views/Account/AccountPasswordReset.vue')
const AccountWrapper = () => import('@/wrappers/AccountWrapper.vue')
const Reservation = () => import('@/views/Reservation/Reservation.vue')
const ReservationPayment = () =>
    import('@/views/Reservation/ReservationPayment.vue')
const ReservationThanks = () =>
    import('@/views/Reservation/ReservationThanks.vue')
const ReservationFailed = () =>
    import('@/views/Reservation/ReservationFailed.vue')

export const routes: Array<RouteRecordRaw> = [
    { path: '/:pathMatch(.*)', component: NotFound },
    {
        path: '/',
        component: BlogWrapper,
        meta: {
            display: appTitle
        },
        children: [
            {
                path: '',
                name: 'parent_landing',
                component: ParentLanding
            },
            {
                path: '/polkolonie',
                name: 'daycamp_listing',
                component: DayCampListing,
                props: true,
                meta: {
                    mobileBackButton: false,
                    mobileSearchVisible: false,
                    searchVisible: false,
                    productSearchVisible: true,
                    productType: ProductType.DayCamp
                }
            },
            {
                path: '/zajecia',
                name: 'class_listing',
                component: DayCampListing,
                props: true,
                meta: {
                    mobileBackButton: false,
                    mobileSearchVisible: false,
                    searchVisible: false,
                    productSearchVisible: true,
                    productType: ProductType.Class
                }
            },
            {
                path: '/polkolonie/:city',
                name: 'daycamp_listing_city',
                component: DayCampListing,
                props: true,
                meta: {
                    mobileBackButton: false,
                    mobileSearchVisible: false,
                    searchVisible: false,
                    productSearchVisible: true,
                    productType: ProductType.DayCamp
                }
            },
            {
                path: '/zajecia/:city',
                name: 'class_listing_city',
                component: DayCampListing,
                props: true,
                meta: {
                    mobileBackButton: false,
                    mobileSearchVisible: false,
                    searchVisible: false,
                    productSearchVisible: true,
                    productType: ProductType.Class
                }
            },
            {
                path: '/:product_slug/:owner_slug/polkolonie/:city',
                name: 'daycamp_profile',
                component: DayCampProfile,
                meta: {
                    mobileBackButton: true,
                    mobileSearchVisible: false,
                    searchVisible: false,
                    productSearchVisible: true,
                    display: 'TAG_DAY_CAMP'
                }
            },
            {
                path: '/:product_slug/:owner_slug/zajecia/:city',
                name: 'class_profile',
                component: DayCampProfile,
                meta: {
                    mobileBackButton: true,
                    mobileSearchVisible: false,
                    searchVisible: false,
                    productSearchVisible: true,
                    display: 'CLASSES'
                }
            },
            {
                path: '/organizator/:owner_slug',
                name: 'owner_profile',
                component: DayCampProfile,
                meta: {
                    mobileBackButton: true,
                    mobileSearchVisible: false,
                    searchVisible: false,
                    productSearchVisible: true
                }
            },
            {
                path: '/polityka-prywatnosci',
                name: 'privacy_policy',
                component: PrivacyPolicyAndRegulations
            },
            {
                path: '/regulamin',
                name: 'regulations',
                component: PrivacyPolicyAndRegulations
            },
            {
                path: '/konto',
                name: 'account',
                component: AccountWrapper,
                children: accountRoutes,
                meta: { validation: 'user' }
            },
            {
                path: '/konto/reset-hasla/:token',
                name: 'account_password_reset',
                component: AccountPasswordReset
            },
            {
                path: '/rezerwacja',
                name: 'reservation_form',
                component: Reservation
            },
            {
                path: '/rezerwacja/platnosc',
                name: 'reservation_payment',
                component: ReservationPayment
            },
            {
                path: '/rezerwacja/zakonczona',
                name: 'reservation_thanks',
                component: ReservationThanks
            },
            {
                path: '/rezerwacja/blad',
                name: 'reservation_failed',
                component: ReservationFailed
            }
        ]
    },
    {
        path: '/strefa-biznesu',
        component: BlogWrapper,
        meta: {
            validation: 'blog_business',
            type: 'business',
            display: t('APPNAME_FOR_BUSINESS', { appTitle })
        },
        children: [
            {
                path: '',
                name: 'business_zone',
                meta: {
                    mobileBackButton: false,
                    mobileSearchVisible: false,
                    searchVisible: false
                },
                component: BlogLanding
            },
            {
                path: 'strefa-wiedzy',
                name: 'business_knowledge_zone',
                meta: {
                    mobileBackButton: false,
                    mobileSearchVisible: true,
                    searchVisible: true
                },
                component: BlogMain
            },
            {
                path: 'strefa-wiedzy/:category',
                name: 'business_knowledge_zone_category',
                meta: {
                    mobileBackButton: true,
                    mobileSearchVisible: true,
                    searchVisible: true
                },
                component: BlogCategory
            },
            {
                path: 'strefa-wiedzy/wyszukiwanie',
                name: 'business_knowledge_zone_search',
                meta: {
                    mobileBackButton: true,
                    mobileSearchVisible: true,
                    searchVisible: true
                },
                component: BlogSearch
            },
            {
                path: 'strefa-wiedzy/:category/:id',
                name: 'business_knowledge_zone_article',
                meta: {
                    display: 'KNOWLEDGE_ZONE',
                    mobileBackButton: true,
                    mobileSearchVisible: true,
                    searchVisible: true
                },
                component: BlogArticle
            }
        ]
    },
    {
        path: '/strefa-rodzica',
        name: 'parent_zone',
        component: BlogWrapper,
        meta: {
            validation: 'blog',
            type: 'parent',
            display: appTitle
        },
        children: [
            {
                path: 'strefa-wiedzy',
                name: 'parent_knowledge_zone',
                meta: {
                    mobileBackButton: false,
                    mobileSearchVisible: true,
                    searchVisible: true
                },
                component: BlogMain
            },
            {
                path: 'strefa-wiedzy/:category',
                name: 'parent_knowledge_zone_category',
                meta: {
                    mobileBackButton: true,
                    mobileSearchVisible: true,
                    searchVisible: true
                },
                component: BlogCategory
            },
            {
                path: 'strefa-wiedzy/wyszukiwanie',
                name: 'parent_knowledge_zone_search',
                meta: {
                    mobileBackButton: true,
                    mobileSearchVisible: true,
                    searchVisible: true
                },
                component: BlogSearch
            },
            {
                path: 'strefa-wiedzy/:category/:id',
                name: 'parent_knowledge_zone_article',
                meta: {
                    mobileBackButton: true,
                    mobileSearchVisible: true,
                    searchVisible: true,
                    display: 'KNOWLEDGE_ZONE'
                },
                component: BlogArticle
            }
        ]
    },
    {
        path: '/support',
        name: 'support',
        component: SupportWrapper,
        children: supportRoutes,
        meta: { validation: 'support' }
    },
    {
        path: '/support-login',
        name: 'support_login',
        component: SupportLogin
    }
]
const router = createRouter({
    scrollBehavior: (to, _from, _savedPosition): any => {
        if (to.name == 'daycamp_profile') return { top: 0 }
        if (to.hash)
            return {
                el: to.hash,
                top: 100,
                behavior: 'smooth'
            }
        return { top: 0, behavior: 'smooth' }
    },
    linkExactActiveClass: 'is-active',
    history: createWebHistory(''),
    routes
})
const loggedUserCase = (validation: string): boolean => {
    return validation === 'user' && !ClientAccounts.isLoggedIn
}
const supportCase = (validation: string): boolean => {
    return validation == 'support' && !Accounts.isSupport
}
router.beforeEach((to, from, next) => {
    const validation = (to.meta?.validation as string) || ''
    switch (true) {
        case loggedUserCase(validation):
            next({ path: '/' })
            break
        case supportCase(validation):
            next({ path: '/support-login' })
            break
        default:
            next()
            break
    }
})
export default router
