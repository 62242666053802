import { useToast } from 'vue-toastification'
import { t } from '@/i18n'
const translate = (title: string) => {
    return t(title)
}
export const toastOptions = {
    transition: 'Vue-Toastification__fade',
    maxToasts: 20,
    newestOnTop: true,
    position: 'bottom-right'
}

export default {
    error: (title: string) => useToast().error(translate(title)),
    success: (title?: string) =>
        useToast().success(translate(title ? title : 'SUCCESS')),
    warning: (title: string) => useToast().warning(translate(title)),
    info: (title: string) => useToast().info(translate(title))
}
