import { generateUUID } from '../helpers/utils'

interface FileModel {
    url: string
    name: string
    file?: File
}
type UserId = string | number

// Uploads native file objects to firebase
// Returns array of asset urls
export const uploadFiles = async ({
    storage,
    files,
    user_id
}: {
    storage: any
    files: any
    user_id: UserId
}): Promise<string[]> => {
    const ref = storage()
        .ref()
        .child('cdn')
        .child('image')
        .child(`${user_id || 'public'}`)
    const promises = files.map((file: FileModel) => {
        const uuid = generateUUID()
        const metadata = {
            customMetadata: {
                fileName: file.name
            }
        }
        return ref
            .child(uuid)
            .put(file, metadata)
            .then(snapshot => snapshot.ref.getDownloadURL())
            .catch(() => 'error')
    })
    return (await Promise.all(promises)).filter(url => url != 'error')
}

// Creates array of prepared file objects from input event
export const addFilesFromEvent = async ({
    event,
    alertFn
}: {
    event: any
    alertFn?: () => void
}) => {
    const files = event.target.files
        ? [...event.target.files]
        : [...event.dataTransfer.files]
    const tooBigFileIndex = files.findIndex(file => file.size > 5242880)
    if (tooBigFileIndex != -1) alertFn && alertFn()
    return files
        .filter(file => file.size < 5242880)
        .map(file => {
            return { file, name: file.name }
        })
}

// Creates array of asset urls from event
export const uploadFilesFromEvent = async ({
    storage,
    event,
    user_id
}: {
    storage: any
    event: any
    user_id: UserId
}): Promise<string[]> => {
    const files = await addFilesFromEvent({ event })
    return uploadFiles({
        storage,
        files: files.map(({ file }) => file),
        user_id
    })
}

const isDataURL = (string: string) => {
    return string ? string.toString().includes('blob:') : false
}

// Uploads localfiles if not uploaded already. Returns array of asset urls
export const uploadLocalFiles = async ({
    storage,
    files,
    user_id
}: {
    storage: any
    files: FileModel[]
    user_id: UserId
}): Promise<string[]> => {
    if (!files.length) return []
    const promises = files.map(async ({ file, url }: FileModel) => {
        if (isDataURL(url)) {
            const assetUrls: any = await uploadFiles({
                storage,
                files: [file],
                user_id
            })
            url = assetUrls[0]
        }
        return url
    })
    return Promise.all(promises)
}

export const getFile = async ({ storage, url }) => {
    try {
        const ref = storage().refFromURL(url)
        const meta = await ref.getMetadata()
        const path = await ref.getDownloadURL()
        const object = {
            url: path,
            name: meta?.customMetadata?.fileName || path,
            type: meta?.contentType
        }
        return object
    } catch (error) {
        return null
    }
}
