<template>
    <input
        class="ColorInput--input"
        type="color"
        :name="field.name"
        :class="{ 'is-danger': error }"
        :value="modelValue"
        @input.prevent="input"
    />
</template>

<script setup lang="ts">
import { FieldModel } from 'shared/models/formContent.types'
const props = defineProps({
    error: {
        type: Boolean,
        default: false
    },
    field: {
        type: Object,
        default: () => ({})
    },
    modelValue: String
})
const emit = defineEmits<{
    (e: 'update', update: FieldModel): void
    (e: 'update:field', update: FieldModel): void
    (e: 'update:modelValue', update: FieldModel): void
}>()

const input = (event: any): void => {
    const value = event.target.value
    emit('update', { ...props.field, value })
    emit('update:field', { ...props.field, value })
    emit('update:modelValue', value)
}
</script>

<style scoped></style>
