import { NumberID, URL } from '../../models/generic.types'

export type AccountID = NumberID

export const type_support = 'support'
export const type_school = 'school'
export const type_parent = 'parent'
export const type_teacher = 'teacher'
export const type_school_network = 'school-network'
export type RoleType =
    | typeof type_parent
    | typeof type_school
    | typeof type_teacher
    | typeof type_support
    | typeof type_school_network

export type Roles = {
    [type_parent]: boolean
    [type_teacher]: boolean
    [type_school]: boolean
    [type_school_network]: boolean
    [type_support]: boolean
}
export interface RoleBaseModel {
    account_id: AccountID
    id: NumberID
    avatar: URL
    description: string
    display: string
    jwt: string
    school_name: string
    is_active: boolean
    type: RoleType
    must_accept?: boolean
    claims?: any
    must_change_name?: boolean
    must_change_password?: boolean
    override?: boolean
}
export interface ParentRoleModel extends RoleBaseModel {
    /** kid - kid ID */
    kid: number
    /** pid - parent ID */
    pid: number
    /** sid - school ID */
    sid: number
    /** nid - school network ID */
    nid: number
    kid_avatar: string
    kid_birthday: string
    kid_gender: string
    kid_name: string
    kid_surname: string
}
export interface SchoolRoleModel extends RoleBaseModel {
    /** sid - school ID */
    sid: number
    requires_onboarding: boolean
    school_name: string
}
export interface TeacherRoleModel extends RoleBaseModel {
    /** tid - teacher ID */
    tid: number
    /** sid - school ID */
    sid: number
}
export interface ShoolNetworkRoleModel extends RoleBaseModel {
    /** nid - school network ID */
    nid: number
}
export interface SupportRoleModel extends RoleBaseModel {
    /** nid - school network ID */
    nid: number
}
export type RoleModel = ParentRoleModel &
    SchoolRoleModel &
    TeacherRoleModel &
    ShoolNetworkRoleModel &
    SupportRoleModel

export interface LivekidAccountModel {
    avatar: string
    id: number
    language: string
    mail: string
    name: string
    surname: string
    people_id: string | null
    phone: string
    roles: RoleModel[]
}
export const getCurrentAccount = (
    accounts: LivekidAccountModel[],
    account_id: number
) => {
    if (!account_id || !accounts) return
    return accounts.find(({ id }) => id == account_id)
}
export const getCurrentRole = (
    accounts: LivekidAccountModel[],
    role_id: number
) => {
    if (role_id == undefined || !accounts) return
    const account = accounts.find(({ roles }) =>
        roles.some(({ id, nid }) => id == role_id || nid == role_id)
    )
    return account?.roles.find(({ id, nid }) => id == role_id || nid == role_id)
}
