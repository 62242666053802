<template>
    <div
        :class="[
            `has-text-${color}`,
            `has-text-weight-${weight}`,
            `is-size-${size}`,
            { '-disabled': disabled },
            { 'is-nav': isNav }
        ]"
        :style="{
            userSelect: userSelect ? 'auto' : 'none'
        }"
        class="text-button"
    >
        <div class="flex-row">
            <slot />
            <p
                v-if="!iconLeft"
                :class="`has-text-${color}`"
                :style="{ color: `${hashColor} !important` }"
                v-text="t(display)"
            />
            <Icon
                v-if="icon"
                :icon="icon"
                :color="icon_color"
                :size="icon_size || size - 2"
                class="mr-1"
                :class="{
                    'ml-2': userProfile,
                    chevron: iconChevron,
                    'icon-rotate': iconRotate
                }"
            />
            <p
                v-if="iconLeft"
                :class="`has-text-${color}`"
                :style="{ color: `${hashColor} !important` }"
                v-text="t(display)"
            />
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { t } from '@/i18n'
import Icon from 'shared/components/utils/Icon.vue'
export default defineComponent({
    props: {
        display: {
            type: String,
            default: ''
        },
        icon: {
            type: Object
        },
        weight: {
            type: String,
            default: 'semibold'
        },
        size: {
            type: String,
            default: '6'
        },
        color: {
            type: String,
            default: 'text'
        },
        hashColor: {
            type: String,
            default: ''
        },
        icon_color: {
            type: String,
            default: 'primary'
        },
        icon_size: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        iconLeft: {
            type: Boolean,
            default: true
        },
        userSelect: {
            type: Boolean,
            default: false
        },
        userProfile: {
            type: Boolean,
            default: false
        },
        isNav: {
            type: Boolean,
            default: false
        },
        iconChevron: {
            type: Boolean,
            default: false
        },
        iconRotate: {
            type: Boolean,
            default: false
        }
    },
    components: { Icon },
    setup() {
        return { t }
    }
})
</script>
<style lang="scss" scoped>
.text-button {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &.-disabled {
        cursor: default;
    }
    p {
        margin: 5px 0;
    }
    &.is-nav {
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
    }
    .chevron {
        margin-right: 0 !important;
        transition: transform 0.5s ease-in-out;

        &.icon-rotate {
            transform: rotate(-180deg);
        }
    }
}
.text-button:hover {
    p {
        text-decoration: underline;
        @include touch {
            text-decoration: none;
        }
    }
    &.-disabled {
        p {
            text-decoration: none;
        }
    }
    &.is-nav {
        border-bottom: 3px solid $main-blue;
        p {
            text-decoration: none;
        }
    }
}
</style>
