import { computed } from 'vue'

export const sortAlphabetically = (a: string, b: string) => a.localeCompare(b)
export const getAsset = (relativePath: string) => {
    return new URL(`/src/assets/img/${relativePath}`, import.meta.url).href
}
export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const generateUUID = () => {
    let d = new Date().getTime()
    if (
        typeof performance !== 'undefined' &&
        typeof performance.now === 'function'
    ) {
        d += performance.now()
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            const r = (d + Math.random() * 16) % 16 | 0
            d = Math.floor(d / 16)
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
        }
    )
}

export const getPriceValueFromBackend = (value: number = 0): string => {
    if (value == undefined || value == null) return '0'
    return (Math.round(Number(value) * 100) / 10000).toFixed(2)
}

export const getPriceToBackend = (value: string): number => {
    if (value == undefined || value == null) return 0
    return Math.floor(Math.round(Number(value) * 10000) / 100)
}
export const isMobile = computed(() => window.innerWidth < 1024)

export const toSentenceCase = (text: string) => {
    return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
}
export const getCurrentUrl = () => {
    return window.location.href
}
export const containsOnlyDigits = (text: string) => {
    return /^\d+$/.test(text)
}
export const booleanEmoji = (value: boolean): string => {
    return value ? '✅' : '❌'
}

export const setAgeOptions = () =>
    Array(18)
        .fill(1)
        .map((_, index) => ({
            value: index + 1,
            display: `TAG_${index + 1}_YEARS_OLD`
        }))

export const getBiggestProperty = (arrayOfObjects: any[], propertyName) => {
    const array = [...arrayOfObjects]
    array.sort((a, b) => a[propertyName] - b[propertyName])
    const biggest = array[array.length - 1][propertyName] | 0
    return array.length > 0 ? biggest : 0
}

export const containsSpecialCharacters = (str: string) => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return format.test(str)
}

export const containsDigits = (str: string) => {
    const format = /\d/
    return format.test(str)
}

export const addAsterisk = (str: string) => {
    return `${str}*`
}

export const getFullName = (name: string, surname: string) => {
    return `${name} ${surname}`
}
