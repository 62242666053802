<template>
   <div class="loading-body">
      <div class="container has-text-centered">
         <h1 class="title" v-text="t('LOADING_DOTS')" />
      </div>
   </div>
</template>
<script lang="ts">
import { t } from '@/i18n'
export default {
   setup() {
      return {
         t
      }
   }
}
</script>
<style scoped lang="scss">
.loading-body {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   width: 100%;
}
</style>
