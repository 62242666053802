import {
    attachment_input,
    date_picker_input,
    group_label,
    multi_select_input,
    single_select_input,
    switch_input,
    text_input,
    time_input
} from 'shared/models/formContent.types'
import { addAsterisk, capitalize, toSentenceCase } from 'shared/helpers/utils'
import {
    getGroupSizeTags,
    tagsByType,
    tagsToSelectOptions
} from '@/helpers/tags'
import { TagModel, TagType, tag_type } from '@/models/tag.types'
import { ownerSelectOptions } from '@/helpers/owners'
import { getWeekDayDisplay, months } from 'shared/helpers/date'
import { ClassWeekDay } from '@/models/class.types'
import { datePickerPlaceholder } from '@/helpers/support/supportForms'
import Owners from '@/store/modules/Owners'
import { t } from '@/i18n'

// GENERIC
export const name = (custom = {}) =>
    text_input({
        title: 'Name',
        key: 'name',
        placeholder: 'Name',
        ...custom
    })
export const surname = (custom = {}) =>
    text_input({
        title: 'Surname',
        key: 'surname',
        placeholder: 'Surname',
        ...custom
    })
export const email = (custom = {}) =>
    text_input({
        title: 'E-mail',
        key: 'email',
        placeholder: 'E-mail',
        ...custom
    })
export const password = (custom = {}) =>
    text_input({
        title: 'Password',
        key: 'password',
        scope: 'password',
        ...custom
    })
export const phone = (custom = {}) =>
    text_input({
        title: 'Phone number',
        key: 'phone',
        placeholder: '+48',
        ...custom
    })
export const country = (custom = {}) =>
    single_select_input({
        title: 'Country',
        key: 'country',
        placeholder: 'Choose country',
        value: 'poland',
        options: [
            {
                display: 'Poland',
                value: 'poland'
            }
        ],
        ...custom
    })
export const district = (custom = {}) =>
    text_input({
        title: 'District',
        key: 'district',
        value: '',
        ...custom
    })
export const voivodeship = (custom = {}) =>
    single_select_input({
        title: 'Voivodeship',
        key: 'voivodeship',
        value: '',
        search_options: true,
        options: [
            'dolnośląskie',
            'kujawsko-pomorskie',
            'lubelskie',
            'lubuskie',
            'łódzkie',
            'małopolskie',
            'mazowieckie',
            'opolskie',
            'podkarpackie',
            'podlaskie',
            'pomorskie',
            'śląskie',
            'świętokrzyskie',
            'warmińsko-mazurskie',
            'wielkopolskie',
            'zachodniopomorskie'
        ].map(value => ({ value, display: capitalize(value) })),
        ...custom
    })
export const address_city = (custom = {}) =>
    text_input({
        title: 'City',
        key: 'address_city',
        placeholder: 'City',
        ...custom
    })
export const address_post_code = (custom = {}) =>
    text_input({
        title: 'Postal code',
        key: 'address_post_code',
        placeholder: 'Postal code',
        ...custom
    })
export const address_street = (custom = {}) =>
    text_input({
        title: 'Street',
        key: 'address_street',
        placeholder: 'Street',
        ...custom
    })
export const address_street_number = (custom = {}) =>
    text_input({
        title: 'Street Number',
        key: 'address_street_number',
        placeholder: 'Street NR',
        ...custom
    })
export const building_number = (custom = {}) =>
    text_input({
        title: 'Building number',
        key: 'building_number',
        placeholder: 'Building number',
        ...custom
    })
export const apartment_number = (custom = {}) =>
    text_input({
        title: 'Apartment number',
        key: 'apartment_number',
        placeholder: 'Apartment number',
        ...custom
    })

export const nip = (custom = {}) =>
    text_input({
        title: 'NIP',
        key: 'nip',
        placeholder: 'NIP',
        ...custom
    })
export const nrb = (custom = {}) =>
    text_input({
        title: 'NRB',
        key: 'nrb',
        placeholder: 'Bank account number',
        ...custom
    })
export const company_name = (custom = {}) =>
    text_input({
        title: 'Company name',
        key: 'company_name ',
        placeholder: 'Company name',
        ...custom
    })
// OWNERS
export const commission = (custom = {}) =>
    text_input({
        title: 'Commission',
        key: 'commission',
        value: 0,
        placeholder: 'Commission (%)',
        scope: 'int',
        ...custom
    })
export const title = (custom = {}) =>
    text_input({
        title: 'Title',
        key: 'title',
        ...custom
    })

// TAGS
export const daycamp_category_tags = (custom = {}) =>
    multi_select_input({
        title: 'Category tags',
        key: 'category_tags',
        search_options: true,
        options: tagsToSelectOptions(
            tagsByType(tag_type.product_category as TagType) // TODO: filter by ancestor 4.08.22
        ),
        ...custom
    })
export const class_category_tags = (custom = {}) =>
    multi_select_input({
        title: 'Category tags',
        key: 'category_tags',
        search_options: true,
        options: tagsToSelectOptions(
            tagsByType(tag_type.product_category as TagType) // TODO: filter by ancestor 4.08.22
        ),
        ...custom
    })
export const age_tags = (custom = {}) =>
    multi_select_input({
        title: 'Age tags',
        key: 'age_tags',
        search_options: true,
        options: tagsToSelectOptions(
            tagsByType(tag_type.product_group_age as TagType)
        ),
        ...custom
    })
export const other_tags = (custom = {}) =>
    multi_select_input({
        title: 'Other tags',
        key: 'other_tags',
        search_options: true,
        options: tagsToSelectOptions(tagsByType(tag_type.other as TagType)),
        ...custom
    })
export const is_published = (custom = {}) =>
    switch_input({
        title: 'Is published?',
        key: 'is_published',
        ...custom
    })

// OWNERS
export const owner_id = (custom = {}) =>
    single_select_input({
        title: 'Owner',
        key: 'owner_id',
        search_options: true,
        options: ownerSelectOptions(),
        onUpdate: ownerId => {
            Owners.SET_CURRENT_OWNER(
                Owners.listOwners.find(({ id }) => id == ownerId)
            )
        },
        ...custom
    })
export const description = (custom = {}) =>
    text_input({
        title: 'Description',
        key: 'description',
        ...custom
    })

// CLASSES
export const group_size = (custom = {}) =>
    single_select_input({
        title: 'Number of children in groups',
        key: 'group_size',
        placeholder: 'Choose',
        dontSort: true,
        options: getGroupSizeTags().map((tag: TagModel) => ({
            display: tag.display,
            value: tag.id
        }))
    })
export const class_schedule = (day: ClassWeekDay) => ({
    week_day: single_select_input({
        title: 'Day of the week',
        key: 'week_day',
        options: Array(7)
            .fill(0)
            .map((_, i) => ({
                display: getWeekDayDisplay(i),
                value: i
            })),
        placeholder: 'Day of the week',
        dontSort: true,
        size: 3,
        value: day.week_day
    }),
    start_time: time_input({
        title: 'Start time',
        key: 'start_time',
        placeholder: '00:00',
        size: 2,
        value: day.start_time
    }),
    end_time: time_input({
        title: 'End time',
        key: 'end_time',
        placeholder: '00:00',
        size: 2,
        value: day.end_time
    })
})

export const class_schedule_range = () => ({
    start_date: date_picker_input({
        title: 'From (first classes)',
        key: 'start_date',
        placeholder: datePickerPlaceholder,
        size: 2
    }),
    end_date: date_picker_input({
        title: 'To (last classes)',
        key: 'end_date',
        placeholder: datePickerPlaceholder,
        size: 2
    }),
    exceptions: date_picker_input({
        title: 'Exceptions',
        key: 'exceptions',
        placeholder: datePickerPlaceholder,
        size: 2,
        autoApply: false,
        multiDates: true
    })
})
export const verified = (custom = {}) =>
    switch_input({
        title: 'Verified',
        key: 'verified',
        ...custom
    })

// client forms
export const email_address = (custom = {}) =>
    email({
        label: t('ADDRESS_EMAIL_*'),
        placeholder: t('TYPE_YOUR_EMAIL_ADDRESS'),
        ...custom
    })
export const client_password = (custom = {}) =>
    password({
        label: `${t('PASSWORD')}*`,
        placeholder: t('TYPE_YOUR_PASSWORD'),
        ...custom
    })
export const client_name = (custom = {}) =>
    name({
        title: t('NAME'),
        placeholder: t('NAME'),
        ...custom
    })
export const client_surname = (custom = {}) =>
    surname({
        title: t('SURNAME'),
        placeholder: t('SURNAME'),
        ...custom
    })
export const client_phone = (custom = {}) =>
    phone({
        title: t('PHONE_NUMBER'),
        placeholder: t('PHONE_NUMBER'),
        ...custom
    })
export const client_avatar = (custom = {}) =>
    attachment_input({
        key: 'avatar',
        single: true,
        fileNameHidden: true,
        title: t('PROFILE_PICTURE'),
        placeholder: t('CLICK_TO_CHOOSE_PICTURE_FROM_DEVICE'),
        ...custom
    })
export const client_kid_profile = (custom = {}) => ({
    name: client_name({
        key: 'name',
        title: t('NAME'),
        overrideClass: 'TextInputRoundedSimple',
        required: true
    }),
    surname: client_surname({
        key: 'surname',
        title: t('SURNAME'),
        overrideClass: 'TextInputRoundedSimple',
        required: true
    }),
    birthdate: date_picker_input({
        title: addAsterisk(t('BIRTH_DATE')),
        placeholder: t('PARTICIPANT_DATE_OF_BIRTH')
    })
})
