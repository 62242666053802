<template>
    <div class="modal is-active" style="z-index: 10000">
        <div class="modal-background" @click="close"></div>
        <div
            class="modal-content"
            :style="{ width: almostFullScreen ? '90%' : `${width}px` }"
        >
            <div
                class="box model-content-wrap p-4"
                :style="{ background: '$grey-light' }"
            >
                <div class="delete is-hidden-tablet" @click="close"></div>
                <div v-if="title" class="px-4 pt-2 pb-4">
                    <h3 class="title is-size-6" v-text="t(title)" />
                </div>
                <div v-if="title" class="divider" />
                <div class="modal-slot-content">
                    <slot />
                </div>
                <div v-if="filteredActions.length > 0" class="divider" />
                <div
                    v-if="filteredActions.length > 0"
                    class="level px-4 pb-1 pt-4"
                >
                    <div class="level-right buttons">
                        <Button
                            v-for="action in filteredActions"
                            :key="action.event.key"
                            :display="action.display"
                            :color="action.color"
                            :class="action.class"
                            :loading="action.loading"
                            :disabled="action.disabled"
                            @click="actionEmitted(action)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import Button from '../utils/Button.vue'
import { ActionModel, actionEmitted } from '../../helpers/actions'
import { t } from '@/i18n'
export default defineComponent({
    props: {
        title: { type: String, default: '' },
        actions: { type: Array as PropType<ActionModel[]>, default: () => [] },
        width: { type: Number, default: 500 },
        almostFullScreen: { type: Boolean, default: false }
    },
    components: { Button },
    setup(props, { emit }) {
        const filteredActions = computed(() => {
            return props.actions.filter(a => !a.hidden)
        })
        const close = (): void => {
            emit('close')
        }
        return { close, filteredActions, actionEmitted, t }
    }
})
</script>
<style lang="scss" scoped>
.modal-content {
    overflow: hidden;
    max-height: 90%;

    @media (max-width: 490px) {
        max-height: 100%;
        height: 100%;
        max-width: 100%;
    }
}
.model-content-wrap {
    overflow: hidden;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.modal-slot-content {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1rem;
    max-height: 100%;
}
.delete {
    margin-left: auto;
    -webkit-tap-highlight-color: transparent;
}
.buttons button {
    font-size: 14px !important;
}
.divider {
    width: calc(100% + 2rem);
    position: relative;
    left: -1rem;
    border-bottom: 1px solid $border-light;
}
.delete-button {
    color: $danger !important;
}
</style>
