<template>
    <div class="uploader">
        <Loading v-if="state.loading.upload" />
        <div v-else-if="!state.uploadFinished" class="upload-form">
            <input class="upload-input" type="file" @change="fileChange" />
            <button
                class="upload-button"
                :class="{ disabled: !state.file }"
                :disabled="!state.file"
                @click="uploadFile"
            >
                Upload
            </button>
        </div>
        <div v-else class="upload-done" v-text="`Upload finished`" />
    </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import Loading from '@/components/utils/Loading.vue'
import toast from '@/helpers/toast'
import { t } from '@/i18n'

interface Props {
    uploadFunction(file: File): Promise<void>
}
const props = defineProps<Props>()

const state = reactive({
    file: null,
    uploadFinished: false,
    loading: {
        upload: false
    }
})

const fileChange = e => {
    state.file = e.target.files[0]
}

const uploadFile = async () => {
    try {
        state.loading.upload = true
        await props.uploadFunction(state.file)
        state.uploadFinished = true
    } catch (err) {
        toast.error(t('SOMETHING_WENT_WRONG'))
    } finally {
        state.loading.upload = false
    }
}
</script>

<style scoped lang="scss">
.upload-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.upload-input {
    margin-bottom: 5rem;
    width: 200px;
}
.upload-button {
    @extend .primary-button-admin;
    @extend .is-button-text-1-web;
    width: fit-content;

    &.disabled {
        background-color: gray;
        border-color: gray;
        cursor: default;
    }
}
</style>
