import { FieldModel } from 'shared/models/formContent.types'
import { ClassSchedule } from './class.types'
import { OwnerDaysSchedule } from './owner.types'
import { ProductFaqModel } from './product.types'

export const type_staff_input = 'staff-input'
export const type_class_schedule_input = 'class-schedule-input'
export const type_owner_schedule_input = 'owner-schedule-input'
export const type_faq_input = 'faq-input'

export interface StaffFieldModel extends FieldModel {
    type: typeof type_staff_input
    value?: []
}
export interface ClassScheduleFieldModel extends FieldModel {
    type: typeof type_class_schedule_input
    value?: Partial<Omit<ClassSchedule, 'created_at' | 'id' | 'product_id'>>
}
export interface OwnerScheduleFieldModel extends FieldModel {
    type: typeof type_owner_schedule_input
    value?: Partial<OwnerDaysSchedule>
}
export interface FAQFieldModel extends FieldModel {
    type: typeof type_faq_input
    presetItems: ProductFaqModel[]
    value?: ProductFaqModel[]
}

export const staff_input = (
    custom: Partial<StaffFieldModel> = {}
): StaffFieldModel => ({
    key: '',
    title: '',
    type: type_staff_input,
    value: [],
    ...custom
})
export const class_schedule_input = (
    custom: Partial<ClassScheduleFieldModel> = {}
): ClassScheduleFieldModel => ({
    key: '',
    title: '',
    type: type_class_schedule_input,
    value: {
        start_date: '',
        end_date: '',
        week_days: [],
        exceptions: []
    },
    ...custom
})

const defaultOwnerScheduleDay = {
    is_open: true,
    open_at: 0,
    close_at: 0
}
export const owner_schedule_input = (
    custom: Partial<OwnerScheduleFieldModel> = {}
): OwnerScheduleFieldModel => ({
    key: '',
    title: '',
    type: type_owner_schedule_input,
    value: {
        monday: defaultOwnerScheduleDay,
        tuesday: defaultOwnerScheduleDay,
        wednesday: defaultOwnerScheduleDay,
        thursday: defaultOwnerScheduleDay,
        friday: defaultOwnerScheduleDay,
        saturday: {
            ...defaultOwnerScheduleDay,
            is_open: false
        },
        sunday: {
            ...defaultOwnerScheduleDay,
            is_open: false
        }
    },
    ...custom
})
export const faq_input = (
    custom: Partial<FAQFieldModel> = {}
): FAQFieldModel => ({
    key: '',
    title: '',
    type: type_faq_input,
    presetItems: [],
    value: [],
    ...custom
})
