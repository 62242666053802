<template>
    <Modal ref="preventModal" class="prevent-modal" :hideHeader="true">
        <div
            class="prevent-text"
            v-text="t('YOU_MAKE_CHANGES_THAT_WERE_NOT_SAVED')"
        />
        <div class="prevent-buttons">
            <button
                class="prevent-no"
                v-text="t('DO_NOT_SAVE')"
                @click="emit('discard')"
            />
            <button
                class="prevent-yes"
                v-text="t('YES_SAVE')"
                @click="emit('save')"
            />
        </div>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/utils/Modal.vue'
import { t } from '@/i18n'
import { ref } from 'vue'

const preventModal = ref<InstanceType<typeof Modal> | null>(null)

const emit = defineEmits<{
    (e: 'discard'): void
    (e: 'save'): void
}>()

const show = () => {
    preventModal.value.show()
}
const close = () => {
    preventModal.value.close()
}

defineExpose({
    show,
    close
})
</script>

<style scoped lang="scss">
.prevent-modal {
    :deep(.modal-card) {
        width: 460px;
    }
}
.prevent-text {
    @extend .is-h6-web;
    text-align: center;
    margin-bottom: 2rem;
}
.prevent-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.prevent-no {
    @extend .secondary-button;
    @extend .is-button-text-1-web;
    text-transform: uppercase;
}
.prevent-yes {
    @extend .primary-button;
    @extend .is-button-text-1-web;
    text-transform: uppercase;
}
</style>
